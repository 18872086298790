export const oneSignal = () => {
    switch(process.env.REACT_APP_ENV) {
        case 'development':
            console.log('development')
            return {
                appId: "29cd2724-afb3-415b-a1e7-a2e553705041",
            }
        case 'production':
            console.log('production')

            return {
                appId: "e119e69a-f359-44f5-a735-4a4dd47b7e95",
            }

    }
}