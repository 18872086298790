import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

export const PivotSkeletons = () => {
  return (
    <Stack id="stack-usuario-skeleton" spacing={1} marginTop={1}>
      <Skeleton
        variant="rectangular"
        style={{ height: "50", width: "100%", borderRadius: 10 }}
      />
    </Stack>
  );
};
