import {
  asyncProductActions,
  asyncProductModelActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import SkeletonFamily from "./Skeleton";
import { Stack, Typography } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { getProductModels } from "app/redux/modules/Products/productModel/actions";
import * as Yup from "yup";

import {
  CLEAN_PRODUCT_MODEL_STORE,

} from "app/redux/modules/Products/productModel/constants";
import { CLEAN_PRODUCT_STORE } from "app/redux/modules/Products/product/constants";

const ProductModels = () => {
  const dispatch = useDispatch();
  const { productModels, product, auth } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);
  const startPage = async() => {   
    await dispatch(asyncProductModelActions.getProductModels("asc", "id"));
    await dispatch(asyncProductActions.getProduct("asc", "id"));
  }

  useEffect(() => { 
    startPage();

    return () => {
      dispatch({ type: CLEAN_PRODUCT_MODEL_STORE });
      dispatch({ type: CLEAN_PRODUCT_STORE });

    }
  }, [dispatch]);
  
  useEffect(() => {
    if (product.data) {
      setNewSelectOptions(
        product.data.data && product.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [product]);


  const dataLabels = [
    {
      id: "Id",
      name: "Nome",
      created_at: "Data de Criação",
      updated_at: "Última atualização",
      product: "Produto Pai",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: "id",
      name: "string",
      description: "string",
      status: "chip",
      created_at: "date",
      updated_at: "date",
      deleted_at: "date",
      product: "nested_name",
    },
  ];

  const createDataType = [
    {
      type: "text",
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "text",
      value: "description",
      label: "Descrição",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "select",
      value: "product_id",
      label: "Produto Pai",
      options: newSelectOptions,
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "status",
      value: "status",
      label: "Status",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
  ];

  const pageConfig = {
    title: "Modelos de Produtos",
    newText: "Novo Modelo",
    searchText: "Pesquisar Modelo",
    data: productModels.data,
    loading: productModels.loading,
    category: newSelectOptions,
    actions: {
      delete: asyncProductModelActions.deleteProductModel,
      edit: asyncProductModelActions.editProductModel,
      get: asyncProductModelActions.getProductModels,
      search: asyncProductModelActions.getProductModels,
      create: asyncProductModelActions.createProductModel,
      clean: { type: CLEAN_PRODUCT_MODEL_STORE },
    },
    createDataType: createDataType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default ProductModels;
