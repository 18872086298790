import { SET_ALERT } from "../../Alerts/constants";
import {
  GET_RESOLUTION_CATEGORIES_SUCCESS,
  GET_RESOLUTION_CATEGORIES_FAILURE,
  GET_RESOLUTION_CATEGORIES_REQUEST,
  DELETE_RESOLUTION_CATEGORIES_FAILURE,
  DELETE_RESOLUTION_CATEGORIES_REQUEST,
  DELETE_RESOLUTION_CATEGORIES_SUCCESS,
  POST_RESOLUTION_CATEGORIES_REQUEST,
  POST_RESOLUTION_CATEGORIES_FAILURE,
  POST_RESOLUTION_CATEGORIES_SUCCESS,
  PUT_RESOLUTION_CATEGORIES_REQUEST,
  PUT_RESOLUTION_CATEGORIES_FAILURE,
  PUT_RESOLUTION_CATEGORIES_SUCCESS,
} from "./constants";
import api from "app/services/config";

export const getResolutionCategories =
  (order, orderBy, status, page, searchKey, noPaginate) => async (dispatch, getState) => {
    dispatch({ type: GET_RESOLUTION_CATEGORIES_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/resolution-categories`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: search.data.status,
          page: search.data.page,
          s: searchKey,
          noPaginate: noPaginate
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_RESOLUTION_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_RESOLUTION_CATEGORIES_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteResolutionCategories = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_RESOLUTION_CATEGORIES_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/resolution-categories/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_RESOLUTION_CATEGORIES_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" }, 
    });
  } catch (error) {
    dispatch({
      type: DELETE_RESOLUTION_CATEGORIES_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" }, 
    });
  } finally {
    dispatch(getResolutionCategories("asc", "id"));
  }
};
export const createResolutionCategories =
  (data) => async (dispatch, getState) => {
    dispatch({ type: POST_RESOLUTION_CATEGORIES_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.post(
        `/resolution-categories`,
        {
          name: data.name,
          description: data.description,
          status: data.status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: POST_RESOLUTION_CATEGORIES_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Criado com sucesso", variant: "success" }, 
      });
    } catch (error) {
      dispatch({
        type: POST_RESOLUTION_CATEGORIES_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao criar", variant: "error" }, 
      });
    } finally {
      dispatch(getResolutionCategories("asc", "id"));
    }
  };

  export const editResolutionCategories =
  (data, id) => async (dispatch, getState) => {
    dispatch({ type: PUT_RESOLUTION_CATEGORIES_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.put(
        `/resolution-categories/${id}`,
        {
          name: data.name,
          description: data.description,
          status: data.status,

        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: PUT_RESOLUTION_CATEGORIES_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Editado com sucesso", variant: "success" }, 
      });
    } catch (error) {
      dispatch({
        type: PUT_RESOLUTION_CATEGORIES_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao editar", variant: "error" }, 
      });
    } finally {
      dispatch(getResolutionCategories("asc", "id"));
    }
  };
