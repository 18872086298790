//TICKETS_NOTIFICATIONS Types

export const GET_TICKETS_NOTIFICATIONS_REQUEST = "GET_TICKETS_NOTIFICATIONS_REQUEST";
export const GET_TICKETS_NOTIFICATIONS_FAILURE = "GET_TICKETS_NOTIFICATIONS_FAILURE";
export const GET_TICKETS_NOTIFICATIONS_SUCCESS = "GET_TICKETS_NOTIFICATIONS_SUCCESS";

export const POST_TICKETS_NOTIFICATIONS_REQUEST = "POST_TICKETS_NOTIFICATIONS_REQUEST";
export const POST_TICKETS_NOTIFICATIONS_FAILURE = "POST_TICKETS_NOTIFICATIONS_FAILURE";
export const POST_TICKETS_NOTIFICATIONS_SUCCESS = "POST_TICKETS_NOTIFICATIONS_SUCCESS";

export const CLEAN_TICKETS_NOTIFICATIONS_STORE = "CLEAN_TICKETS_NOTIFICATIONS_STORE";