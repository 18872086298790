import { Skeleton, Stack, Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import React, { useEffect } from "react";
import { salesData } from "./data";
import { styled } from "@mui/material/styles";
import { useFetchSinglePivoQuery } from "app/pages/Pivos/features/pivos/pivos-slice";
import { useParams } from "react-router-dom";
import { AccessTime, AirlineStops, WaterDrop } from "@mui/icons-material";
import { formatDate2 } from "app/utils/appHelpers";

const Item = styled("div")(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: 16,
  width: "20%",
  [theme.breakpoints.down("lg")]: {
    width: "20%",
  },
  [theme.breakpoints.down("md")]: {
    width: "33.3%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
  padding: theme.spacing(0, 2),
}));

function RenderPivoData() {
  const { id } = useParams();
  const { data, isFetching, isLoading } = useFetchSinglePivoQuery(id);

  if (isLoading || isFetching) {
    return (
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        sx={{ p: (theme) => theme.spacing(0, 1, 2) }}
      >
        <Skeleton
          variant="rectangular"
          style={{ height: "50px", width: "100%", margin: 1 }}
        />
      </Stack>
    );
  }

  return (
    <Stack
      direction={"row"}
      flexWrap={"wrap"}
      sx={{ p: (theme) => theme.spacing(0, 1, 2) }}
    >
      <Item>
        <Typography variant={"body1"} color={"common.white"} mb={1}>
          <DateRangeIcon
            fontSize={"small"}
            sx={{ verticalAlign: "middle", mr: 1, mt: -0.5 }}
          />
          {formatDate2(data.last_oil_change)}
        </Typography>
        <Typography variant={"h6"} color={"common.white"} mb={0}>
          Última troca de óleo
        </Typography>
      </Item>
      <Item>
        <Typography variant={"body1"} color={"common.white"} mb={1}>
          <AccessTime
            fontSize={"small"}
            sx={{ verticalAlign: "middle", mr: 1, mt: -0.5 }}
          />
          {data.hourmeter}
        </Typography>
        <Typography variant={"h6"} color={"common.white"} mb={0}>
          Horímetro
        </Typography>
      </Item>
      <Item>
        <Typography variant={"body1"} color={"common.white"} mb={1}>
          <AirlineStops
            fontSize={"small"}
            sx={{ verticalAlign: "middle", mr: 1, mt: -0.5 }}
          />
          {data.pivot_spans}
        </Typography>
        <Typography variant={"h6"} color={"common.white"} mb={0}>
          N. de lances
        </Typography>
      </Item>
      <Item>
        <Typography variant={"body1"} color={"common.white"} mb={1}>
          <WaterDrop
            fontSize={"small"}
            sx={{ verticalAlign: "middle", mr: 1, mt: -0.5 }}
          />
          {data?.reducers_count}
        </Typography>
        <Typography variant={"h6"} color={"common.white"} mb={0}>
          N. de redutores
        </Typography>
      </Item>
    </Stack>
  );
}

export default RenderPivoData;
