import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { osTypes } from "../../untils/constants";

function OsTypeField({ formik, ...props }) {
  return (
    <FormControl sx={{ mb: 1 }}>
      <InputLabel id="demo-select-small-status">Tipo de OS</InputLabel>
      <Select
        {...props}
        labelId="demo-select-small-status"
        id="demo-select-small-status"
        label="Tipo da OS"
        value={formik.values.so_type}
        onChange={(e) => {
          formik.setFieldValue("so_type", e.target.value);
          formik.setFieldValue("pivot_id", undefined);
          formik.setFieldValue("reducers_ids", undefined);
        }}
      >
        <MenuItem value="">
          <em>Selecione...</em>
        </MenuItem>
        <MenuItem name={osTypes.STANDARD} value={osTypes.STANDARD}>
          Padrão
        </MenuItem>
        <MenuItem
          name={osTypes.REDUCER_MAINTENANCE}
          value={osTypes.REDUCER_MAINTENANCE}
        >
          Manutenção de Pivô
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default OsTypeField;
