import React from "react";
import { Content } from "./style";
import { Stack } from "@mui/material";

import IndexListingRTK from "app/components/IndexListingRTK";
import {
  useFetchAllTicketsIndexQuery,
  useFetchProductFamiliesQuery,
  useFetchTicketCategoriesQuery,
} from "./features/Tickets/tickets-slice";
import { useParams } from "react-router-dom";

const TicketsRevendas = () => {
  const { data: categoriesData } = useFetchTicketCategoriesQuery();
  const { data: productFamiliesData } = useFetchProductFamiliesQuery();
  const { statusTicket, comapanyId } = useParams();

  const orderByData = [
    { id: "id", name: "Número do Ticket" },
    { id: "title", name: "Assunto" },
    { id: "status_id", name: "Status" },
    { id: "updated_at", name: "Ultima atualização" },
  ];

  const status = [
    {
      id: 0,
      name: "Todos",
    },
    {
      id: 1,
      name: "Aberto",
    },
    {
      id: 2,
      name: "Pendente",
    },
    {
      id: 3,
      name: "Resolvido",
    },
    {
      id: 4,
      name: "Fechado",
    },
    {
      id: 5,
      name: "Em Andamento",
    },
  ];

  const headerFilters = [
    {
      key: "ownerCompany",
      data: [
        { id: "RESALE", name: "Revendas" },
        { id: "KREBS", name: "Krebs" },
      ],
      label: "Origem",
      defaultValue: "KREBS",
    },
    {
      key: "ticket_status_id",
      label: "Status",
      data: status,
      defaultValue: status.find(
        (item) =>
          item.name.toLocaleLowerCase() === statusTicket.replace("-", " ")
      )?.id,
    },
    {
      key: "ticket_category_id",
      data: categoriesData?.data,
      label: "Categoria",
      defaultValue: undefined,
    },
    {
      key: "product_family_id",
      data: productFamiliesData?.data,
      label: "Família",
      defaultValue: undefined,
    },
    {
      key: "orderBy",
      label: "Ordenação",
      data: orderByData,
      defaultValue: "updated_at",
    },
  ];

  const columns = [
    {
      key: "id",
      cols: "1",
    },
    {
      label: "Assunto",
      key: "title",
      cols: "3",
    },
    {
      key: "responsible_user.profile.name",
      label: "solicitante",
      cols: "1",
    },
    {
      label: "Categoria",
      key: "category.name",
      cols: "1",
    },
    {
      key: "channel.name",
      label: "Canal",
      cols: "1",
    },
    {
      key: "product_family.name",
      label: "Família",
      cols: "1",
    },
    {
      key: "updated_at",
      label: "Atualizado",
      cols: "1",
      special: "date",
    },

    {
      label: "Status",
      key: "status.id",
      cols: "1",
      special: "status",
    },
  ];
  return (
    <Content>
      <Stack style={{ marginTop: 10 }}>
        <IndexListingRTK
          newRoute={"/tickets/novo"}
          header="Tickets de suporte"
          headerFilters={headerFilters}
          columns={columns}
          hook={useFetchAllTicketsIndexQuery}
        />
      </Stack>
    </Content>
  );
};

export default TicketsRevendas;
