//PRODUCT Types

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";

export const GET_SINGLE_PRODUCT_REQUEST = 'get-single-PRODUCT-request'
export const GET_SINGLE_PRODUCT_FAILURE = 'get-single-PRODUCT-failure';
export const GET_SINGLE_PRODUCT_SUCCESS = 'get-single-PRODUCT-success';

export const POST_PRODUCT_REQUEST = "POST_PRODUCT_REQUEST";
export const POST_PRODUCT_FAILURE = "post-PRODUCT-failure";
export const POST_PRODUCT_SUCCESS = "post-PRODUCT-success";

export const PUT_PRODUCT_REQUEST = "PUT_PRODUCT_REQUEST";
export const PUT_PRODUCT_FAILURE = "put-PRODUCT-failure";
export const PUT_PRODUCT_SUCCESS = "put-PRODUCT-success";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_FAILURE = "delete-PRODUCT-failure";
export const DELETE_PRODUCT_SUCCESS = "delete-PRODUCT-success";

export const CLEAN_PRODUCT_STORE = "CLEAN_PRODUCT_STORE";