import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFetchTechnicianQuery } from "../../features/serviceOrderFields-slice";
import { FieldSkeleton } from "app/components/CustomInputs/Skeleton";
import { formatDate } from "app/utils/appHelpers";
import { useSelector } from "react-redux";

function TechnicianSoField({ formik, handlePreviewData, ...props }) {
  const { auth } = useSelector((state) => state);

  const { data, isFetching, isLoading } = useFetchTechnicianQuery({
    company_id:
      formik.values.technicians_origin === "RESALE"
        ? formik.values.resale
        : auth.user.profile.unit.company_id,
    start: formatDate(formik.values.scheduled_at, formik.values.time),
    end: formatDate(formik.values.end, formik.values.time_end),
  });

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        multiple
        options={data || []}
        loading={isLoading || isFetching}
        fullWidth
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          if (value.length === 0) {
            formik.setFieldValue("responsibles", []);
            handlePreviewData("responsibles", "");
            return;
          }

          const options = value.map((option) => option.id);
          formik.setFieldValue("responsibles", options);

          const optionsPreview = value.map((options) => options.profile.name);
          handlePreviewData("responsibles", optionsPreview.join(", "));
        }}
        value={
          data?.filter((option) =>
            formik.values.responsibles.includes(option.id)
          ) || []
        }
        renderOption={(props, option, { selected }) => {
          const { ...optionsProps } = props;

          return <li {...optionsProps}>{option.profile.name}</li>;
        }}
        getOptionLabel={(option) => option.profile.name || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"responsibles"}
            name={"responsibles"}
            label={"Técnicos responsáveis"}
            error={
              formik.touched.responsibles && Boolean(formik.errors.responsibles)
            }
            helperText={
              formik.touched.responsibles && formik.errors.responsibles
            }
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default TechnicianSoField;
