import { Pagination } from "@mui/material";
import { formatDate, formatDate2 } from "app/utils/appHelpers";
import React, { useContext } from "react";
import ChipStatus from "../ChipStatus";
import IndexListingRTKHeader from "./IndexListingRTKHeader";
import IndexListingProvider from "./IndexListingProvider";
import IndexListing from "./IndexListing";

function IndexListingRTK({ hook, columns, headerFilters, header, newRoute }) {
  return (
    <IndexListingProvider>
      <IndexListing
        newRoute={newRoute}
        hook={hook}
        columns={columns}
        headerFilters={headerFilters}
        header={header}
      />
    </IndexListingProvider>
  );
}

export default IndexListingRTK;
