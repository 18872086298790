import React from "react";
import { Autocomplete, FormControl, MenuItem, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { FieldSkeleton } from "./Skeleton";
import { MULTIPLE, RESELER_THUNK, SINGLE } from "./constants";
import { formatDate } from "app/utils/appHelpers";

export default function AutoCompleteInput({
  fieldName,
  fieldLabel,
  formik,
  data,
  placeHolder,
  dependentFields,
  clearStores,
  dependsOn,
  handlePreviewData,
  getNextFieldData,
  subLevelData,
  loading,
  referField,
  thunkLayout,
}) {
  const dispatch = useDispatch();

  return (
    <>
      {loading ? (
        <FieldSkeleton />
      ) : (
        <>
          <FormControl sx={{ mb: 1 }}>
            <Autocomplete
              data-testid={`input-${fieldName}`}
              key={fieldName}
              disablePortal
              options={data ? data : []}
              getOptionLabel={
                subLevelData
                  ? (field) => field[subLevelData].name
                  : (field) => field.name
              }
              fullWidth
              disabled={dependsOn && !formik.values[dependsOn]}
              value={
                formik.values[fieldName] && data && data.length > 0
                  ? data.filter((co) => co.id == formik.values[fieldName])[0]
                  : null
              }
              noOptionsText={placeHolder}
              onChange={async (event, newValue, reason) => {
                if (reason == "clear") {
                  formik.setFieldValue(fieldName, "");
                  clearStores && handlePreviewData(fieldName, "");
                  clearStores &&
                    Promise.all(clearStores.map((store) => dispatch(store)));
                }
                if (newValue) {
                  if (thunkLayout === SINGLE) {
                    getNextFieldData && dispatch(getNextFieldData(newValue.id));
                  }
                  if (thunkLayout === RESELER_THUNK) {
                    getNextFieldData &&
                      dispatch(
                        getNextFieldData(
                          newValue.id,
                          formatDate(
                            formik.values.scheduled_at,
                            formik.values.time
                          ),
                          formatDate(formik.values.end, formik.values.time_end)
                        )
                      );
                  }

                  if (thunkLayout === MULTIPLE) {
                    getNextFieldData &&
                      dispatch(
                        getNextFieldData(
                          "",
                          "",
                          "",
                          "",
                          "",
                          true,
                          referField ? referField : newValue.id
                        )
                      );
                  }
                  clearStores &&
                    Promise.all(clearStores.map((store) => dispatch(store)));
                  formik.setFieldValue(fieldName, newValue.id);
                  handlePreviewData(
                    fieldName,
                    subLevelData ? newValue[subLevelData].name : newValue.name
                  );
                }
                dependentFields &&
                  dependentFields.map((field) => {
                    formik.setFieldValue(field, "");
                    handlePreviewData([field], "");
                  });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id={fieldName}
                  name={fieldName}
                  label={fieldLabel}
                  value={formik.values[fieldName]}
                  error={
                    formik.touched[fieldName] &&
                    Boolean(formik.errors[fieldName])
                  }
                  helperText={
                    formik.touched[fieldName] && formik.errors[fieldName]
                  }
                />
              )}
            />
          </FormControl>
        </>
      )}
    </>
  );
}
