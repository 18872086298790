const today = new Date();
const currentYear = today.getFullYear();

export const tasks =  [
    {
        tasks: [
            {
                title: 'Tarefa 1.1',
                start: new Date(currentYear, 9, 20, 8, 0, 0),
                end: new Date(currentYear, 9, 20, 10, 0, 0),
            },
            {
                title: 'Tarefa 1.2',
                start: new Date(currentYear, 9, 20, 12, 0, 0),
                end: new Date(currentYear, 9, 20, 14, 0, 0),
            }
        ]
    },
    {
        tasks: [
            {
                title: 'Tarefa 2.1',
                start: new Date(currentYear, 10, 10, 8, 0, 0),
                end: new Date(currentYear, 10, 11, 10, 0, 0),
            },
            {
                title: 'Tarefa 2.2',
                start: new Date(currentYear, 10, 20, 12, 0, 0),
                end: new Date(currentYear, 10, 20, 14, 0, 0),
            }
        ]
    },
    {
        tasks: [
            {
                title: 'Tarefa 3.1',
                start: new Date(currentYear, 9, 20, 8, 0, 0),
                end: new Date(currentYear, 9, 20, 10, 0, 0),
            },
            {
                title: 'Tarefa 3.2',
                start: new Date(currentYear, 9, 28, 12, 0, 0),
                end: new Date(currentYear, 9, 29, 14, 0, 0),
            },
            {
                title: 'Tarefa 3.3',
                start: new Date(currentYear, 10, 20, 12, 0, 0),
                end: new Date(currentYear, 10, 20, 14, 0, 0),
            }
        ]
    }
];
