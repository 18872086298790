import {
  CLEAN_SEARCH,
  SET_SEARCH_STATUS,
  SET_SEARCH_CATEGORY,
  SET_SEARCH_ORDER,
  SET_SEARCH_ORDER_BY,
  SET_SEARCH_PAGE,
  SET_RESPONSE_TICKET,
  SET_PRODUCT_FAMILY_ID,
  SET_SEARCH_CITY,
  SET_SEARCH_UF,
  SET_COMPANY_TYPE_ID,
  SET_SEARCH_USER_ID,
  SET_SEARCH_COMPANY_ID,
  
} from "./constants";
 
const initialState = {
  data: {
    order: "desc",
    orderBy: "id",
    status: 1,
    category: "",
    page: 1,
    responseTicket: null,
    city: "",
    uf: "",
    product_family_id: "",
    company_type_id: "",
    company_id: "",
    user_id: "",
  }
 
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_FAMILY_ID:
      return {
        ...state,
        data: {
            ...state.data,
            product_family_id: action.payload,
          },          
    };
    case SET_COMPANY_TYPE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          company_type_id: action.payload
        }
      }
    case SET_SEARCH_CITY:
      return {
        ...state,
        data: {
            ...state.data,
            city: action.payload,
          },          
    };
    case SET_SEARCH_UF:
      return {
        ...state,
        data: {
            ...state.data,
            uf: action.payload,
          },          
    };
    case SET_RESPONSE_TICKET:
      return {
        ...state,
        data: {
            ...state.data,
            responseTicket: action.payload,
          },          
    };
    case SET_SEARCH_STATUS:
        return {
          ...state,
          data: {
              ...state.data,
              status: action.payload,
            },          
      };
     case SET_SEARCH_ORDER:
        return {
          ...state,
          data: {
              ...state.data,
              order: action.payload,
            },          
      };  
      case SET_SEARCH_PAGE:
        return {
          ...state,
          data: {
              ...state.data,
              page: action.payload,
            },          
      };  
      case SET_SEARCH_ORDER_BY:
        return {
          ...state,
          data: {
              ...state.data,
              orderBy: action.payload,
            },          
      };  
    case SET_SEARCH_CATEGORY:
        return {
          ...state,
          data: {
              ...state.data,
              category: action.payload,
            },          
      };  
    case SET_SEARCH_USER_ID:
      return {
        ...state,
        data: {
            ...state.data,
            user_id: action.payload,
          },          
    };
    case SET_SEARCH_COMPANY_ID:
      return {
        ...state,
        data: {
            ...state.data,
            company_id: action.payload,
          },          
    };   
    case CLEAN_SEARCH:
      return initialState;
    default:
      return state;
  }
}
