//TICKETS Types

export const GET_TICKETS_REQUEST = "GET_TICKETS_REQUEST";
export const GET_TICKETS_FAILURE = "GET_TICKETS_FAILURE";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";

export const GET_TICKETS_FROM_COMPANY_REQUEST = "GET_TICKETS_FROM_COMPANY_REQUEST";
export const GET_TICKETS_FROM_COMPANY_FAILURE = "GET_TICKETS_FROM_COMPANY_FAILURE";
export const GET_TICKETS_FROM_COMPANY_SUCCESS = "GET_TICKETS_FROM_COMPANY_SUCCESS";

export const GET_SINGLE_TICKETS_REQUEST = 'get-single-TICKETS-request'
export const GET_SINGLE_TICKETS_FAILURE = 'get-single-TICKETS-failure';
export const GET_SINGLE_TICKETS_SUCCESS = 'get-single-TICKETS-success';

export const POST_TICKETS_REQUEST = "POST_TICKETS_REQUEST";
export const POST_TICKETS_FAILURE = "post-TICKETS-failure";
export const POST_TICKETS_SUCCESS = "post-TICKETS-success";

export const PUT_TICKETS_REQUEST = "PUT_TICKETS_REQUEST";
export const PUT_TICKETS_FAILURE = "put-TICKETS-failure";
export const PUT_TICKETS_SUCCESS = "put-TICKETS-success";

export const DELETE_TICKETS_REQUEST = "DELETE_TICKETS_REQUEST";
export const DELETE_TICKETS_FAILURE = "delete-TICKETS-failure";
export const DELETE_TICKETS_SUCCESS = "delete-TICKETS-success";

export const CLEAN_TICKETS_STORE = "CLEAN_TICKETS_STORE";