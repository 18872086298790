import { PivoContext } from "app/pages/Pivos/PivoProvider";
import React from "react";
import { useParams } from "react-router-dom";
import { useFetchSinglePivoQuery } from "../features/pivos/pivos-slice";

function useCurrentPivot() {
  const { id } = useParams();
  const { data, isFetching, isLoading, refetch } = useFetchSinglePivoQuery(id);
  return { data, isFetching, isLoading, refetch };
}

export default useCurrentPivot;
