import {
  GET_TASK_TYPES_REQUEST,
  GET_TASK_TYPES_SUCCESS,
  GET_TASK_TYPES_FAILURE,

  CLEAN_TASK_TYPES_STORE,
  
  DELETE_TASK_TYPES_REQUEST,
  DELETE_TASK_TYPES_SUCCESS,
  DELETE_TASK_TYPES_FAILURE,

  POST_TASK_TYPES_REQUEST,
  POST_TASK_TYPES_FAILURE,
  POST_TASK_TYPES_SUCCESS,

  PUT_TASK_TYPES_REQUEST,
  PUT_TASK_TYPES_FAILURE,
  PUT_TASK_TYPES_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function taskTypes(state = initialState, action) {
  switch (action.type) {
    case GET_TASK_TYPES_REQUEST:
    case DELETE_TASK_TYPES_REQUEST:
    case POST_TASK_TYPES_REQUEST:
    case PUT_TASK_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TASK_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_TASK_TYPES_SUCCESS:
    case POST_TASK_TYPES_SUCCESS:
    case PUT_TASK_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_TASK_TYPES_FAILURE:
    case POST_TASK_TYPES_FAILURE:
    case DELETE_TASK_TYPES_FAILURE:
    case PUT_TASK_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_TASK_TYPES_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
