import {
  asyncProblemTypesActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING, STATUS_INPUT, TEXT_INPUT } from "app/components/DynamicViewGenerator/constants";
import { CLEAN_PROBLEM_TYPES_STORE } from "app/redux/modules/Setup/ProblemTypes/constants";
import * as Yup from "yup";

const ProblemTypes = () => {
  const dispatch = useDispatch();
  const { problemTypes } = useSelector((state) => state);

  const startPage = async () => {
     dispatch(asyncProblemTypesActions.get("asc", "id"));
  }

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_PROBLEM_TYPES_STORE });
    }
  }, [dispatch]);
 



  const dataLabels = [
    {
      id: "Id",
      name: "Nome",   
      description: "Descrição",  
      created_at: "Data criação",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      description: DATA_TYPE_STRING,
      status: DATA_TYPE_CHIP,
      created_at: DATA_TYPE_DATE,
      },
  ];

  const formFieldsType = [
    {
      type: TEXT_INPUT,
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    }, 
    {
      type: TEXT_INPUT,
      value: "description",
      label: "Descrição",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },   
    {
      type: STATUS_INPUT,
      value: "status",
      label: "Status",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },   
  ];

  const pageConfig = {
    title: "Tipos de Problema",
    newText: "Novo problema",
    searchText: "Pesquisar",
    data: problemTypes.data,
    loading: problemTypes.loading,
    actions: {
      delete: asyncProblemTypesActions.delete,
      edit: asyncProblemTypesActions.edit,
      get: asyncProblemTypesActions.get,
      search: asyncProblemTypesActions.get,
      create: asyncProblemTypesActions.create,
      clean: { type: CLEAN_PROBLEM_TYPES_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default ProblemTypes;
