import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Divider,
  Tooltip,
  Typography,
  Avatar,
  Stack,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import IconButton from "@mui/material/IconButton";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import Div from "@jumbo/shared/Div";
/* import {contactService} from "../../../../../services/contact-services"; */
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ContactForm from "../ContactForm";
import useContactsApp from "../../hooks/useContactsApp";
import { useMutation } from "react-query";
import ContactDetail from "../ContactDetail";
import JumboGridItem from "@jumbo/components/JumboList/components/JumboGridItem";
import moment from "moment";
import { appTheme } from "app/themes/app/theme";
import { history } from "app/redux/store";
import { useNavigate } from "react-router-dom";

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const ContactItem = ({ contact, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { setContactsListRefresh, field } = useContactsApp();
  const [favorite, setFavorite] = React.useState(contact?.starred);
  /* const deleteContactMutation = useMutation(contactService.delete, {
        onSuccess: () => {
            hideDialogAndRefreshContactsList();
        }
    }); */
  const navigate = useNavigate();

  const hideDialogAndRefreshContactsList = React.useCallback(() => {
    hideDialog();
    setContactsListRefresh(true);
  }, [hideDialog, setContactsListRefresh]);

  const showContactDetail = React.useCallback(() => {
    if (field == "serviceOrders") {
      navigate(`/os/detalhes/${contact.id}`);
    } else {
      showDialog({
        content: (
          <ContactDetail contact={contact} onClose={hideDialog} field={field} />
        ),
      });
    }
  }, [showDialog, contact]);

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        showDialog({
          title: "Update contact detail",
          content: (
            <ContactForm
              contact={contact}
              onSave={hideDialogAndRefreshContactsList}
            />
          ),
        });
        break;
      case "delete":
        showDialog({
          variant: "confirm",
          title: "Are you sure about deleting this contact?",
          content: "You won't be able to recover this contact later",
          onYes: () => {},
          onNo: hideDialog,
        });
    }
  };

  if (view === "grid") {
    return (
      <JumboGridItem xs={12} lg={6}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={contact.name}
                src={contact.profile_pic}
              />
            }
            action={
              <React.Fragment>
                <StarIcon
                  sx={{ color: "warning.main", verticalAlign: "middle", mr: 1 }}
                />
                <IconButton>
                  <MoreHorizIcon />
                </IconButton>
              </React.Fragment>
            }
            title={
              <Typography variant={"h6"} color={"text.primary"} mb={0.25}>
                {contact.name}
              </Typography>
            }
            subheader={
              <Typography variant={"body1"} color={"text.secondary"}>
                {contact.designation}
              </Typography>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem sx={{ p: (theme) => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={contact.company} />
              </ListItem>
              <ListItem sx={{ px: 1.5 }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <AlternateEmailIcon />
                </ListItemIcon>
                <ListItemText primary={contact.email} />
              </ListItem>
              <ListItem sx={{ px: 1.5 }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary={contact.phone} />
              </ListItem>
            </List>
            <Divider sx={{ my: 2 }} />
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Checkbox sx={{ my: -0.5 }} />
              {contact.labels.length == 0 ? null : (
                <JumboChipsGroup
                  chips={contact.labels}
                  mapKeys={{ label: "name" }}
                  spacing={1}
                  size={"small"}
                  max={3}
                />
              )}
            </Div>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }

  const fields = {
    unit: [
      {
        label: "Nome",
        field: "name",
        type: "text",
      },
      {
        label: "Endereço",
        field: "address",
        type: "text",
      },
      {
        label: "Status",
        field: "status",
        type: "boolean",
      },
    ],
    tickets: [
      {
        label: "Título",
        field: "title",
        type: "text",
      },
      {
        label: "Data Criação",
        field: "created_at",
        type: "date",
      },
      {
        label: "Categoria",
        field: "category.name",
        type: "text",
      },
      {
        label: "Canal",
        field: "channel.name",
        type: "text",
      },
      {
        label: "Solicitante",
        field: "attendant.profile.name",
        type: "text",
      },
    ],
    serviceOrders: [
      {
        label: "Data de Criação",
        field: "created_at",
        type: "date",
      },
      {
        label: "Data de Agendamento",
        field: "task.scheduled_at",
        type: "date",
      },
      {
        label: "Descrição",
        field: "description",
        type: "text",
      },
      {
        label: "Endereço",
        field: "unit.address",
        type: "text",
      },
      {
        label: "Status",
        field: "status_service_order.name",
        type: "text",
      },
      {
        label: "Tipo de Serviço",
        field: "task.task_type.name",
        type: "text",
      },
      {
        label: "Última atualização",
        field: "updated_at",
        type: "date",
      },
    ],
  };

  function getFieldValue(item) {
    const fieldArray = item.field.split(".");

    let value = contact;

    for (let i = 0; i < fieldArray.length; i++) {
      const f = fieldArray[i];

      if (value[f]) {
        value = value[f];
      } else {
        value = "N/A";
        break;
      }
    }

    switch (item.type) {
      case "date":
        return moment(value).format("DD/MM/YYYY");
      case "boolean":
        return value ? "Ativo" : "Inativo";
      default:
        return value;
    }
  }

  return (
    <React.Fragment>
      <JumboListItem
        componentElement={"div"}
        itemData={contact}
        sx={{
          cursor: "pointer",
          borderTop: 1,
          borderColor: "divider",
          "&:hover": {
            bgcolor: "action.hover",
          },
        }}
      >
        <ListItemText
          onClick={showContactDetail}
          primary={
            <Card sx={{ mb: 1 }}>
              <Accordion
                expanded={true}
                onChange={() => {}}
                square
                sx={{ borderRadius: 2 }}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    px: 3,
                    flex: 1,
                    flexDirection: "row-reverse",
                    minHeight: 90,

                    "& .MuiAccordionSummary-content": {
                      alignItems: "center",

                      "&.Mui-expanded": {
                        margin: "12px 0",
                      },
                    },
                    ".MuiAccordionSummary-expandIconWrapper": {
                      borderRadius: 1,
                      border: 1,
                      color: "text.secondary",
                      borderColor: "divider",
                      transform: "none",
                      height: 28,
                      width: 28,
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 1,

                      "&.Mui-expanded": {
                        transform: "none",
                        color: "primary.main",
                        borderColor: "primary.main",
                      },

                      "& svg": {
                        fontSize: "1.25rem",
                      },
                    },
                  }}
                >
                  <Div
                    sx={{
                      mr: { xs: 0, md: 2 },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid " + appTheme.palette.red100,
                      bgcolor: appTheme.palette.neutral100,
                      borderRadius: "5px",
                      p: (theme) => theme.spacing(1, 2),
                      px: 1,
                      mb: { xs: 2, md: 0 },
                    }}
                  >
                    <Typography
                      variant={"h6"}
                      color={appTheme.palette.red100}
                      mb={0}
                    >
                      {field == "serviceOrders" ? contact.so : contact.id}
                    </Typography>
                  </Div>
                  {fields[field].map((fi) => (
                    <Div
                      sx={{
                        flex: 1,
                        flexDirection: "column",
                        alignItems: { xs: "center", md: "flex-start" },
                      }}
                    >
                      <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={0.25}
                      >
                        {fi.label}
                      </Typography>
                      <Typography variant={"body1"}>
                        {getFieldValue(fi)}
                      </Typography>
                    </Div>
                  ))}
                </AccordionSummary>
              </Accordion>
            </Card>
          }
        />
      </JumboListItem>
    </React.Fragment>
  );
};
/* Todo contact, view prop define */
export default ContactItem;
