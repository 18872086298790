import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import useCurrentPivot from "app/pages/Pivos/hooks/useCurrentPivot";
import SkeletonsCard from "../../Components/SkeletonsCard/SkeletonsCard";
const COLORS = ["#3BD2A2", "#E73145"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChartContaminationGraph = () => {
  const { data, isFetching, isLoading } = useCurrentPivot();
  if (isLoading || isFetching) {
    return <SkeletonsCard />;
  }

  const dataGraph = [
    { name: "Com óleo", value: data.reducersReport.oilLevelCount.true },
    { name: "Sem óleo", value: data.reducersReport.oilLevelCount.false },
  ];
  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart>
        <Pie
          dataKey="value"
          data={dataGraph}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={75}
          fill={"#1e88e5"}
        >
          {dataGraph.map((entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartContaminationGraph;
