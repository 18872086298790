import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import useFileManagerContext from "../../hooks/useFileManagerContext";

import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useCreateCategoryMutation } from "../../features/fileManager-slice";
import * as yup from "yup";
import useToast from "app/hooks/useToast";
import FileCategoriesInput from "../inputs/FileCategoriesInput";
import { CustomIcons } from "../CustomIcons";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FamilyField from "app/pages/Tickets/components/fields/FamilyField";
const validationSchema = yup.object({
  name: yup.string().required("O nome da pasta é obrigatório"),
});

function NewCategoryModal() {
  const { dispatchToast } = useToast();
  const { modalCategoryIsOpen, setOpenModalCategory } = useFileManagerContext();
  const [createCategory, { isSuccess, isError, isLoading }] =
    useCreateCategoryMutation();
  const formik = useFormik({
    initialValues: {
      file_manager_category_id: undefined,
      name: undefined,
      category: undefined,
      family_id: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const params = {
        name: values.name,
        parent_category_id: values.file_manager_category_id,
        product_family_id: values.family_id,
      };

      await createCategory(params)
        .unwrap()
        .then((res) => {
          dispatchToast({
            message: "Pasta criada com sucesso!",
            variant: "success",
          });
        })
        .catch((err) => {
          dispatchToast({
            message: "Erro ao criar pasta, tente novamente mais tarde.",
            variant: "error",
          });
        })
        .finally(() => {
          setOpenModalCategory(false);
          formik.resetForm();
        });
    },
  });

  React.useEffect(() => {
    console.log(formik.values);
  }, [formik.values]);

  if (!modalCategoryIsOpen) return null;

  return (
    <Modal
      open={modalCategoryIsOpen}
      onClose={() => setOpenModalCategory(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex items-center justify-center min-h-screen"
    >
      <div className="bg-white w-1/3 rounded-xl shadow-xl max-h-[80vh] overflow-auto">
        <div className="p-5 border-solid border-b-[0.5px] border-gray-200 text-md">
          Criar nova pasta
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-5 flex flex-col gap-5">
            <FormControl variant="outlined" fullWidth>
              <TextField
                size="small"
                label="Nome da pasta"
                variant="outlined"
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
            <FileCategoriesInput
              label={"Quer escolher uma pasta pai?"}
              formikRef={formik}
            />
            <FamilyField formikRef={formik} />
            {formik.values.file_manager_category_id && (
              <>
                <div className=" w-full flex content-center justify-center">
                  Criando a pasta{" "}
                  <span className="ml-2 font-bold text-red-800">
                    {formik.values.name}
                  </span>{" "}
                  {formik.values.file_manager_category_id && (
                    <div className="ml-2">
                      dentro da pasta{" "}
                      <span className=" font-bold text-red-800">
                        {formik.values.category?.name}
                      </span>
                    </div>
                  )}
                </div>
                <div className="w-full items-center justify-between p-5 rounded-md border-dashed border-2 border-gray-300 mb-2 grid grid-cols-3">
                  <div className="w-full flex flex-wrap content-center flex-col justify-center  items-center">
                    <CustomIcons icon="folder" alt={formik.values.name} />
                    <span className="text-sm italic">{formik.values.name}</span>
                  </div>
                  {formik.values.file_manager_category_id && (
                    <>
                      <div className="w-full flex flex-wrap content-center flex-col justify-center  items-center">
                        <ArrowRightAltIcon />
                      </div>
                      <div className="w-full flex flex-wrap content-center flex-col justify-center items-center">
                        <CustomIcons
                          icon="folderFull"
                          alt={formik.values.name}
                        />
                        <span className="text-sm italic">
                          {formik.values.category?.name}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="p-5 border-solid border-t-[0.5px] border-gray-200">
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              color="success"
              onClick={() => {}}
              size="small"
            >
              Criar pasta
            </LoadingButton>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default NewCategoryModal;
