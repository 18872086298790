import { SET_ALERT } from "../../Alerts/constants";
import {
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANIES_REQUEST,
  DELETE_COMPANIES_FAILURE,
  DELETE_COMPANIES_REQUEST,
  DELETE_COMPANIES_SUCCESS,
  POST_COMPANIES_REQUEST,
  POST_COMPANIES_FAILURE,
  POST_COMPANIES_SUCCESS,
  PUT_COMPANIES_REQUEST,
  PUT_COMPANIES_FAILURE,
  PUT_COMPANIES_SUCCESS,
  GET_COMPANIES_TYPES_REQUEST,
  GET_COMPANIES_TYPES_SUCCESS,
  GET_COMPANIES_TYPES_FAILURE,
  GET_RESALES_SUCCESS,
  GET_RESALES_FAILURE,
  GET_RESALES_REQUEST,
} from "./constants";
import api from "app/services/config";

export const getCompanies =
  (order, orderBy, status, page, s, noPaginate, company_type_id) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_COMPANIES_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/companies`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: search.data.status,
          page: search.data.page,
          s: s,
          noPaginate: noPaginate || false,
          city: search.data.city,
          uf: search.data.uf,
          product_family_id: search.data.product_family_id,
          company_type_id: company_type_id
            ? company_type_id
            : search.data.company_type_id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      dispatch({
        type: GET_COMPANIES_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: GET_COMPANIES_FAILURE,
        payload: error.message,
      });
    }
  };

export const getResales =
  (order, orderBy, status, page, s, noPaginate) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_RESALES_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/companies`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: search.data.status,
          page: search.data.page,
          s: s,
          noPaginate: noPaginate || false,
          city: search.data.city,
          uf: search.data.uf,
          product_family_id: search.data.product_family_id,
          company_type_id: 2,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_RESALES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_RESALES_FAILURE,
        payload: error.message,
      });
    }
  };

export const getCompaniesTypes =
  (order, orderBy, status, page, search, noPaginate) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_COMPANIES_TYPES_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.get(`/companies/types`, {
        params: {
          orderBy: `${orderBy},${order}`,
          status: status,
          page: page,
          s: search,
          noPaginate: noPaginate,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_COMPANIES_TYPES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANIES_TYPES_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteCompanies = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_COMPANIES_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/companies/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_COMPANIES_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: DELETE_COMPANIES_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" },
    });
  } finally {
    dispatch(getCompanies("asc", "id"));
  }
};
export const createCompanies = (data) => async (dispatch, getState) => {
  dispatch({ type: POST_COMPANIES_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.post(
      `/companies`,
      {
        name: data.name,
        description: data.description,
        cnpj: data.cnpj,
        status: data.status,
        user_id: data.user_id,
        company_type_id: data.company_type_id,
        uf: data.address.address_components.find((comp) =>
          comp.types.includes("administrative_area_level_1")
        ).short_name,
        city: data.address.address_components.find((comp) =>
          comp.types.includes("administrative_area_level_2")
        ).long_name,
        product_family_id: data.product_family_id,
        unit: {
          name: data.name,
          description: data.description,
          // classification: data.classification,
          address: data.address.formatted_address,
          address_add_info: data.address_add_info,
          longitude: data.address.geometry.location.lng,
          latitude: data.address.geometry.location.lat,
          // company_id: data.company_id,
          status: data.status,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    dispatch({
      type: POST_COMPANIES_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Criado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: POST_COMPANIES_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao criar", variant: "error" },
    });
  } finally {
    dispatch(getCompanies("asc", "id"));
  }
};

export const editCompanies = (data, id) => async (dispatch, getState) => {
  dispatch({ type: PUT_COMPANIES_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.put(
      `/companies/${id}`,
      {
        name: data.name,
        description: data.description,
        cnpj: data.cnpj,
        status: data.status,
        user_id: data.user_id,
        company_type_id: data.company_type_id,
        uf: data.uf,
        city: data.city,
        product_family_id: data.product_family_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    dispatch({
      type: PUT_COMPANIES_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Editado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: PUT_COMPANIES_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao editar", variant: "error" },
    });
  } finally {
    dispatch(getCompanies());
  }
};
