import React from 'react';
import {GoogleMap, useLoadScript, Marker} from "@react-google-maps/api";
import Typography from "@mui/material/Typography";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {useTranslation} from "react-i18next";
import Div from '@jumbo/shared/Div/Div';

const SimpleMap = ({ location }) => {
    const {t} = useTranslation();
    
    const [refresh, setRefresh] = React.useState(false);
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: "AIzaSyCQmjUo21XAXUWVFElZbg5bROsoyGDvTTQ",
    });

    React.useEffect(() => {
        setRefresh(true);

        setTimeout(() => setRefresh(false), 500);
    }, [location]);

    return (
        <React.Fragment>
            <Div sx={{ borderRadius: 2, overflow: "hidden", width: '100%', height: "400px", bgcolor: "#cecece" }}>
                {
                    !refresh && isLoaded &&
                    <GoogleMap
                        zoom={15}
                        center={location}
                        mapContainerStyle={{width: '100%', height: "400px"}}
                    >
                        <Marker position={location} />
                    </GoogleMap>
                }
            </Div>
        </React.Fragment>
    );
};

export default SimpleMap;
