//FAMILY_SOLUTIONS Types

export const GET_FAMILY_SOLUTIONS_REQUEST = "GET_FAMILY_SOLUTIONS_REQUEST";
export const GET_FAMILY_SOLUTIONS_FAILURE = "GET_FAMILY_SOLUTIONS_FAILURE";
export const GET_FAMILY_SOLUTIONS_SUCCESS = "GET_FAMILY_SOLUTIONS_SUCCESS";

export const GET_SINGLE_FAMILY_SOLUTIONS_REQUEST = 'get-single-FAMILY_SOLUTIONS-request'
export const GET_SINGLE_FAMILY_SOLUTIONS_FAILURE = 'get-single-FAMILY_SOLUTIONS-failure';
export const GET_SINGLE_FAMILY_SOLUTIONS_SUCCESS = 'get-single-FAMILY_SOLUTIONS-success';

export const POST_FAMILY_SOLUTIONS_REQUEST = "POST_FAMILY_SOLUTIONS_REQUEST";
export const POST_FAMILY_SOLUTIONS_FAILURE = "post-FAMILY_SOLUTIONS-failure";
export const POST_FAMILY_SOLUTIONS_SUCCESS = "post-FAMILY_SOLUTIONS-success";

export const PUT_FAMILY_SOLUTIONS_REQUEST = "PUT_FAMILY_SOLUTIONS_REQUEST";
export const PUT_FAMILY_SOLUTIONS_FAILURE = "put-FAMILY_SOLUTIONS-failure";
export const PUT_FAMILY_SOLUTIONS_SUCCESS = "put-FAMILY_SOLUTIONS-success";

export const DELETE_FAMILY_SOLUTIONS_REQUEST = "DELETE_FAMILY_SOLUTIONS_REQUEST";
export const DELETE_FAMILY_SOLUTIONS_FAILURE = "delete-FAMILY_SOLUTIONS-failure";
export const DELETE_FAMILY_SOLUTIONS_SUCCESS = "delete-FAMILY_SOLUTIONS-success";

export const CLEAN_FAMILY_SOLUTIONS_STORE = "CLEAN_FAMILY_SOLUTIONS_STORE";