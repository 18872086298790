import PdfIcon from "./pdf-icon-png-2056.png";
import FolderIcon from "./folder.PNG";
import FolderFullIcon from "./folder-full.PNG";
import DefaultIco from "./defaultFile.png";

export const CustomIcons = ({ icon, alt }) => {
  const icons = {
    folder: <img className="w-10 h-14" src={FolderIcon} alt={alt} />,
    folderFull: <img className="w-10 h-14" src={FolderFullIcon} alt={alt} />,
    default: <img src={DefaultIco} alt={alt} />,
    pdf: <img className="w-10 h-14" src={PdfIcon} alt={alt} />,
  };

  const iconsKeys = Object.keys(icons);

  if (!iconsKeys.includes(icon)) {
    return icons.default;
  }
  return icons[icon];
};
