import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

function ReducerSelectInput({ ...props }) {
  const { labelId, label } = props;
  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select {...props}>
        <MenuItem value="">
          <em>Selecione...</em>
        </MenuItem>
        <MenuItem name="sim" value={1}>
          Sim
        </MenuItem>
        <MenuItem name="nao" value={0}>
          Não
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default ReducerSelectInput;
