import { Image, PictureAsPdf } from "@mui/icons-material";
import React, { useEffect } from "react";
import { CustomIcons } from "./CustomIcons";
import { Link } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useDeleteCategoryMutation,
  useDeleteFileMutation,
  useRenameCategoryMutation,
} from "../features/fileManager-slice";
import useToast from "app/hooks/useToast";
import useContextMenu from "app/hooks/useContextMenu";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
function LifeManagerItensCategories({ item }) {
  const [deleteCategory, { isLoading }] = useDeleteCategoryMutation();
  const [newName, setNewName] = React.useState("");
  const [editing, setEditing] = React.useState(false);
  const [renameCategory, { isLoading: isRenaming }] =
    useRenameCategoryMutation();
  const { contextMenu, handleContextMenu, handleClose } = useContextMenu();

  const { dispatchToast } = useToast();
  const handleDeleteClick = () => {
    deleteCategory({ id: item.id });
    dispatchToast({
      message: `A pasta ${item.name} foi deletada com sucesso!`,
      variant: "success",
    });
  };

  const handleEditClick = () => {
    setEditing(true);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleEnterPress();
    }
    if (event.key === "Escape") {
      handleEscPress();
    }
  };

  const handleEnterPress = () => {
    renameCategory({ id: item.id, name: newName });
    dispatchToast({
      message: `Pasta renomeada com sucesso!`,
      variant: "success",
    });
    setEditing(false);
    setNewName("");
  };

  const handleEscPress = () => {
    setEditing(false);
    setNewName("");
  };

  useEffect(() => {
    console.log(newName, item.id);
  }, [newName]);

  if (isRenaming) {
    return (
      <div className="flex-col flex items-center py-4 cursor-pointer hover:bg-white hover:shadow-md rounded-lg relative group">
        <Skeleton variant="rectangular" className="w-10 h-14 rounded-lg" />
        <Skeleton variant="rectangular" className="w-2/3 mt-4 rounded-lg" />
      </div>
    );
  }
  return (
    <div onContextMenu={handleContextMenu}>
      <div className="flex-col flex items-center py-4 cursor-pointer hover:bg-white hover:shadow-md rounded-lg relative group">
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {" "}
          <MenuItem
            color="warning"
            onClick={() => {
              handleEditClick();
              handleClose();
            }}
          >
            <DriveFileRenameOutlineIcon className="w-4 h-4 mr-2" />
            Renomear
          </MenuItem>
          <MenuItem color="error" onClick={() => handleDeleteClick()}>
            <DeleteIcon className="w-4 h-4 mr-2" />
            Apagar
          </MenuItem>
        </Menu>

        <div className="w-10 h-14">
          <Link
            to={`/gerenciamento-de-arquivos/arquivos/${item.id}`}
            key={item.id}
          >
            {item.countFiles > 0 ? (
              <CustomIcons icon={"folderFull"} alt={item.name} />
            ) : (
              <CustomIcons icon={"folder"} alt={item.name} />
            )}
          </Link>
        </div>
        {!editing && (
          <Link
            to={`/gerenciamento-de-arquivos/arquivos/${item.id}`}
            key={item.id}
          >
            <span className="text-sm p-2 font-light text-black text-center flex">
              {item.name}
            </span>
          </Link>
        )}
        {editing && (
          <div className="p-2">
            <TextField
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              variant="standard"
              placeholder="Novo nome..."
              onKeyDown={handleKeyDown}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LifeManagerItensCategories;
