export const FILE_MANAGER_CONSTANTS = {
  KEY_WORD: "KEY_WORD",
  MODAL_OPEN: "MODAL_OPEN",
  OPEN_MODAL_CATEGORY: "OPEN_MODAL_CATEGORY",
};

export const FILEMANAGER_SLICE = {
  LIST_CATEGORIES: "listCategories",
  LIST_FILES: "listFiles",
  LIST_FILES_CATEGORIES: "LIST_FILES_CATEGORIES",
};
