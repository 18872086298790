import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

export const TicketsSkeleton = () => {
    return (        
        <Stack id="stack-usuario-skeleton" spacing={1} marginTop={1}>
            <Skeleton variant="rectangular" style={{ height: "1600px", width: "760", borderRadius: 10 }} />
        </Stack>
    )
};

export const FieldSkeleton = () => {
    return (        
        <Stack id="stack-usuario-skeleton" spacing={1} marginTop={1}>
            <Skeleton variant="rectangular" style={{ height: "53px", width: "100%", borderRadius: 5 }} />
        </Stack>
    )
}

export const AnswerSkeleton = () =>{
    return (
        <Stack id="stack-usuario-skeleton" spacing={1} marginTop={1}>
            <Skeleton variant="rectangular" style={{ height: "100px", width: "100%", margin: 1}} />
            <Skeleton variant="rectangular" style={{ height: "100px", width: "100%", margin: 1}} />
            <Skeleton variant="rectangular" style={{ height: "100px", width: "100%", margin: 1}} />
            <Skeleton variant="rectangular" style={{ height: "100px", width: "100%", margin: 1}} />
        </Stack>
    )
}

