import React from 'react';
import FoldersList from "../FoldersList";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ContactForm from "../ContactForm";
import useContactsApp from "../../hooks/useContactsApp";
/* import {ASSET_AVATARS} from "../../../../../utils/constants/paths";
import {getAssetPath} from "../../../../../utils/appHelpers"; */

const ContactsAppSidebar = () => {
    const {showDialog, hideDialog} = useJumboDialog();
    const {setContactsListRefresh, company} = useContactsApp();

    const handleContactAdd = React.useCallback(() => {
        hideDialog();
        setContactsListRefresh(true);
    }, [hideDialog, setContactsListRefresh]);

    const showAddContactDialog = React.useCallback(() => {
        showDialog({
            title: "Add new contact",
            content: <ContactForm onSave={handleContactAdd} />
        });
    }, [handleContactAdd, showDialog]);

    return (
        <React.Fragment>
            <FoldersList/>
        </React.Fragment>
    );
};
export default React.memo(ContactsAppSidebar);
