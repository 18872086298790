import {
  GET_PARTS_REQUEST,
  GET_PARTS_SUCCESS,
  GET_PARTS_FAILURE,

  CLEAN_PARTS_STORE,
  
  DELETE_PARTS_REQUEST,
  DELETE_PARTS_SUCCESS,
  DELETE_PARTS_FAILURE,

  POST_PARTS_REQUEST,
  POST_PARTS_FAILURE,
  POST_PARTS_SUCCESS,

  PUT_PARTS_REQUEST,
  PUT_PARTS_FAILURE,
  PUT_PARTS_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function parts(state = initialState, action) {
  switch (action.type) {
    case GET_PARTS_REQUEST:
    case DELETE_PARTS_REQUEST:
    case POST_PARTS_REQUEST:
    case PUT_PARTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PARTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PARTS_SUCCESS:
    case POST_PARTS_SUCCESS:
    case PUT_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_PARTS_FAILURE:
    case POST_PARTS_FAILURE:
    case DELETE_PARTS_FAILURE:
    case PUT_PARTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_PARTS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
