export const KREBSFER = "krebsfer";
export const ROLE_REVENDA = "RESALE";
export const ORIGIN_SELECT_DATA = {
  data: [
    {
      id: 1,
      name: "Krebsfer",
      value: KREBSFER,
    },
    {
      id: 2,
      name: "Revenda",
      value: ROLE_REVENDA,
    },
  ],
};

export const freeTimes = [
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
];

export const osTypes = {
  STANDARD: "standard",
  REDUCER_MAINTENANCE: "reducer_maintenance",
};
