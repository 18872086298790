import React, { useEffect, useRef, useState } from "react";
import api from "app/services/config";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Content, FormBody } from "./style";
import Div from "@jumbo/shared/Div";
import AvatarEditor from "react-avatar-editor";
import { Form, Formik, useFormik, useFormikContext } from "formik";
import {
  EMAIL_INPUT,
  MASKED_INPUT,
  PHOTO_UPLOAD_INPUT,
  SELECT_AUTOCOMPLETE_INPUT,
  TEXT_INPUT,
} from "app/components/DynamicViewGenerator/constants";
import ReactInputMask from "react-input-mask";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Dropzone from "react-dropzone";
import { deleteUser, editUser } from "app/redux/modules/Users/Users/actions";
import Loading from "app/components/Loading";
import InputMask from "react-input-mask";
import UserDetailsSkeleton from "./UserDetailsSkeleton";
import { asyncCompaniesActions, asyncUnitsActions } from "app/redux/reducers";
import * as Yup from "yup";
import { useFetchCompaniesForSelectQuery } from "app/pages/Pivos/features/Company/company-slice";
import {
  useEditUserMutation,
  useFetchUserShowQuery,
} from "../features/Tickets/tickets-slice";
import { LoadingButton } from "@mui/lab";
import CompanyField from "../components/fields/CompanyField";
import UnitField from "../components/fields/UnitField";

const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [phoneMask, setPhoneMask] = useState("(99) 9999-9999");
  const { data: user, isLoading } = useFetchUserShowQuery(id);
  const [showPassword, setShowPassword] = React.useState(false);
  const [avatar, setAvatar] = useState({ image: null, upload: null });
  const handleDrop = (avatar) => {
    setAvatar({ image: avatar[0] });
  };
  const [editor, setEditor] = useState(null);
  const setEditorRef = (editor) => setEditor(editor);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    name: undefined,
    last_name: undefined,
    cpf: undefined,
    email: undefined,
    company_id: undefined,
    unit_id: undefined,
    phone: undefined,
    status: undefined,
  };

  const [editUser, { isLoading: editLoading }] = useEditUserMutation();
  const formik = useFormik({
    initialValues:
      (user && {
        name: user.profile.name || undefined,
        last_name: user.profile.last_name || undefined,
        cpf: user.profile.cpf || undefined,
        email: user.email || undefined,
        company_id: user.profile.unit?.company_id || undefined,
        unit_id: user.profile.unit_id || undefined,
        phone: user.profile.phone || undefined,
        status: user.status || undefined,
      }) ||
      initialValues,
    onSubmit: (data) => {
      delete data.phone;
      editUser({ body: data, id: user.id });
      setAvatar({ image: null, upload: null });
      history.back();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Obrigatório"),
      last_name: Yup.string().required("Obrigatório"),
      cpf: Yup.string().required("Obrigatório"),
      email: Yup.string().email("Email inválido").required("Obrigatório"),
      company_id: Yup.string().required("Obrigatório"),
      unit_id: Yup.string().required("Obrigatório"),
      status: Yup.string().required("Obrigatório"),
    }),
    enableReinitialize: true,
  });

  const selectStatusOptions = [
    {
      id: 1,
      label: "Ativo",
      name: "ativo",
    },
    {
      id: 0,
      label: "Desativado",
      name: "desativado",
    },
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Card className="mb-5">
          <CardContent>
            <Grid container>
              <Grid item lg={1}>
                <FormControl
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Dropzone
                    onDrop={handleDrop}
                    noClick={avatar.image && true}
                    noKeyboard
                    style={{ width: "100px", height: "100px" }}
                    accept="image/jpeg, image/png"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        {avatar.image ? (
                          <AvatarEditor
                            ref={setEditorRef}
                            onImageReady={() =>
                              formik.setFieldValue("avatar", avatar.image)
                            }
                            onMouseUp={() => {
                              const canvas = editor.getImageScaledToCanvas();
                              canvas.toBlob((blob) => {
                                const file = new File(
                                  [blob],
                                  avatar.image.name,
                                  {
                                    type: avatar.image.type,
                                  }
                                );
                                formik.setFieldValue("avatar", file);
                              }, avatar.type);
                            }}
                            width={100}
                            height={100}
                            borderRadius={15}
                            image={avatar.image}
                          />
                        ) : (
                          <Div
                            sx={{
                              width: 80,
                              height: 80,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              bgcolor: "#ccc",
                              borderRadius: "15px",

                              overflow: "hidden",
                            }}
                          >
                            {user?.profile?.avatar ? (
                              <img
                                src={`${process.env.REACT_APP_PHOTO_PATH}${user?.profile?.avatar}`}
                                width={90}
                                height={90}
                              />
                            ) : (
                              <Typography
                                variant={"h6"}
                                color={"#fff"}
                                mb={0}
                                align="center"
                              >
                                Selecionar Foto
                              </Typography>
                            )}
                          </Div>
                        )}
                        <input
                          id="avatar"
                          name="avatar"
                          label="Avatar"
                          {...getInputProps()}
                          InputProps={{
                            autoComplete: "off",
                          }}
                        />
                      </div>
                    )}
                  </Dropzone>
                </FormControl>
              </Grid>
              <Grid item lg={11} className={"pl-1"}>
                <Box
                  className={
                    "align-middle flex flex-wrap flex-col  h-[100%] justify-center"
                  }
                >
                  <Typography variant={"h5"}>
                    {user?.profile?.name} {user?.profile?.last_name || ""}
                  </Typography>
                  <Typography className={"text-gray-300 capitalize"}>
                    {user?.profile?.unit?.company?.name || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="Informações do Usuário" />
          <CardContent className="px-10">
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    style={{ zIndex: 0 }}
                    id="name"
                    value={formik.values.name}
                    name="name"
                    label="Nome"
                    InputProps={{
                      autoComplete: "off",
                    }}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    /* defaultValue={initialformik.values[field.value]} */
                    value={formik.values.last_name}
                    style={{ zIndex: 0 }}
                    id="last_name"
                    name="last_name"
                    label="Último Nome"
                    InputProps={{
                      autoComplete: "off",
                    }}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <ReactInputMask
                    mask="999.999.999-99"
                    fullWidth
                    /* defaultValue={initialformik.values[field.value]} */
                    style={{ zIndex: 0 }}
                    value={formik.values.cpf}
                    id="cpf"
                    name="cpf"
                    label="CPF"
                    InputProps={{
                      autoComplete: "off",
                    }}
                    onChange={formik.handleChange}
                  >
                    {() => (
                      <TextField
                        label="CPF"
                        id="cpf"
                        name="cpf"
                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                        helperText={formik.touched.cpf && formik.errors.cpf}
                      />
                    )}
                  </ReactInputMask>
                </FormControl>
              </Grid>
              <Grid item lg={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    /* defaultValue={initialformik.values[field.value]} */
                    style={{ zIndex: 0 }}
                    id="email"
                    value={formik.values.email}
                    name="email"
                    label="Email"
                    InputProps={{
                      autoComplete: "off",
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={12}>
                <CompanyField formikRef={formik} />
              </Grid>
              <Grid item lg={12}>
                <UnitField formikRef={formik} />
              </Grid>
              <Grid item lg={12}>
                <FormControl fullWidth>
                  <InputMask
                    mask={phoneMask}
                    onBlur={(e) => {
                      if (e.target.value.endsWith("_")) {
                        setPhoneMask("(99) 9999-9999");

                        formik.setFieldValue(
                          "phone",
                          e.target.value.replace(/\D/g, "")
                        );
                      }
                    }}
                    onFocus={(e) => {
                      if (!e.target.value.endsWith("_")) {
                        setPhoneMask("(99) 9 9999-9999");
                        formik.setFieldValue(
                          "phone",
                          e.target.value.replace(/\D/g, "")
                        );
                      }
                    }}
                    fullWidth
                    style={{ zIndex: 0 }}
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    label="Telefone"
                    InputProps={{
                      autoComplete: "asdasdasd",
                      role: "presentation",
                    }}
                    onChange={formik.handleChange}
                  >
                    {() => (
                      <TextField
                        label="Telefone"
                        id="phone"
                        name="phone"
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                        role="presentation"
                        autoComplete="asdasd"
                      />
                    )}
                  </InputMask>
                </FormControl>
              </Grid>

              <Grid item lg={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    options={selectStatusOptions}
                    fullWidth
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={selectStatusOptions.find(
                      (option) => option.id === formik.values.status
                    )}
                    onChange={(event, value) =>
                      formik.setFieldValue(
                        "status",
                        value !== null ? value.id : formik.values.status
                      )
                    }
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id="status"
                        name="status"
                        label="Status"
                        error={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={12}>
                <Stack flexDirection="row" justifyContent={"flex-end"}>
                  <LoadingButton
                    loading={isLoading}
                    type="submit"
                    variant="contained"
                    color="success"
                  >
                    Salvar
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  );
};

export default UserDetails;
