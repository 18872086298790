import { Image } from "@mui/icons-material";
import React, { forwardRef } from "react";

import { CustomIcons } from "./CustomIcons";
import { Link } from "react-router-dom";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteFileMutation } from "../features/fileManager-slice";
import useToast from "app/hooks/useToast";
import useContextMenu from "app/hooks/useContextMenu";

const LifeManagerItens = forwardRef(({ item }, ref) => {
  const { dispatchToast } = useToast();

  const [deleteFile, { isLoading }] = useDeleteFileMutation();
  const handleDeleteClick = () => {
    deleteFile({ id: item.id });
    dispatchToast({
      message: `O arquivo ${item.name} foi deletado com sucesso!`,
      variant: "success",
    });
  };
  const { contextMenu, handleContextMenu, handleClose } = useContextMenu();

  return (
    <div
      key={item.id}
      onContextMenu={handleContextMenu}
      className="flex-col flex items-center p-4 cursor-pointer hover:bg-white hover:shadow-md rounded-lg relative group"
    >
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem color="error" onClick={() => handleDeleteClick()}>
          <DeleteIcon />
          delete
        </MenuItem>
      </Menu>

      <div className="w-10 h-14">
        <Link ref={ref} to={item.url} target="_blank">
          {item.extension === "png" ||
          item.extension === "jpg" ||
          item.extension === "PNG" ||
          item.extension === "jpeg" ? (
            <div className="w-10 h-14 bg-gray-100 rounded-sm flex flex-wrap items-center content-center p-1 relative">
              <div className="border-solid border-b-gray-500 border-l-gray-500 border-[5px]  border-transparent absolute top-0 right-0 z-20 shadow-sm" />
              <div className="w-2 h-2 absolute top-0 right-0 bg-white z-10 " />
              <img src={item.url} alt={item.name} />
            </div>
          ) : (
            <div>
              <CustomIcons icon={item.extension} alt={item.name} />
            </div>
          )}
        </Link>
      </div>
      <Link ref={ref} to={item.url} target="_blank">
        <span className="text-sm lowercase p-2 font-light text-black text-center  flex">
          {item.name}
        </span>{" "}
      </Link>
    </div>
  );
});

export default LifeManagerItens;
