import React, { useState } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_IMAGES, ASSET_KREBS } from "../../../utils/constants/paths";
import { history } from "app/redux/store";
import { appTheme } from "app/themes/app/theme";
import { useDispatch } from "react-redux";
import api from "../../../services/config";
import { login } from "app/redux/actions/auth";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login1 = () => {
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const theme = useTheme();

  const Swal = useSwalWrapper();

  const navigate = useNavigate();
  const { setActiveLayout } = useJumboApp();

  const [errorMessage, setErrorMessage] = useState("");

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    return () => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
    };
  }, []);

  React.useEffect(() => {
    if (auth.token) {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);

      navigate("/os", { replace: true });
    }
  }, [auth]);

  const mutation = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      console.log(error);
    },
    onSuccess(data) {
      navigate("/", { replace: true });
    },
  });

  const onSignIn = async (email, password) => {
    try {
      const { data } = await api.post("session", { email, password });

      if (!["ADMIN", "SAC", "RESALE"].includes(data.user.role[0].name)) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Usuário não autorizado.",
        });

        return;
      }

      dispatch(login(data.token, data.user));
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);

      navigate("/os", { replace: true });
    } catch (error) {
      console.log(error);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Credenciais Inválidas",
        //background: appTheme.palette.red100,
      });
    }
    //mutation.mutate({email, password});
  };

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Div sx={{ mb: 1, display: "inline-flex" }}>
        <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
          <img src={`${ASSET_KREBS}/logo_smaller.png`} alt="Krebs Logo" />
        </Link>
      </Div>
      <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
        <Div sx={{ position: "relative", height: "200px" }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="200"
            image={`${ASSET_IMAGES}/pivo.jpg`}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              backgroundColor: (theme) =>
                alpha(theme.palette.common.black, 0.5),
              p: (theme) => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: "common.white",
                fontSize: "1.5rem",
                mb: 0,
              }}
            >
              Krebs OS
            </Typography>
          </Div>
        </Div>
        <CardContent sx={{ pt: 0 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              email:
                process.env.REACT_APP_ENV === "production"
                  ? ""
                  : "adm@krebs.com.br",
              password:
                process.env.REACT_APP_ENV === "production" ? "" : "krebs1320",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSignIn(data.email, data.password);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                {/* <Avatar
                                    alt="Remy Sharp" src={`${ASSET_KREBS}/icone35-krebs.png`}
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        marginLeft: 'auto',
                                        objectFit: "cover",
                                        boxShadow: shadows[3],
                                        transform: 'translateY(-50%)',
                                    }}
                                /> */}
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Div sx={{ mb: 3, mt: 5 }}>
                  <JumboTextField fullWidth name="email" label="Email" />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                  />
                </Div>

                {errorMessage && (
                  <Div
                    sx={{
                      mb: 2,
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant={"p"}
                      sx={{
                        color: appTheme.palette.red200,
                        fontSize: "1rem",
                        mb: 0,
                      }}
                    >
                      {errorMessage}
                    </Typography>
                  </Div>
                )}
                <Typography textAlign={"right"} variant={"body1"}>
                  <Link
                    underline="none"
                    href="/recupera-senha"
                    color={appTheme.palette.red200}
                  >
                    Esqueceu a senha?
                  </Link>
                </Typography>
                <Div sx={{ mb: 1 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Lembre-se de mim"
                  />
                </Div>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    mb: 3,
                    bgcolor: appTheme.palette.red100,
                    "&:hover": {
                      bgcolor: appTheme.palette.red200,
                    },
                  }}
                  loading={isSubmitting || mutation.isLoading}
                >
                  Entrar
                </LoadingButton>
                {/* <Typography textAlign={"center"} variant={"body1"} mb={1}>Não tem um conta?
                                    <Link
                                        underline="none"
                                        href="/cadastro"
                                        color={appTheme.palette.red200}
                                    >{" "}Cadastre-se</Link>
                                </Typography> */}
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {/* <Typography variant={"body1"} mb={2}>Ou entre com</Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                    sx={{
                        bgcolor: '#385196',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#385196',
                        }
                    }}
                    aria-label="Facebook"
                >
                    <Facebook fontSize={"small"}/>
                </IconButton>
                <IconButton
                    sx={{
                        bgcolor: '#00a8ff',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#00a8ff',
                        }
                    }}
                    aria-label="Twitter"
                >
                    <Twitter fontSize={"small"}/>
                </IconButton>
                <IconButton
                    sx={{
                        bgcolor: '#23272b',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#23272b',
                        }
                    }}
                    aria-label="Twitter"
                >
                    <Google fontSize="small"/>
                </IconButton>
            </Stack> */}
    </Div>
  );
};

export default Login1;
