import React from "react";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { FieldSkeleton } from "./Skeleton";

export default function MenuLocationSelectInput({
  fieldName,
  fieldLabel,
  formik,
  data,
  handlePreviewData,
  getNextFieldData,
  setPropertyLocation,
  loading,
}) {
  const dispatch = useDispatch();

  return (
    <>
      {loading ? (
        <FieldSkeleton />
      ) : (
        <FormControl sx={{ mb: 1 }}>
          <TextField
            data-testid={`input-${fieldName}`}
            key={fieldName}
            fullWidth
            select
            id={fieldName}
            name={fieldName}
            label={fieldLabel}
            disabled={!(data.data && data.data.length > 0)}
            value={formik.values[fieldName] || ""}
            error={
              formik.touched[fieldName] && Boolean(formik.errors[fieldName])
            }
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            onChange={(e) => {
              formik.handleChange(e);
              let name = "";
              if (fieldName === "property") {
                data.data.map((dt) => {
                  if (dt.id == e.target.value) {
                    name = dt.address;
                    setPropertyLocation({
                      lat: Number(dt.latitude),
                      lng: Number(dt.longitude),
                    });
                    handlePreviewData(fieldName, name);
                  }
                });
              }
              dispatch(
                getNextFieldData("", "", "", "", "", true, e.target.value)
              );
            }}
          >
            {data.data && data.data.length > 0 ? (
              data.data.map((item, index) => (
                <MenuItem
                  data-testid={`select-option-${fieldName}-${index}`}
                  key={index}
                  value={item.id}
                >
                  {item.address}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No options</MenuItem>
            )}
          </TextField>
        </FormControl>
      )}
    </>
  );
}
