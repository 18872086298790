import {
  GET_CITIES_REQUEST,
  GET_CITIES_FAILURE,
  GET_CITIES_SUCCESS,  
} from "./constants";

import api from "app/services/config";

  export const getCities =
  (id) => async (dispatch, getState) => {
    dispatch({ type: GET_CITIES_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.get(`/cities?state_id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_CITIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CITIES_FAILURE,
        payload: error.message,
      });
    }
  };
