import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import useFileManagerContext from "../../hooks/useFileManagerContext";

import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { FormSelectInput, FromFileUploadInput } from "focal-ui-react";
import { useUploadFilesMutation } from "../../features/fileManager-slice";
import * as yup from "yup";
import useToast from "app/hooks/useToast";
import FileCategoriesInput from "../inputs/FileCategoriesInput";
import FileUploadInput from "../inputs/FileUploadInput";
import { useParams } from "react-router-dom";

const validationSchema = yup.object({
  file_manager_category_id: yup.string().required("Categoria é obrigatória"),
  files: yup.array().required("Envie ao menos um arquivo."),
});

function FileUploadModal() {
  const { id } = useParams();
  const { dispatchToast } = useToast();
  const { modalIsOpen, setOpenModal } = useFileManagerContext();
  const [uploadFiles, { isSuccess, isError, isLoading }] =
    useUploadFilesMutation();
  const formik = useFormik({
    initialValues: {
      file_manager_category_id: undefined,
      files: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await uploadFiles(values)
        .unwrap()
        .then((res) => {
          dispatchToast({
            message: "Arquivos enviados com sucesso!",
            variant: "success",
          });
        })
        .catch((err) => {
          dispatchToast({
            message: "Erro ao enviar os arquivos, tente novamente mais tarde.",
            variant: "error",
          });
        })
        .finally(() => {
          setOpenModal(false);
          formik.resetForm();
        });
    },
  });

  if (!modalIsOpen) return null;

  return (
    <Modal
      open={modalIsOpen}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex items-center justify-center min-h-screen"
    >
      <div className="bg-white w-1/3 rounded-xl shadow-xl max-h-[80vh] overflow-auto">
        <div className="p-5 border-solid border-b-[0.5px] border-gray-200 text-md">
          Enviar arquivos para o sistema
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-5 flex flex-col gap-5">
            <FileCategoriesInput label="Selecione a pasta" formikRef={formik} />
            <FileUploadInput formikRef={formik} />
          </div>
          <div className="p-5 border-solid border-t-[0.5px] border-gray-200">
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              color="success"
              onClick={() => {}}
              size="small"
            >
              Salvar Arquivos
            </LoadingButton>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default FileUploadModal;
