//RESOLUTION_CATEGORIES Types

export const GET_RESOLUTION_CATEGORIES_REQUEST = "GET_RESOLUTION_CATEGORIES_REQUEST";
export const GET_RESOLUTION_CATEGORIES_FAILURE = "GET_RESOLUTION_CATEGORIES_FAILURE";
export const GET_RESOLUTION_CATEGORIES_SUCCESS = "GET_RESOLUTION_CATEGORIES_SUCCESS";

export const GET_SINGLE_RESOLUTION_CATEGORIES_REQUEST = 'get-single-RESOLUTION_CATEGORIES-request'
export const GET_SINGLE_RESOLUTION_CATEGORIES_FAILURE = 'get-single-RESOLUTION_CATEGORIES-failure';
export const GET_SINGLE_RESOLUTION_CATEGORIES_SUCCESS = 'get-single-RESOLUTION_CATEGORIES-success';

export const POST_RESOLUTION_CATEGORIES_REQUEST = "POST_RESOLUTION_CATEGORIES_REQUEST";
export const POST_RESOLUTION_CATEGORIES_FAILURE = "post-RESOLUTION_CATEGORIES-failure";
export const POST_RESOLUTION_CATEGORIES_SUCCESS = "post-RESOLUTION_CATEGORIES-success";

export const PUT_RESOLUTION_CATEGORIES_REQUEST = "PUT_RESOLUTION_CATEGORIES_REQUEST";
export const PUT_RESOLUTION_CATEGORIES_FAILURE = "put-RESOLUTION_CATEGORIES-failure";
export const PUT_RESOLUTION_CATEGORIES_SUCCESS = "put-RESOLUTION_CATEGORIES-success";

export const DELETE_RESOLUTION_CATEGORIES_REQUEST = "DELETE_RESOLUTION_CATEGORIES_REQUEST";
export const DELETE_RESOLUTION_CATEGORIES_FAILURE = "delete-RESOLUTION_CATEGORIES-failure";
export const DELETE_RESOLUTION_CATEGORIES_SUCCESS = "delete-RESOLUTION_CATEGORIES-success";

export const CLEAN_RESOLUTION_CATEGORIES_STORE = "CLEAN_RESOLUTION_CATEGORIES_STORE";