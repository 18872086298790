import {
  asyncTicketsActions,
  asyncTicketsResponsesActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, Skeleton, Stack, TextField } from "@mui/material";
import Div from "@jumbo/shared/Div";
import "./style.css";
import { colors } from "app/utils/Colors";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SplitButton from "app/components/SplitButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { normalizeDate, renderAvatar, firstLetter } from "app/utils/appHelpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import SwitchTickets from "app/components/SwitchTickets";

import TicketHeader from "./components/TicketHeader";
import TicketResponse from "./components/TicketResponse";
import EditorField from "./components/fields/EditorField";
import AttendantField from "./components/fields/AttendantField";
import CategoryField from "./components/fields/CategoryField";
import CompanyField from "./components/fields/CompanyField";
import UnitField from "./components/fields/UnitField";
import CustomerField from "./components/fields/CustomerField";
import FamilyField from "./components/fields/FamilyField";
import { useNewTicketMutation } from "./features/Tickets/tickets-slice";

const validationSchema = Yup.object().shape({
  client_id: Yup.string().required("Obrigatório"),
  title: Yup.string().required("Obrigatório"),
  family_id: Yup.string().required("Obrigatório"),
  category_id: Yup.string().required("Obrigatório"),
  attendant_id: Yup.string().required("Obrigatório"),
  company_id: Yup.string().required("Obrigatório"),
  unit_id: Yup.string().required("Obrigatório"),
});

const initialValues = {
  title: "",
  description: "",
  category_id: undefined,
  status: "",
  attendant_id: undefined,
  client_id: undefined,
  company_id: undefined,
  unit_id: undefined,
  family_id: undefined,
};

const NewTicket = () => {
  const [switchState, setSwitchState] = React.useState(true);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [newTicket, { isLoading: loading }] = useNewTicketMutation();
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      handleCreate(values);
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
  });

  const handleCreate = (values) => {
    const data = {
      title: values.title,
      description: values.description,
      ticket_channel_id: 3,
      ticket_category_id: values.category_id,
      ticket_status_id: values.status,
      responsible_user_id: values.client_id,
      unit_id: values.unit_id,
      user_id: values.attendant_id,
      product_family_id: values.family_id,
    };
    newTicket(data);
    navigate("/tickets/aberto");
  };

  return (
    <>
      <TicketHeader />
      <form style={$form} onSubmit={formik.handleSubmit}>
        <Div style={$container}>
          <Stack style={$leftBlock}>
            <Div className="py-10 px-5 border-solid border-0 border-b-[1px] border-gray-300 flex gap-5 flex-col">
              <span>Informações do atendimento</span>
              <FormControl className="bg-white">
                <TextField
                  name="title"
                  label="Título"
                  variant="outlined"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <AttendantField formikRef={formik} />
              <CategoryField formikRef={formik} />
              <span>Dados do cliente</span>
              <CompanyField formikRef={formik} />
              <UnitField formikRef={formik} />
              <CustomerField formikRef={formik} />
              <span>Dados complementares</span>
              <FamilyField formikRef={formik} />
            </Div>
          </Stack>
          <Stack className={"midBlock"} style={$midBlock}>
            <Div
              style={
                switchState
                  ? {
                      ...$ticketResponseFieldBlockWrapper,
                      ...$switchOn,
                    }
                  : {
                      ...$ticketResponseFieldBlockWrapper,
                      ...$switchOff,
                    }
              }
            >
              <Div style={$ticketResponseFieldBlock}>
                {auth.user.profile.avatar ? (
                  <Div>{renderAvatar(auth.user.profile.avatar)}</Div>
                ) : (
                  <Div>{firstLetter(auth.user.profile.name)}</Div>
                )}

                <EditorField formikRef={formik} />
              </Div>
            </Div>
          </Stack>
        </Div>
        <Div
          style={{
            ...$footerBlock,
            justifyContent: "space-between",
          }}
        >
          <div />
          <SplitButton loading={loading} setFieldValue={formik.setFieldValue} />
        </Div>
      </form>
    </>
  );
};
const $form = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const $ticketResponseFieldBlock = {
  padding: 20,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  transition: "background-color 0.4s ease",
};
const $ticketResponseFieldBlockWrapper = {
  transition: "background-color 0.4s ease",
};

const $switchOn = {
  backgroundColor: "#fff",
};

const $switchOff = {
  backgroundColor: "#fff6f6",
};

const $titleTicketBlock = {
  display: "flex",
  flexDirection: "row",
  padding: 20,
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
};
const $ticektTitle = {
  fontSize: 20,
  fontWeight: 500,
};

const $footerBlock = {
  backgroundColor: colors.standardWhite,
  maxWidth: "100%",
  padding: 10,
  flexDirection: "row",
  display: "flex",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
  minHeight: "6vh",
  flexWrap: "wrap",
  alignContent: "center",
};

const $midBlock = {
  backgroundColor: "#fff",
  flex: 1,
  overflow: "scroll",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "red",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  maxHeight: "77vh",
};

const $leftBlock = {
  backgroundColor: colors.standardGray,
  flex: 1,
  maxWidth: "25%",
};

const $container = {
  flex: 1,
  display: "flex",
  padding: 0,
};

export default NewTicket;
