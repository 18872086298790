import JumboSearch from "@jumbo/components/JumboSearch";
import { FormControl } from "@mui/material";
import React from "react";
import useFileManagerContext from "../../hooks/useFileManagerContext";
import { debounce } from "lodash";

function SearchInput() {
  const { setKeyWord } = useFileManagerContext();

  const handleSearch = debounce((value) => {
    setKeyWord(value);
  }, 500);

  return (
    <FormControl className="min-[1500px]:w-[212px] w-[130px]">
      <JumboSearch
        onChange={(value) => handleSearch(value)}
        placeholder="Pesquisar"
        sx={{
          display: { md: "block" },
          flex: 1,
          bgcolor: "#FFF",
        }}
      />
    </FormControl>
  );
}

export default SearchInput;
