import React from "react";
import FileManagerListInsideFolderComponent from "./components/FileManagerListInsideFolderComponent";
import FileManagerProvider, { FileManagerContext } from "./FileManagerProvider";
import FileManagerHeader from "./components/FileManagerHeader";
import FileUploadModal from "./components/FileUploadModal/FileUploadModal";
import NewCategoryModal from "./components/NewCategoryModal/NewCategoryModal";

function FileManagerInsideFolder() {
  return (
    <FileManagerProvider>
      <NewCategoryModal />

      <FileUploadModal />
      <FileManagerHeader />
      <FileManagerListInsideFolderComponent />
    </FileManagerProvider>
  );
}

export default FileManagerInsideFolder;
