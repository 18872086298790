import {
  asyncFilterActions,
  asyncFamilyIssuesActions,
  asyncProductFamiliesActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import {
  DATA_TYPE_CHIP,
  DATA_TYPE_DATE,
  DATA_TYPE_ID,
  DATA_TYPE_STRING,
} from "app/components/DynamicViewGenerator/constants";
import { CLEAN_PRODUCT_MODEL_STORE } from "app/redux/modules/Products/productModel/constants";
import * as Yup from "yup";
import { CLEAN_FAMILY_ISSUES_STORE } from "app/redux/modules/Products/FamilyIssues/constants";
import { CLEAN_SEARCH } from "app/redux/modules/Search/constants";

const FamilyIssues = () => {
  const dispatch = useDispatch();
  const { familyIssues, productFamilies, auth } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);
  const [categoryFilter, setCategoryFilter] = React.useState([]);
  const [newSelectFamilyOptions, setNewSelectFamilyOptions] = React.useState(
    []
  );

  const startPage = async () => {
    dispatch(asyncFamilyIssuesActions.get("asc", "id"));
    dispatch(
      asyncProductFamiliesActions.getProductFamilies(
        "asc",
        "name",
        1,
        1,
        "",
        true
      )
    );
  };

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_FAMILY_ISSUES_STORE });
      dispatch({ type: CLEAN_SEARCH });
    };
  }, [dispatch]);

  useEffect(() => {
    if (productFamilies.data) {
      setNewSelectFamilyOptions(
        productFamilies.data &&
          productFamilies?.data?.data?.map(({ id, name }) => ({ id, name }))
      );
    }
  }, []);

  useEffect(() => {
    if (productFamilies.data) {
      setNewSelectOptions(
        productFamilies.data.data &&
          productFamilies.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [productFamilies]);

  const dataLabels = [
    {
      id: "Id",
      name: "Nome",
      description: "Descrição",
      product_family_id: "Família do Produto",
      created_at: "Data de Criação",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      description: DATA_TYPE_STRING,
      status: DATA_TYPE_CHIP,
      created_at: DATA_TYPE_DATE,
      product_family_id: {
        parent: "product_family",
        name: "name",
      },
    },
  ];

  const formFieldsType = [
    {
      type: "text",
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório"),
    },
    {
      type: "text",
      value: "description",
      label: "Descrição",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório"),
    },
    {
      type: "select",
      value: "product_family_id",
      label: "Família do Produto",
      options: newSelectOptions,
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório"),
    },
    {
      type: "status",
      value: "status",
      label: "Status",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório"),
    },
  ];

  const pageConfig = {
    title: "Ocorrências de Família",
    newText: "Novo ocorrência",
    searchText: "Pesquisar",
    data: familyIssues.data,
    loading: familyIssues.loading,
    familySearch: true,
    familyData: newSelectFamilyOptions,
    actions: {
      delete: asyncFamilyIssuesActions.delete,
      edit: asyncFamilyIssuesActions.edit,
      get: asyncFamilyIssuesActions.get,
      search: asyncFamilyIssuesActions.get,
      create: asyncFamilyIssuesActions.create,
      clean: { type: CLEAN_FAMILY_ISSUES_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default FamilyIssues;
