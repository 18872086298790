import { FormControl } from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";

function InitialDateSo({ formik, handlePreviewData }) {
  return (
    <FormControl sx={{ mb: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          data-testid={"input-date"}
          disablePast
          id="date"
          name="date"
          label="Início"
          format="DD/MM/YYYY"
          minDate={moment().utc()}
          onAccept={(newValue) => {
            if (newValue) {
              formik.setFieldValue("scheduled_at", newValue, true);
              const date = newValue.format("DD/MM/YYYY");

              handlePreviewData((previewData) => ({
                ...previewData,
                scheduled_at: [date, previewData.scheduled_at[1]],
              }));

              if (formik.values["end"]) {
                const end = formik.values["end"];

                const diffEnd = end.diff(newValue, "d");

                if (diffEnd < 0) {
                  formik.setFieldValue("end", newValue);

                  handlePreviewData((prev) => ({
                    ...prev,
                    endAt: [newValue.format("DD/MM/YYYY"), prev.endAt[1]],
                  }));
                }
              }
            }
          }}
          onChange={(newValue) => {
            if (newValue && newValue.format("DD/MM/YYYY") != "Invalid Date") {
              formik.setFieldValue("scheduled_at", newValue, true);
              const date = newValue.format("DD/MM/YYYY");

              handlePreviewData((previewData) => ({
                ...previewData,
                scheduled_at: [date, previewData.scheduled_at[1]],
              }));
            }
          }}
          value={formik.values.scheduled_at}
          slotProps={{
            textField: {
              variant: "outlined",
              error:
                formik.touched.scheduled_at &&
                Boolean(formik.errors.scheduled_at),
              helperText:
                formik.touched.scheduled_at && formik.errors.scheduled_at,
              onBlur: () => {
                if (formik.values["scheduled_at"]) {
                  const today = dayjs().startOf("day");
                  const date = formik.values["scheduled_at"];
                  const diff = date.diff(today, "d");

                  handlePreviewData((prev) => ({
                    ...prev,
                    scheduled_at: [
                      date.format("DD/MM/YYYY"),
                      prev.scheduled_at[1],
                    ],
                  }));

                  if (diff < 0) {
                    formik.setFieldValue("scheduled_at", today);

                    handlePreviewData((prev) => ({
                      ...prev,
                      scheduled_at: [
                        today.format("DD/MM/YYYY"),
                        prev.scheduled_at[1],
                      ],
                    }));
                  }

                  if (formik.values["end"]) {
                    const end = formik.values["end"];

                    const diffEnd = end.diff(date, "d");

                    if (diffEnd < 0) {
                      formik.setFieldValue("end", date);

                      handlePreviewData((prev) => ({
                        ...prev,
                        endAt: [date.format("DD/MM/YYYY"), prev.endAt[1]],
                      }));
                    }
                  }
                }
              },
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
}

export default InitialDateSo;
