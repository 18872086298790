import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const fieldsSlice = createApi({
  reducerPath: "fieldsSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchAttendantField: builder.query({
        query(id) {
          return `fields/attendants`;
        },
      }),
      fetchCustomerField: builder.query({
        query(id) {
          return `fields/customer/${id}`;
        },
      }),
      fetchCompanyField: builder.query({
        query() {
          return `fields/company/`;
        },
      }),
      fetchUnitField: builder.query({
        query(id) {
          return `fields/unit/${id}`;
        },
      }),
      fetchTicketCategoryField: builder.query({
        query() {
          return `tickets/categories?orderBy=id,desc`;
        },
      }),
      fetchFamilyField: builder.query({
        query() {
          return `product-families?orderBy=id,desc`;
        },
      }),
    };
  },
});

export const {
  useFetchAttendantFieldQuery,
  useFetchCompanyFieldQuery,
  useLazyFetchCustomerFieldQuery,
  useFetchCustomerFieldQuery,
  useLazyFetchUnitFieldQuery,
  useFetchTicketCategoryFieldQuery,
  useFetchFamilyFieldQuery,
} = fieldsSlice;
