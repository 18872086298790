//TICKETS Types

export const GET_TICKETS_CC_REQUEST = "GET_TICKETS_CC_REQUEST";
export const GET_TICKETS_CC_FAILURE = "GET_TICKETS_CC_FAILURE";
export const GET_TICKETS_CC_SUCCESS = "GET_TICKETS_CC_SUCCESS";

export const ADD_TICKETS_CC_REQUEST = "ADD_TICKETS_CC_REQUEST";
export const ADD_TICKETS_CC_FAILURE = "ADD_TICKETS_CC_FAILURE";
export const ADD_TICKETS_CC_SUCCESS = "ADD_TICKETS_CC_SUCCESS";

export const CLEAN_TICKETS_CC_STORE = "CLEAN_TICKETS_CC_STORE";

