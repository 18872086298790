import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pivot_id: undefined,
  reducers_ids: [],
};

const soSlice = createSlice({
  name: "so",
  initialState,
  reducers: {
    setPivotId(state, action) {
      state.pivot_id = action.payload;
    },
    setSelectedReducers(state, action) {
      state.reducers_ids = action.payload;
    },
  },
});

export const { setPivotId, setSelectedReducers } = soSlice.actions;
export default soSlice.reducer;
