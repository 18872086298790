import {
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import Lottie from "lottie-react";
import locationAnimation from "assets/animations/locationAnimation.json";
import SimpleMap from "../SimpleMap";

function PreviewDataSo({ propertyLocation, previewData }) {
  return (
    <Stack direction="column" flex={1} p={5}>
      {propertyLocation ? (
        <Div data-testid="unit-map">
          <SimpleMap location={propertyLocation} />
        </Div>
      ) : (
        <Stack width="100%" height="400px" alignItems="center">
          <Lottie
            animationData={locationAnimation}
            loop={true}
            style={{
              height: 350,
            }}
          />
          <ListItemText primary="Selecione uma unidade" />
        </Stack>
      )}

      <Div>
        <CardHeader title="Dados da Ordem de Serviço" sx={{ mb: -1, ml: -2 }} />
        <Divider sx={{ mb: 2 }} />

        <List disablePadding sx={{ width: "100%" }}>
          <ListItem
            alignItems="flex-start"
            sx={{
              width: "80%",
              display: "grid",
              gridRow: "1",
              gridTemplateColumns: "repeat(2,1fr)",
              rowGap: 2,
              p: (theme) => theme.spacing(1.25, 1),
            }}
          >
            <ListItemText primary="Empresa" />
            <ListItemText secondary={previewData.company} />
            <ListItemText primary="Unidade" />
            <ListItemText secondary={previewData.property} />
            <ListItemText primary="Cliente" />
            <ListItemText secondary={previewData.client} />
            <ListItemText primary="Família" />
            <ListItemText secondary={previewData.family} />
            <ListItemText primary="Produto" />
            <ListItemText secondary={previewData.product} />
            <ListItemText primary="Modelo" />
            <ListItemText secondary={previewData.model} />
            <ListItemText primary="Ocorrências" />
            <ListItemText secondary={previewData.issue} />
            <ListItemText primary="Descrição" />
            <ListItemText secondary={previewData.description} />
            <ListItemText primary="Observações Internas" />
            <ListItemText secondary={previewData.internal_observations} />
            <ListItemText primary="Nota Fiscal" />
            <ListItemText secondary={previewData.invoice} />
            <ListItemText primary="Número de Série" />
            <ListItemText secondary={previewData.serial_number} />
            <ListItemText primary="Tipo de Serviço" />
            <ListItemText secondary={previewData.service_type} />
            <ListItemText primary="Agendado para" />
            <ListItemText
              secondary={`${
                previewData.scheduled_at[0] != "" &&
                !previewData.scheduled_at[0].includes("inválida")
                  ? previewData.scheduled_at[0]
                  : ""
              } ${previewData.scheduled_at[1]} ${
                previewData.endAt[0] != "" &&
                !previewData.endAt[0].includes("inválida")
                  ? `até ${previewData.endAt[0]} ${previewData.endAt[1]}`
                  : ""
              }`}
            />
            <ListItemText primary="Técnicos responsáveis" />
            <ListItemText secondary={previewData.responsibles} />
          </ListItem>
        </List>
      </Div>
    </Stack>
  );
}

export default PreviewDataSo;
