import {
  START_NEW_TICKET_FAILURE,
  START_NEW_TICKET_SUCCESS,
  START_NEW_TICKET_REQUEST,
  GET_SINGLE_TICKETS_DETAIL_REQUEST,
  GET_SINGLE_TICKETS_DETAIL_FAILURE,
  GET_SINGLE_TICKETS_DETAIL_SUCCESS,
  CLEAN_TICKETS_DETAILS_STORE,
} from "./constants";

const initialState = {
  data: {
    sac: [],
    ticketCategories: [],
    companies: [],
    productFamilies: [],
    units: [],
    ticketsResponses: [],
    customers: []
  },
  loading: false,
  error: null,
};

export default function ticketDetails(state = initialState, action) {
  switch (action.type) {
    case GET_SINGLE_TICKETS_DETAIL_REQUEST:
    case START_NEW_TICKET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SINGLE_TICKETS_DETAIL_SUCCESS:
    case START_NEW_TICKET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GET_SINGLE_TICKETS_DETAIL_FAILURE:
    case START_NEW_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_TICKETS_DETAILS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
