//EMAIL Types

export const GET_EMAIL_REQUEST = "GET_EMAIL_REQUEST";
export const GET_EMAIL_FAILURE = "GET_EMAIL_FAILURE";
export const GET_EMAIL_SUCCESS = "GET_EMAIL_SUCCESS";

export const GET_SINGLE_EMAIL_REQUEST = 'get-single-EMAIL-request'
export const GET_SINGLE_EMAIL_FAILURE = 'get-single-EMAIL-failure';
export const GET_SINGLE_EMAIL_SUCCESS = 'get-single-EMAIL-success';

export const POST_EMAIL_REQUEST = "POST_EMAIL_REQUEST";
export const POST_EMAIL_FAILURE = "post-EMAIL-failure";
export const POST_EMAIL_SUCCESS = "post-EMAIL-success";

export const PUT_EMAIL_REQUEST = "PUT_EMAIL_REQUEST";
export const PUT_EMAIL_FAILURE = "put-EMAIL-failure";
export const PUT_EMAIL_SUCCESS = "put-EMAIL-success";

export const DELETE_EMAIL_REQUEST = "DELETE_EMAIL_REQUEST";
export const DELETE_EMAIL_FAILURE = "delete-EMAIL-failure";
export const DELETE_EMAIL_SUCCESS = "delete-EMAIL-success";

export const CLEAN_EMAIL_STORE = "CLEAN_EMAIL_STORE";