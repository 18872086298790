import {
  GET_RESELLER_REQUEST,
  GET_RESELLER_SUCCESS,
  GET_RESELLER_FAILURE,

  CLEAN_RESELLER_STORE,
  
  DELETE_RESELLER_REQUEST,
  DELETE_RESELLER_SUCCESS,
  DELETE_RESELLER_FAILURE,

  POST_RESELLER_REQUEST,
  POST_RESELLER_FAILURE,
  POST_RESELLER_SUCCESS,

  PUT_RESELLER_REQUEST,
  PUT_RESELLER_FAILURE,
  PUT_RESELLER_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function reseller(state = initialState, action) {
  switch (action.type) {
    case GET_RESELLER_REQUEST:
    case DELETE_RESELLER_REQUEST:
    case POST_RESELLER_REQUEST:
    case PUT_RESELLER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_RESELLER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_RESELLER_SUCCESS:
    case POST_RESELLER_SUCCESS:
    case PUT_RESELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_RESELLER_FAILURE:
    case POST_RESELLER_FAILURE:
    case DELETE_RESELLER_FAILURE:
    case PUT_RESELLER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_RESELLER_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
