import { Pagination, Skeleton, Stack, Typography } from "@mui/material";
import { formatDate, formatDate2 } from "app/utils/appHelpers";
import React, { useContext } from "react";
import ChipStatus from "../ChipStatus";
import IndexListingRTKHeader from "./IndexListingRTKHeader";
import IndexListingProvider, {
  IndexListingContext,
} from "./IndexListingProvider";
import { Link, useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
const Label = ({ children }) => (
  <span className=" text-gray-400 text-[12px]">{children}</span>
);
const Value = ({ children }) => (
  <span className=" text-gray-800 text-md">{children}</span>
);

const ColumnBox = ({ children, cols }) => (
  <div className="grid" style={{ gridColumn: `span ${cols}` }}>
    {children}
  </div>
);

const IndexListingCols = ({ columns, item }) =>
  columns.map((colum, index) => {
    if (colum.key === "id") {
      return (
        <div
          key={index}
          className="w-12 h-12 self-center justify-self-center bg-primary rounded-full flex items-center justify-center"
        >
          <span className="text-white"> {item[colum.key]}</span>
        </div>
      );
    }
    if (colum.key?.includes(".")) {
      let keys = colum.key.split(".");
      let value = item;

      while (keys.length) {
        if (value[keys[0]] === undefined || value[keys[0]] === null) {
          value = "N/A";
          break;
        }
        value = value[keys[0]];
        keys.shift();
      }

      if (colum.special === "date") {
        value = formatDate(value);
      }
      if (colum.special === "status") {
        return (
          <ColumnBox cols={colum.cols} key={index}>
            <Label>{colum.label}</Label>

            <ChipStatus status={value} />
          </ColumnBox>
        );
      }

      return (
        <ColumnBox cols={colum.cols} key={index}>
          <Label>{colum.label}</Label>

          <Value> {value}</Value>
        </ColumnBox>
      );
    }

    let value = item[colum.key];

    if (colum.special === "date") {
      value = formatDate2(value);
    }

    return (
      <ColumnBox cols={colum.cols} key={index}>
        <Label>{colum.label}</Label>
        <Value> {value}</Value>
      </ColumnBox>
    );
  });

function IndexListing({ hook, columns, headerFilters, header, newRoute }) {
  const navigate = useNavigate();

  const { setSearchParams, searchParams } = useContext(IndexListingContext);

  const { data, isLoading, isFetching, refetch } = hook(searchParams);
  React.useEffect(() => {
    refetch();
  }, [searchParams]);
  const colNumber = columns.reduce((acc, curr) => acc + parseInt(curr.cols), 0);
  return (
    <>
      <IndexListingRTKHeader headerFilters={headerFilters} newRoute={newRoute}>
        {header}
      </IndexListingRTKHeader>
      <div className="grid grid-cols-1 gap-4">
        {isLoading || isFetching ? (
          <Stack id="stack-usuario-skeleton" spacing={1} marginTop={1}>
            <Skeleton
              variant="rectangular"
              className="w-full h-14 rounded-md"
            />
            <Skeleton
              variant="rectangular"
              className="w-full h-14 rounded-md"
            />
            <Skeleton
              variant="rectangular"
              className="w-full h-14 rounded-md"
            />
            <Skeleton
              variant="rectangular"
              className="w-full h-14 rounded-md"
            />
            <Skeleton
              variant="rectangular"
              className="w-full h-14 rounded-md"
            />
            <Skeleton
              variant="rectangular"
              className="w-full h-14 rounded-md"
            />
            <Skeleton
              variant="rectangular"
              className="w-full h-14 rounded-md"
            />
          </Stack>
        ) : (
          <>
            {data.data.length === 0 && (
              <Div
                className={`bg-white rounded-md shadow-sm w-full p-4 grid gap-4 hover:shadow-lg cursor-pointer`}
              >
                <Typography variant="h4" className="text-center">
                  Nenhum registro encontrado
                </Typography>
              </Div>
            )}
            {data.data.map((item, index) => (
              <Div
                className={`bg-white rounded-md shadow-sm w-full p-4 grid gap-4 hover:shadow-lg cursor-pointer`}
                onClick={() => navigate(`/tickets/responder/${item.id}`)}
                style={{
                  gridTemplateColumns: `repeat(${colNumber}, minmax(0, 1fr))`,
                }}
              >
                <IndexListingCols item={item} columns={columns} index={index} />
              </Div>
            ))}

            <Pagination
              page={data.meta.current_page}
              count={data.meta.last_page}
              onChange={(_, value) =>
                setSearchParams({ ...searchParams, page: value })
              }
              color="primary"
            />
          </>
        )}
      </div>
    </>
  );
}

export default IndexListing;
