//SOLUTIONS Types

export const GET_SOLUTIONS_REQUEST = "GET_SOLUTIONS_REQUEST";
export const GET_SOLUTIONS_FAILURE = "GET_SOLUTIONS_FAILURE";
export const GET_SOLUTIONS_SUCCESS = "GET_SOLUTIONS_SUCCESS";

export const GET_SINGLE_SOLUTIONS_REQUEST = 'get-single-SOLUTIONS-request'
export const GET_SINGLE_SOLUTIONS_FAILURE = 'get-single-SOLUTIONS-failure';
export const GET_SINGLE_SOLUTIONS_SUCCESS = 'get-single-SOLUTIONS-success';

export const POST_SOLUTIONS_REQUEST = "POST_SOLUTIONS_REQUEST";
export const POST_SOLUTIONS_FAILURE = "post-SOLUTIONS-failure";
export const POST_SOLUTIONS_SUCCESS = "post-SOLUTIONS-success";

export const PUT_SOLUTIONS_REQUEST = "PUT_SOLUTIONS_REQUEST";
export const PUT_SOLUTIONS_FAILURE = "put-SOLUTIONS-failure";
export const PUT_SOLUTIONS_SUCCESS = "put-SOLUTIONS-success";

export const DELETE_SOLUTIONS_REQUEST = "DELETE_SOLUTIONS_REQUEST";
export const DELETE_SOLUTIONS_FAILURE = "delete-SOLUTIONS-failure";
export const DELETE_SOLUTIONS_SUCCESS = "delete-SOLUTIONS-success";

export const CLEAN_SOLUTIONS_STORE = "CLEAN_SOLUTIONS_STORE";