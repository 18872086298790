import {
  POST_PHOTO_SUCCESS,
  POST_PHOTO_FAILURE,
  POST_PHOTO_REQUEST,

} from "./constants";
import api from "app/services/config";

export const uploadPhoto =
  (photo, id) => async (dispatch, getState) => {
    dispatch({ type: POST_PHOTO_REQUEST });

    try {

      const formData = new FormData();
      formData.append("file", photo);
      formData.append("user_id", id);

      const { auth } = getState();
      const response = await api.post(`/avatars`, formData
      , {
             headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: POST_PHOTO_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: POST_PHOTO_FAILURE,
        payload: error.message,
      });
    }
  };