import React, { useEffect, useState } from "react";
import ListItems from "./items";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Stack,
  Typography,
  Pagination,
  Autocomplete,
  TextField,
} from "@mui/material";
import JumboSearch from "@jumbo/components/JumboSearch";
import Div from "@jumbo/shared/Div";
import SortIcon from "@mui/icons-material/Sort";
import { history } from "app/redux/store";
import { appTheme } from "app/themes/app/theme";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import SimpleModalEditor from "./SimpleModalEditor";
import SkeletonFamily from "./Skeleton";
import {
  cleanSearch,
  setCategory,
  setOrder,
  setOrderBy,
  setPage,
  setProductFamilyId,
  setStatus,
  setCity,
  setUf,
} from "app/redux/modules/Search/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { CLEAN_UNITS_STORE } from "app/redux/modules/Customers/Units/constants";
import { asyncSearchActions } from "app/redux/reducers";
import { CLEAN_STATES_STORE } from "app/redux/modules/States/constants";
import { CLEAN_CITIES_STORE } from "app/redux/modules/Cities/constants";

/**
 * Um componente genérico para ajudar a criar Views rapidamente aqui da focalweb. Para funcionar corretamente, é obrigatório passar todos os props e na ordem correta, para que o componente possa renderizar corretamente a página.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.data - O retorno da API com um array em JSON
 * @param {Array<string>} [props.labels] - Uma lista de Labels que serão aplicados aos dados.
 * @returns {JSX.Element} - The component's markup.
 */

const DynamicViewGenerator = ({ labels, type, route, pageConfig }) => {
  const navigate = useNavigate();

  const [paramsOrder, setParams] = useState({ orderBy: "id" });
  // const [order, setOrder] = useState('desc');
  // const [status, setStatus] = useState("1");
  const [totalPages, setTotalPages] = useState(0);
  const [continueFetching, setContinueFetching] = useState(false);
  const [searchText, setSearchText] = useState(null);
  // const reorder = Object.keys(labels[0]);
  const [openSimpleEditor, setOpenSimpleEditor] = React.useState(false);
  const dispatch = useDispatch();
  const { search, cities, states } = useSelector((state) => state);
  const [selectedState, setSelectedState] = useState(null);
  const location = useLocation();

  const reorder = pageConfig.reorder
    ? pageConfig.reorder
    : [
        {
          id: "id",
          name: "Código",
        },
        {
          id: "name",
          name: "Nome",
        },
        {
          id: "status",
          name: "Status",
        },
        {
          id: "created_at",
          name: "Data de criação",
        },
        {
          id: "updated_at",
          name: "Data de atualização",
        },
      ];
  useEffect(() => {
    dispatch(asyncSearchActions.getStates());

    dispatch(asyncSearchActions.getCities());

    return () => {
      dispatch(cleanSearch());
      dispatch({ type: CLEAN_UNITS_STORE });
      dispatch({ type: CLEAN_STATES_STORE });
      dispatch({ type: CLEAN_CITIES_STORE });
    };
  }, []);

  useEffect(() => {
    if (selectedState) {
      dispatch(asyncSearchActions.getCities(selectedState));
    }
  }, [selectedState]);

  const handleOpenSimpleEditor = () => {
    setOpenSimpleEditor(true);
  };

  const handleCloseSimpleEditor = () => {
    setOpenSimpleEditor(false);
  };

  function toggleOrder() {
    const throgle =
      search.data.order && search.data.order == "asc" ? "desc" : "asc";
    dispatch(setOrder(throgle));
  }

  const debounceSearch = debounce(
    (order, orderBy, status, page, searchText) => {
      dispatch(
        pageConfig.actions?.get(order, orderBy, status, page, searchText)
      );
    },
    300
  );

  useEffect(() => {
    debounceSearch(
      search.data.order,
      search.data.orderBy,
      search.data.status,
      search.data.page,
      searchText
    );
  }, [
    search.data.order,
    search.data.orderBy,
    search.data.status,
    searchText,
    search.data.page,
    search.data.category,
    search.data.city,
    search.data.uf,
    search.data.product_family_id,
  ]);

  const standardStatus = [
    {
      id: "1",
      name: "Ativo",
    },
    {
      id: "0",
      name: "Desativado",
    },
  ];

  return (
    <React.Fragment>
      {pageConfig.createDataType && (
        <SimpleModalEditor
          pageConfig={pageConfig}
          open={openSimpleEditor}
          handleClose={() => handleCloseSimpleEditor()}
        />
      )}
      <Div>
        <Div className="flex min-[1850px]:flex-row flex-col py-4 justify-between">
          <Typography className="flex items-center" variant={"h2"}>
            {pageConfig.title}
          </Typography>
          <Div className="flex flex-wrap items-center">
            {pageConfig.statesCitySearch && (
              <>
                <Div className="filter-item sm:flex hidden">
                  <FormControl
                    className="m-2 2xl:min-w-[50px] min-w-[50px]"
                    size="small"
                  >
                    <Autocomplete
                      size="small"
                      filterSelectedOptions
                      options={states.data}
                      fullWidth
                      InputProps={{
                        autoComplete: "off",
                      }}
                      onChange={(event, value) => {
                        dispatch(setUf(value?.letter));
                        setSelectedState(value?.id);
                        setUf(value?.letter);
                        setCity(null);
                      }}
                      getOptionLabel={(option) => option.letter}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id={"state"}
                          name={"state"}
                          label={"UF"}
                        />
                      )}
                    />
                  </FormControl>
                </Div>

                <Div className="filter-item sm:flex hidden">
                  <FormControl
                    className="m-2 2xl:min-w-[150px] min-w-[130px]"
                    size="small"
                  >
                    <Autocomplete
                      size="small"
                      filterSelectedOptions
                      options={cities.data}
                      disabled={!selectedState}
                      fullWidth
                      InputProps={{
                        autoComplete: "off",
                      }}
                      onChange={(event, value) => {
                        setCity(value?.title);
                        dispatch(setCity(value?.title));
                      }}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id={"city"}
                          name={"city"}
                          label={"Cidade"}
                        />
                      )}
                    />
                  </FormControl>
                </Div>
              </>
            )}
            {pageConfig.familySearch && (
              <Div className="filter-item sm:flex hidden">
                <FormControl
                  className="m-2 2xl:min-w-[150px] min-w-[130px]"
                  size="small"
                >
                  <Autocomplete
                    size="small"
                    filterSelectedOptions
                    options={pageConfig.familyData}
                    disabled={!pageConfig.familyData}
                    fullWidth
                    InputProps={{
                      autoComplete: "off",
                    }}
                    onChange={(event, value) => {
                      dispatch(setProductFamilyId(value?.id));
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id={"family"}
                        name={"family"}
                        label={"Familia"}
                      />
                    )}
                  />
                </FormControl>
              </Div>
            )}
            {pageConfig.category && (
              <Div className="filter-item sm:flex hidden">
                <FormControl
                  className="m-2 2xl:min-w-[200px] min-w-[142px]"
                  sx={{ minWidth: 200 }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-status">
                    Categoria
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-status"
                    id="demo-select-small-status"
                    value={search.data.category}
                    label="Status"
                    onChange={(event) => {
                      dispatch(setCategory(event.target.value));
                    }}
                  >
                    {/* <MenuItem value="">
                                        <em>Selecione...</em>
                                    </MenuItem> */}
                    {pageConfig.category.map((key, index) => (
                      <MenuItem key={index} value={key.id}>
                        {key.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Div>
            )}

            {!pageConfig.hiddenSort && (
              <>
                <Div className="filter-item sm:flex hidden">
                  <FormControl
                    className="m-2 2xl:min-w-[200px] min-w-[142px]"
                    sx={{ minWidth: 200 }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-status">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-status"
                      id="demo-select-small-status"
                      value={search.data.status}
                      label="Status"
                      onChange={(event) => {
                        dispatch(setStatus(event.target.value));
                        if (pageConfig.status) {
                          // if(location.pathname.includes('/cliente')){
                          //     navigate(`/tickets/cliente/${search.data.user_id}/${pageConfig.status.find((item) => item.id === event.target.value).name.toLocaleLowerCase()}`)
                          // }
                          // if(location.pathname.includes('/empresa')){
                          //     navigate(`/tickets/empresa/${search.data.company_id}/${pageConfig.status.find((item) => item.id === event.target.value).name.toLocaleLowerCase()}`)
                          // }
                          if (
                            !location.pathname.includes("/cliente") &&
                            !location.pathname.includes("/empresa")
                          ) {
                            navigate(
                              `/tickets/${pageConfig.status
                                .find((item) => item.id === event.target.value)
                                .name.toLocaleLowerCase()
                                .replace(" ", "-")}`
                            );
                          }
                        }
                      }}
                    >
                      {pageConfig.status
                        ? pageConfig.status.map((key, index) => (
                            <MenuItem
                              name={key.name}
                              key={index}
                              value={key.id}
                            >
                              {key.name}
                            </MenuItem>
                          ))
                        : standardStatus.map((key, index) => (
                            <MenuItem key={index} value={key.id}>
                              {key.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Div>

                <Div className="filter-item sm:flex hidden mr-2">
                  <FormControl
                    className="m-2 2xl:min-w-[200px] min-w-[142px]"
                    sx={{ minWidth: 250 }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-order">
                      Ordenar por
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-order"
                      id="demo-select-small-order"
                      value={search.data.orderBy}
                      label="Order"
                      onChange={(event) => {
                        dispatch(setOrderBy(event.target.value));
                      }}
                    >
                      {/* <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem> */}
                      {reorder &&
                        reorder.map((key, index) => (
                          <MenuItem key={index} value={key.id}>
                            {key.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Button
                    sx={{
                      m: 1,
                      borderColor: appTheme.palette.red100,
                      color: appTheme.palette.red100,
                      "&:hover": {
                        borderColor: appTheme.palette.red200,
                      },
                    }}
                    variant="outlined"
                    onClick={toggleOrder}
                  >
                    <SortIcon
                      sx={{
                        transform: `rotateX(${
                          search.data.order == "desc" ? 0 : 180
                        }deg)`,
                      }}
                    />
                  </Button>
                </Div>
              </>
            )}

            {!pageConfig.hiddenSearch && (
              <FormControl className="min-[1500px]:w-[212px] w-[130px]">
                <JumboSearch
                  onChange={(e) => {
                    setSearchText(e);
                    setPage(1);
                  }}
                  placeholder={pageConfig.searchText}
                  sx={{
                    display: { md: "block" },
                    flex: 1,
                    bgcolor: "#FFF",
                  }}
                />
              </FormControl>
            )}

            {!pageConfig.hiddenSearch && (
              <Button
                variant="contained"
                color="success"
                data-cy="openModal"
                sx={{
                  ml: 3,
                }}
                onClick={() =>
                  pageConfig.newPage
                    ? navigate(pageConfig.newPage)
                    : handleOpenSimpleEditor()
                }
              >
                {pageConfig.newText}
              </Button>
            )}
          </Div>
        </Div>
      </Div>
      {pageConfig.loading ? (
        <SkeletonFamily />
      ) : (
        <>
          {pageConfig.data.data && pageConfig.data.data.length > 0 ? (
            <>
              {pageConfig.data.data.map((item, index) => (
                <ListItems
                  key={index}
                  item={item}
                  labels={labels}
                  type={type}
                  route={route}
                  actions={pageConfig.actions}
                  pageConfig={pageConfig}
                />
              ))}
              <Pagination
                count={pageConfig?.data?.meta?.last_page}
                page={search?.data?.page}
                onChange={(event, value) => dispatch(setPage(value))}
                shape="rounded"
                variant="outlined"
                color="secondary"
              />
            </>
          ) : (
            <Typography variant={"h4"} mb={3}>
              Nada para listar
            </Typography>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default DynamicViewGenerator;
