import React from "react";
import { Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import PieChartReducersWithLeakGraph from "./PieChartReducersWithLeakGraph";

const PieChartReducersWithLeak = () => {
  return (
    <JumboCardQuick
      title={
        <Typography variant={"h6"} mb={0}>
          Redutores com vazamento
        </Typography>
      }
      noWrapper
      sx={{ textAlign: "center" }}
    >
      <PieChartReducersWithLeakGraph />
    </JumboCardQuick>
  );
};

export default PieChartReducersWithLeak;
