//PRODUCT_MODEL Types

export const GET_FILTER_PRODUCT_MODEL_REQUEST = "GET_FILTER_PRODUCT_MODEL_REQUEST";
export const GET_FILTER_PRODUCT_MODEL_FAILURE = "GET_FILTER_PRODUCT_MODEL_FAILURE";
export const GET_FILTER_PRODUCT_MODEL_SUCCESS = "GET_FILTER_PRODUCT_MODEL_SUCCESS";

export const GET_FILTER_SINGLE_PRODUCT_MODEL_REQUEST = 'get-single-PRODUCT_MODEL-request'
export const GET_FILTER_SINGLE_PRODUCT_MODEL_FAILURE = 'get-single-PRODUCT_MODEL-failure';
export const GET_FILTER_SINGLE_PRODUCT_MODEL_SUCCESS = 'get-single-PRODUCT_MODEL-success';

export const GET_USER_TO_EDIT_REQUEST = 'get-user-to-edit-request';
export const GET_USER_TO_EDIT_FAILURE = 'get-user-to-edit-failure';
export const GET_USER_TO_EDIT_SUCCESS = 'get-user-to-edit-success';

export const CLEAN_FILTER_PRODUCT_MODEL_STORE = "CLEAN_PRODUCT_MODEL_STORE";