import {
  asyncProductActions,
  asyncProductFamiliesActions,
} from "app/redux/reducers";
import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack, Typography } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { CLEAN_PRODUCT_STORE } from "app/redux/modules/Products/product/constants";
import { CLEAN_PRODUCT_FAMILIES_STORE } from "app/redux/modules/Products/families/constants";
import * as Yup from "yup";

const Product = () => {
  const dispatch = useDispatch();
  const { product, productFamilies, auth } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);

  const startPage = async () => {
     dispatch(asyncProductActions.getProduct("asc", "id"));
     dispatch(asyncProductFamiliesActions.getProductFamilies("asc", "id",1,1,"",true));  
  }

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_PRODUCT_STORE });
      dispatch({ type: CLEAN_PRODUCT_FAMILIES_STORE });
    }
  }, [dispatch]);
 
  useEffect(() => {
    if (productFamilies.data) {
      setNewSelectOptions(
        productFamilies.data.data && productFamilies.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [productFamilies]);


  const dataLabels = [
    {
      id: "Id",
      name: "Nome",
      created_at: "Data de Criação",
      updated_at: "Última atualização",
      product_family: "Familia de Produto",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: "id",
      name: "string",
      description: "string",
      status: "chip",
      created_at: "date",
      updated_at: "date",
      deleted_at: "date",
      product_family: "nested_name",
    },
  ];

  const formFieldsType = [
    {
      type: "text",
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "text",
      value: "description",
      label: "Descrição",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "select",
      value: "product_family_id",
      label: "Família de Produto",
      options: newSelectOptions,
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "status",
      value: "status",
      label: "Status",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
  ];

  const pageConfig = {
    title: "Produtos",
    newText: "Novo Produto",
    searchText: "Pesquisar",
    data: product.data,
    loading: product.loading,
    category: newSelectOptions,
    actions: {
      delete: asyncProductActions.deleteProduct,
      edit: asyncProductActions.editProduct,
      get: asyncProductActions.getProduct,
      search: asyncProductActions.getProduct,
      create: asyncProductActions.createProduct,
      clean: { type: CLEAN_PRODUCT_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default Product;
