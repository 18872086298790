import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { useLazyFetchUnitFieldQuery } from "app/redux/features/fields-slices";
import React, { useEffect } from "react";

function UnitField({ formikRef, ...props }) {
  const formik = formikRef;

  const [trigger, { data, isLoading, isFetching }] =
    useLazyFetchUnitFieldQuery();

  useEffect(() => {
    if (!formik.values.company_id) return;
    trigger(formik.values.company_id);
  }, [formik.values.company_id]);

  return (
    <FormControl fullWidth className="bg-white">
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data || []}
        loading={isLoading || isFetching}
        fullWidth
        disabled={formik.values.company_id === undefined}
        onChange={(_, value) => {
          formik.setFieldValue(
            "unit_id",
            value !== null ? value.id : undefined
          );
          formik.setFieldValue("client_id", undefined);
        }}
        value={
          data?.find((option) => option.id === formik.values.unit_id) || null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.value}
          </li>
        )}
        getOptionLabel={(option) => option.value || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            className="w-full text-sm text-gray-500"
            size="medium"
            fullWidth
            id={"unit_id"}
            name={"unit_id"}
            label={"Unidade"}
            InputLabelProps={{
              className: "text-sm pt-[3px]",
            }}
            error={formik.touched.unit_id && Boolean(formik.errors.unit_id)}
            helperText={formik.touched.unit_id && formik.errors.unit_id}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default UnitField;
