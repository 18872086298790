import React from 'react';
import BusinessIcon from "@mui/icons-material/Business";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FolderItem from "./FolderItem";
import {useParams} from "react-router-dom";
import StyledMenu from 'app/shared/StyledMenu';
import useContactsApp from '../../hooks/useContactsApp';
/* import StyledMenu from "../../../../../shared/StyledMenu"; */

const folders = [
    {
        icon: <BusinessIcon fontSize={"small"} />,
        label: "Unidades",
        slug: "all",
        path: "unit"
    },
    {
        icon: <SettingsIcon fontSize={"small"} />,
        label: "Tickets",
        slug: "starred",
        path: "tickets"
    },
    {
        icon: <AccessTimeIcon fontSize={"small"} />,
        label: "Ordens de Serviço",
        slug: "frequent",
        path: "serviceOrders"
    }
];

const FoldersList = () => {
    const params = useParams();
    const {setField, field} = useContactsApp();

    return (
        <StyledMenu sx={{mb: 2}}>
            {
                folders.map(folder => (
                    <FolderItem
                        key={folder.slug}
                        path={folder.path}
                        label={folder.label}
                        icon={folder.icon}
                        selected={folder.path == field}
                        onClick={() => setField(folder.path)}
                    />
                ))
            }
        </StyledMenu>
    );
};

export default React.memo(FoldersList);
