import React from 'react';

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {useTheme} from "@mui/material";

import { useDispatch, useSelector } from 'react-redux';
import { CLEAN_ALERT_STORE } from 'app/redux/modules/Alerts/constants';



const SystemMessage = () => {
    const { alerts } = useSelector((state) => state);
    const dispatch = useDispatch();
    const Swal = useSwalWrapper();
    const theme = useTheme();
    const sweetAlerts = variant => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: alerts?.alert?.message,
            background: theme.palette.background.paper,
        });
    };

    React.useEffect(() => {
        if (alerts?.alert?.message) {
          sweetAlerts(alerts?.alert?.variant);
          dispatch({ type: CLEAN_ALERT_STORE });

        }
    
      }, [alerts]);

    
    return (<></>)
  
};

export default SystemMessage;