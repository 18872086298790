import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import { useFetchResellersQuery } from "../../features/serviceOrderFields-slice";
import { FieldSkeleton } from "app/components/CustomInputs/Skeleton";

function ResellersSoField({ formik, handlePreviewData, ...props }) {
  const { data, isFetching, isLoading } = useFetchResellersQuery();

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        data-testid={"input-resale"}
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          formik.setFieldValue("resale", value !== null ? value.id : undefined);

          formik.setFieldValue("responsibles", []);
          handlePreviewData("responsibles", "");
        }}
        value={
          data?.data?.find((option) => option.id === formik.values.resale) ||
          null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"resale"}
            name={"resale"}
            label={"Revenda"}
            error={formik.touched.resale && Boolean(formik.errors.resale)}
            helperText={formik.touched.resale && formik.errors.resale}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default ResellersSoField;
