import {
  GET_TICKETS_CC_FAILURE,
  GET_TICKETS_CC_REQUEST, GET_TICKETS_CC_SUCCESS,

} from "./constants";
import api from "app/services/config";

export const getTicketsCcUsers =
  () => async (dispatch, getState) => {
    dispatch({ type: GET_TICKETS_CC_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.get(`/users`, {
        params: {
          orderBy: `name, desc`,
          status: 1,
          page: 1,
          s: "",
          noPaginate: true,
          role_id: '1,2,4'
  

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_TICKETS_CC_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TICKETS_CC_FAILURE,
        payload: error.message,
      });
    }
  };

  