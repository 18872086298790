import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLazyFetchUnitsQuery } from "../../features/serviceOrderFields-slice";
import { FieldSkeleton } from "app/components/CustomInputs/Skeleton";

function UnitSoField({
  formik,
  handlePreviewData,
  setPropertyLocation,
  ...props
}) {
  const [trigger, { data, isLoading, isFetching }] = useLazyFetchUnitsQuery();

  useEffect(() => {
    if (!formik.values.company) return;
    trigger(formik.values.company);
  }, [formik.values.company]);

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        disabled={formik.values.company === undefined}
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          formik.setFieldValue(
            "property",
            value !== null ? value.id : undefined
          );
          handlePreviewData(
            "property",
            value !== null ? value.address : undefined
          );
          setPropertyLocation(
            value !== null
              ? {
                  lat: Number(value.latitude),
                  lng: Number(value.longitude),
                }
              : null
          );

          formik.setFieldValue("client", undefined);
        }}
        value={
          data?.data?.find((option) => option.id === formik.values.property) ||
          null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.address}
          </li>
        )}
        getOptionLabel={(option) => option.address || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"property"}
            name={"property"}
            label={"Unidade"}
            error={formik.touched.property && Boolean(formik.errors.property)}
            helperText={formik.touched.property && formik.errors.property}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default UnitSoField;
