import { Pagination, Typography } from "@mui/material";
import React, { useContext } from "react";
import { PivoContext } from "../PivoProvider";

import Loading from "app/components/Loading";

import { useParams } from "react-router-dom";
import { useFetchReducersQuery } from "../features/redutores/reducer-slice";
import ReducersListHeader from "../Reducers/Components/ReducersListHeader";

function HistoryList() {
  // const data = PivoMockData;
  const { id } = useParams();
  const { reducerSearch, setReducerPage } = useContext(PivoContext);
  const search = {
    pivot_id: id,
    s: reducerSearch.s,
    page: reducerSearch.page,
    order: reducerSearch.order,
    orderBy: reducerSearch.order_by,
  };

  const { data, isLoading } = useFetchReducersQuery(search);

  if (isLoading) {
    return <Loading />;
  }

  if (data?.data?.history?.length === 0) {
    return (
      <React.Fragment>
        <ReducersListHeader />
        <Typography variant={"h4"} mb={3}>
          Nenhum histórico para listar
        </Typography>{" "}
      </React.Fragment>
    );
  }

  if (data?.data?.history?.length !== 0) {
    return (
      <React.Fragment>
        <ReducersListHeader />
        <Typography variant={"h4"} mb={3}>
          Tem historico{" "}
        </Typography>{" "}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {/* <ReducerModal />
      <ReducersListHeader />
      {data?.data.map((item) => (
        <ReducerListItens
          key={item.id}
          data-testid={`reducer-item-${item.id}`}
          item={item}
        />
      ))}
      <Pagination
        count={data.meta.last_page}
        page={reducerSearch.page}
        onChange={(_, value) => setReducerPage(value)}
        shape="rounded"
        variant="outlined"
        color="secondary"
      /> */}
    </React.Fragment>
  );
}

export default HistoryList;
