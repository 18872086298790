//PROBLEM_TYPES Types

export const GET_PROBLEM_TYPES_REQUEST = "GET_PROBLEM_TYPES_REQUEST";
export const GET_PROBLEM_TYPES_FAILURE = "GET_PROBLEM_TYPES_FAILURE";
export const GET_PROBLEM_TYPES_SUCCESS = "GET_PROBLEM_TYPES_SUCCESS";

export const GET_SINGLE_PROBLEM_TYPES_REQUEST = 'get-single-PROBLEM_TYPES-request'
export const GET_SINGLE_PROBLEM_TYPES_FAILURE = 'get-single-PROBLEM_TYPES-failure';
export const GET_SINGLE_PROBLEM_TYPES_SUCCESS = 'get-single-PROBLEM_TYPES-success';

export const POST_PROBLEM_TYPES_REQUEST = "POST_PROBLEM_TYPES_REQUEST";
export const POST_PROBLEM_TYPES_FAILURE = "post-PROBLEM_TYPES-failure";
export const POST_PROBLEM_TYPES_SUCCESS = "post-PROBLEM_TYPES-success";

export const PUT_PROBLEM_TYPES_REQUEST = "PUT_PROBLEM_TYPES_REQUEST";
export const PUT_PROBLEM_TYPES_FAILURE = "put-PROBLEM_TYPES-failure";
export const PUT_PROBLEM_TYPES_SUCCESS = "put-PROBLEM_TYPES-success";

export const DELETE_PROBLEM_TYPES_REQUEST = "DELETE_PROBLEM_TYPES_REQUEST";
export const DELETE_PROBLEM_TYPES_FAILURE = "delete-PROBLEM_TYPES-failure";
export const DELETE_PROBLEM_TYPES_SUCCESS = "delete-PROBLEM_TYPES-success";

export const CLEAN_PROBLEM_TYPES_STORE = "CLEAN_PROBLEM_TYPES_STORE";