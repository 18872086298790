import styled from "@emotion/styled";


export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UserImage = styled.img``;

export const UserInitials = styled.div``;

export const UserInitialsText = styled.text``;

export const FormBody = styled.div``;