//SAC Types

export const GET_SAC_REQUEST = "GET_SAC_REQUEST";
export const GET_SAC_FAILURE = "GET_SAC_FAILURE";
export const GET_SAC_SUCCESS = "GET_SAC_SUCCESS";

export const GET_SINGLE_SAC_REQUEST = 'get-single-SAC-request'
export const GET_SINGLE_SAC_FAILURE = 'get-single-SAC-failure';
export const GET_SINGLE_SAC_SUCCESS = 'get-single-SAC-success';

export const POST_SAC_REQUEST = "POST_SAC_REQUEST";
export const POST_SAC_FAILURE = "post-SAC-failure";
export const POST_SAC_SUCCESS = "post-SAC-success";

export const PUT_SAC_REQUEST = "PUT_SAC_REQUEST";
export const PUT_SAC_FAILURE = "put-SAC-failure";
export const PUT_SAC_SUCCESS = "put-SAC-success";

export const DELETE_SAC_REQUEST = "DELETE_SAC_REQUEST";
export const DELETE_SAC_FAILURE = "delete-SAC-failure";
export const DELETE_SAC_SUCCESS = "delete-SAC-success";

export const CLEAN_SAC_STORE = "CLEAN_SAC_STORE";