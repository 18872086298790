import { Skeleton } from "@mui/material";
import React from "react";

function UserDetailsSkeleton() {
  return (
    <>
      <Skeleton
        variant="rectangular"
        style={{ height: 200, marginVertical: 10, borderRadius: 10 }}
      />
      <Skeleton
        variant="rectangular"
        style={{ height: 500, marginTop: 10, borderRadius: 10 }}
      />
    </>
  );
}

export default UserDetailsSkeleton;
