import React from "react";
import FileManagerListComponent from "../components/FileManagerListComponent";
import FileManagerHeader from "../components/FileManagerHeader";

function FileManagerList() {
  return (
    <div>
      <FileManagerHeader />
      <FileManagerListComponent />
    </div>
  );
}

export default FileManagerList;
