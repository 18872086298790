import {
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,

  CLEAN_COMPANIES_STORE,
  
  DELETE_COMPANIES_REQUEST,
  DELETE_COMPANIES_SUCCESS,
  DELETE_COMPANIES_FAILURE,

  POST_COMPANIES_REQUEST,
  POST_COMPANIES_FAILURE,
  POST_COMPANIES_SUCCESS,

  PUT_COMPANIES_REQUEST,
  PUT_COMPANIES_FAILURE,
  PUT_COMPANIES_SUCCESS,

  GET_COMPANIES_TYPES_REQUEST,
  GET_COMPANIES_TYPES_SUCCESS,
  GET_COMPANIES_TYPES_FAILURE,
  CLEAN_COMPANIES_TYPES_STORE,
  GET_RESALES_REQUEST,
  GET_RESALES_SUCCESS,
  GET_RESALES_FAILURE,
} from "./constants";

const initialState = {
  data: [],
  resales: [],
  loading: false,
  error: null,
};


export function companiesTypes(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_COMPANIES_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GET_COMPANIES_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_COMPANIES_TYPES_STORE:
      return {
          ...state,
          data: [],
          loading: false,
          error: null,
      };
    default:
      return state;
  }
}

export function companies(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES_REQUEST:
    case GET_RESALES_REQUEST:
    case DELETE_COMPANIES_REQUEST:
    case POST_COMPANIES_REQUEST:
    case PUT_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GET_RESALES_SUCCESS:
      return {
        ...state,
        resales: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_COMPANIES_SUCCESS:
    case POST_COMPANIES_SUCCESS:
    case PUT_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_COMPANIES_FAILURE:
    case GET_RESALES_FAILURE:
    case POST_COMPANIES_FAILURE:
    case DELETE_COMPANIES_FAILURE:
    case PUT_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_COMPANIES_STORE:
      return {
        ...state,
        data: [],
        resales: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
