import React, { useEffect } from 'react';
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import ContactsAppSidebar from "./components/ContactsAppSidebar";
import ContactsList from "./components/ContactsList";
import ContactsAppProvider from "./ContactsAppProvider";
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
/* import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader"; */
import {useJumboTheme} from "@jumbo/hooks";
import {Stack, useMediaQuery, Skeleton, Avatar, Typography, Card, IconButton, Icon} from "@mui/material";
import ContactFab from "./components/ContactFab";
import FolderDropdown from "./components/FolderDropdown";
import LabelDropdown from "./components/LabelDropdown";
import useContactsApp from './hooks/useContactsApp';
import Div from '@jumbo/shared/Div';
import ReactLoading from 'react-loading';
import { appTheme } from 'app/themes/app/theme';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import api from "app/services/config";
import moment from 'moment';
import SimpleModalEditor from 'app/components/DynamicViewGenerator/SimpleModalEditor';
import { MASKED_INPUT, PHOTO_UPLOAD_INPUT, STATUS_INPUT, TEXT_INPUT } from 'app/components/DynamicViewGenerator/constants';
import * as Yup from "yup";

const ContactsApp = () => {
    const {theme} = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const {company, loadingCompany} = useContactsApp();

    const { auth } = useSelector(state => state);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);

    const formFieldsType = [
        {
          type: PHOTO_UPLOAD_INPUT,
          value: "logo",
          label: "Logo",
          required: false,
          action: "",
        },
        {
          type: TEXT_INPUT,
          value: "name",
          label: "Empresa",
          required: true,
          action: "",
          validation: Yup.string().required("Obrigatório")
        },  
        {
          type: TEXT_INPUT,
          value: "description",
          label: "Descrição",
          required: true,
          action: "",
          validation: Yup.string().required("Obrigatório")
        },
        {
          type: MASKED_INPUT,
          value: "cnpj",
          label: "CNPJ",
          required: true,
          mask: "99.999.999/9999-99",
          action: "",
          validation: Yup.string().transform(value => value.replace(/[^\d]/g, '')).min(11, "Cpf inválido").required("Obrigatório")
        },
        {
          type: "select",
          value: "uf",
          label: "UF",
          options: [],
          required: true,
          action: "",
          validation: Yup.string().required("Obrigatório")
        },
        {
            type: "select",
            value: "city",
            label: "Cidade",
            options: [],
            required: true,
            action: "",
            validation: Yup.string().required("Obrigatório")
        },
        {
            type: "select",
            value: "product_family_id",
            label: "Família",
            options: [],
            required: true,
            action: "",
            validation: Yup.string().required("Obrigatório")
        },
        {
            type: "select",
            value: "resale_id",
            label: "Revenda",
            options: [],
            required: true,
            action: "",
            validation: Yup.string().required("Obrigatório")
          },
        {
          type: STATUS_INPUT,
          value: "status",
          label: "Status",
          required: true,
          action: "",
          validation: Yup.string().required("Obrigatório")
    
        },
      ];

    const pageConfig = {
        title: "Empresa",
        actions: {
          edit: console.log,
        },
        createDataType: formFieldsType,
      };

    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    async function uploadMedia() {
        setLoading(true);
        const formData = new FormData();

        formData.append("file", image);

        try {
            const { data } = await api.post(
            `companies/logo/${company.id}`,
            formData,
            { headers: { Authorization: `Bearer ${auth.token}`, "Content-Type": "multipart/form-data", } }
            );

            console.log("UPLOAD RESULT > > ", data)
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setLoading(false);
    }

    useEffect(() => {
        if(image) {
            uploadMedia();
        }
    }, [image]);

    const dataLabels = [
        {
            label: "Código",
            field: "id",
            type: "id"
        },
        {
            label: "Empresa",
            field: "name",
            type: "text"
        },
        {
            label: "Tipo de empresa",
            field: "company_type.name",
            type: "text"
        },
        {
            label: "Família",
            field: "product_family.name",
            type: "text"
        },
        {
            label: "Cidade",
            field: "city.name",
            type: "text"
        },
        {
            label: "UF",
            field: "uf.name",
            type: "text"
        },
        {
            label: "Status",
            field: "status",
            type: "boolean"
        },
    ]

    function getFieldValue(item) {
        const fieldArray = item.field.split(".");

        let value = company;

        for (let i = 0; i < fieldArray.length; i++) {
            const f = fieldArray[i];
            
            if(value[f]) {
                value = value[f];
            } else {
                value = "N/A";
                break;
            }
        }

        switch (item.type) {
            case "date":
                return moment(value).format("DD/MM/YYYY");
            case "boolean":
                return value ? "Ativo" : "Inativo";
            default:
                return value;
        }
    }

    return (
        <>
            {
                loadingCompany ? (
                    <Stack>
                        <Skeleton variant="text" style={{ borderRadius: 10, height: 50, width: 120 }} />
                        <Skeleton variant="text" style={{ borderRadius: 10, height: 20, width: 100 }} />

                        <Stack direction="row" mt={5}>
                            <Skeleton variant="rectangular" style={{ borderRadius: 10, height: "600px", width: "20%", marginRight: 10 }} />
                            <Skeleton variant="rectangular" style={{ borderRadius: 10, height: "600px", width: "80%" }} />
                        </Stack>
                    </Stack>
                ) : (
                    <>
                        <JumboContentLayout
                            sidebar={<ContactsAppSidebar />}
                            header={
                                <Card
                                    sx={{
                                        p: 2,
                                        mb: 3,
                                    }}
                                >
                                    <Div
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <input
                                            type="file"
                                            accept='image/*'
                                            id="image_logo_input"
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                                const file = e.target.files[0];

                                                setImage(Object.assign(file, {
                                                    preview: URL.createObjectURL(file),
                                                }),);
                                            }}
                                        />
                                        
                                        {
                                            dataLabels.map(fi => (
                                                <>
                                                    {
                                                        fi.type == "id"
                                                            ? (
                                                                <Div
                                                                    sx={{
                                                                        bgcolor: appTheme.palette.red100,
                                                                        width: 45,
                                                                        height: 45,
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderRadius: "50%",
                                                                        mr: 3
                                                                    }}
                                                                >
                                                                    <Typography variant={"h6"} color={appTheme.palette.neutral100} mb={0}>
                                                                        {getFieldValue(fi)}
                                                                    </Typography>
                                                                </Div>
                                                            )
                                                            : (
                                                                <Div sx={{flex: '1 1 auto'}}>
                                                                    <Typography variant={"h4"} mb={.35}>{fi.label}</Typography>
                                                                    <Typography variant={"body1"} color={"text.secondary"}>{getFieldValue(fi)}</Typography>
                                                                </Div>
                                                            )
                                                    }
                                                </>
                                            ))
                                        }

                                        {/* <IconButton onClick={() => setEdit(true)}>
                                            <Icon color={"warning"}>edit</Icon>
                                        </IconButton> */}
                                    </Div>
                                </Card>
                            }
                            layoutOptions={layoutOptions}
                        >
                            {
                                lg && (
                                    <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                                        <FolderDropdown/>
                                        <LabelDropdown/>
                                        <ContactFab/>
                                    </Stack>
                                )
                            }
                            <ContactsList />
                        </JumboContentLayout>
                    </>
                )
            }
        </>
    );
};
export default ContactsApp;
