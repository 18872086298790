import {
  CLEAN_USERS_STORE,
  CREATE_USERS_FAILURE,
  CREATE_USERS_REQUEST,
  CREATE_USERS_SUCCESS,
  DELETE_USERS_FAILURE,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  PUT_USERS_FAILURE,
  PUT_USERS_REQUEST,
  PUT_USERS_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function customers(state = initialState, action) {
  switch (action.type) {
    case DELETE_USERS_REQUEST:
    case PUT_USERS_REQUEST:
    case CREATE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_USERS_SUCCESS:
    case PUT_USERS_SUCCESS:
    case CREATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETE_USERS_FAILURE:
    case PUT_USERS_FAILURE:
    case CREATE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_USERS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
