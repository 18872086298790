import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { useFetchAttendantFieldQuery } from "app/redux/features/fields-slices";
import React from "react";

function AttendantField({ formikRef, ...props }) {
  const formik = formikRef;

  const { data, isLoading, isFetching } = useFetchAttendantFieldQuery();

  return (
    <FormControl fullWidth className="bg-white">
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data || []}
        loading={isLoading || isFetching}
        fullWidth
        disabled={data === undefined}
        onChange={(_, value) => {
          formik.setFieldValue(
            "attendant_id",
            value !== null ? value.id : undefined
          );
        }}
        value={
          data?.find((option) => option.id === formik.values.attendant_id) ||
          null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.value}
          </li>
        )}
        getOptionLabel={(option) => option.value || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            className="w-full text-sm text-gray-500"
            size="medium"
            fullWidth
            id={"attendant_id"}
            name={"attendant_id"}
            label={"Responsável pelo atendimento"}
            InputLabelProps={{
              className: "text-sm pt-[3px]",
            }}
            error={
              formik.touched.attendant_id && Boolean(formik.errors.attendant_id)
            }
            helperText={
              formik.touched.attendant_id && formik.errors.attendant_id
            }
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default AttendantField;
