import React from "react";
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import NotificationsDropdown from "../../../../shared/NotificationsDropdown";
import MessagesDropdown from "../../../../shared/MessagesDropdown";
import SearchGlobal from "../../../../shared/SearchGlobal";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  useMediaQuery,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import { SIDEBAR_STYLES } from "@jumbo/utils/constants";
import { useJumboHeaderTheme } from "@jumbo/hooks";
import { appTheme } from "app/themes/app/theme";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "app/redux/actions/auth";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const [dropdownSearchVisibility, setDropdownSearchVisibility] =
    React.useState(false);
  const { headerTheme } = useJumboHeaderTheme();
  const navigate = useNavigate();

  const showDropdownSearch = useMediaQuery("(max-width:575px)");

  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log(auth.user);

    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/login");

    dispatch(logout());
  };

  return (
    <React.Fragment>
      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        (sidebarOptions.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
          !sidebarOptions?.open)) && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{
            ml:
              sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                ? -2
                : 0,
            mr: 3,
          }}
          onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
        >
          {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
      )}
      {sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <Logo sx={{ mr: 3 }} mode={headerTheme.type ?? "light"} />
      )}
      {showDropdownSearch && (
        <Slide in={dropdownSearchVisibility}>
          <Div
            sx={{
              zIndex: 5,
              left: 0,
              right: 0,
              position: "absolute",
              height: "100%",
            }}
          >
            {/* <SearchGlobal
                            sx={{
                                maxWidth: 'none',
                                height: '100%',
                                display: 'flex',

                                '& .MuiInputBase-root': {
                                    flex: 1,
                                    borderRadius: 0,
                                    background: theme => theme.palette.background.default,
                                },
                                '& .MuiInputBase-input': {
                                    pr: 6,
                                }
                            }}
                        /> */}
            <IconButton
              sx={{
                position: "absolute",
                right: 15,
                top: "50%",
                color: appTheme.palette.neutral100,
                transform: "translateY(-50%)",
              }}
              onClick={() => setDropdownSearchVisibility(false)}
            >
              <CloseIcon color="#FFF" />
            </IconButton>
          </Div>
        </Slide>
      )}
      {/* {
                !showDropdownSearch &&
                <SearchGlobal
                    sx={{
                        maxWidth: {xs: 240, md: 320}
                    }}
                />
            } */}
      {/* <Stack direction="row" alignItems="center" spacing={1.25} sx={{ml: "auto"}}>
                {
                    showDropdownSearch &&
                    <JumboIconButton elevation={25} onClick={() => setDropdownSearchVisibility(true)}>
                        <SearchIcon fontSize={"small"}/>
                    </JumboIconButton>
                }
                <MessagesDropdown/>
                <NotificationsDropdown/>
                <AuthUserDropdown/>
            </Stack> */}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flex: 1,
          alignItems: "center",
        }}
      >
        <NotificationsDropdown />

        <Avatar
          src={`https://storage.googleapis.com/krebs-os-bucket/avatars/${
            auth.user ? auth.user.profile.avatar : ""
          }`}
          alt={auth.user ? auth.user.profile.name : ""}
          onClick={handleMenu}
          sx={{
            cursor: "pointer",
            width: 60,
            height: 60,
            boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.2)",
            ml: 5,
          }}
        />

        <Menu
          sx={{
            zIndex: 99999,
          }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </React.Fragment>
  );
};

export default Header;
