import { CLEAN_ALERT_STORE, SET_ALERT } from './constants';

const initialState = {
  alert: { message: '', variant: '' },
};

export default function alerts(state = initialState, action) {
  switch (action.type) {

    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      }
    case CLEAN_ALERT_STORE:
      return {
        ...state,
        alert: { message: '', variant: '' },
      }
    default:
      return state;


  }
}