import {
  asyncProductFamiliesActions,
} from "app/redux/reducers";
import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack, Typography } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { CLEAN_PRODUCT_FAMILIES_STORE } from "app/redux/modules/Products/families/constants";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING, STATUS_INPUT, TEXT_INPUT } from "app/components/DynamicViewGenerator/constants";
import * as Yup from "yup";

const Product = () => {
  const dispatch = useDispatch();
  const { productFamilies, auth } = useSelector((state) => state);

  const startPage = async () => {
    await dispatch(asyncProductFamiliesActions.getProductFamilies("asc", "id"));
  }
  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_PRODUCT_FAMILIES_STORE });
    }
  
  }, [dispatch]);


  const dataLabels = [
    {
      id: "Id",
      name: "Nome",
      created_at: "Data de Criação",
      updated_at: "Última atualização",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      description: DATA_TYPE_STRING,
      created_at: DATA_TYPE_DATE,
      updated_at: DATA_TYPE_DATE,
      status: DATA_TYPE_CHIP,

    },
  ];

  const formFieldsType = [
    {
      type: TEXT_INPUT,
      value: "name",
      label: "Nome",
      required: true,
      action: "Você precisa colocar seu nome.",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: TEXT_INPUT,
      value: "description",
      label: "Descrição",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: STATUS_INPUT,
      value: "status",
      label: "Status",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
  ];

  const pageConfig = {
    title: "Família de Produtos",
    newText: "Nova Família",
    searchText: "Pesquisar",
    data: productFamilies.data,
    loading: productFamilies.loading,
    actions: {
      delete: asyncProductFamiliesActions.deleteProductFamilies,
      edit: asyncProductFamiliesActions.editProductFamilies,
      get: asyncProductFamiliesActions.getProductFamilies,
      search: asyncProductFamiliesActions.getProductFamilies,
      create: asyncProductFamiliesActions.createProductFamilies,
      clean: { type: CLEAN_PRODUCT_FAMILIES_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default Product;
