import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { colors } from "app/utils/Colors";
import { LoadingButton } from "@mui/lab";

// const options = ['Aberto', 'Pendente', 'Resolvido', 'Fechado', 'Suspenso'];
const options = [
  { id: 1, color: "red", text: "Aberto" },
  { id: 2, color: "#1976d2", text: "Pendente" },
  { id: 3, color: "#9575cd", text: "Resolvido" },
  { id: 4, color: "#212121", text: "Fechado" },
  { id: 5, color: "#607d8b", text: "Em Andamento" },
];

export default function SplitButton({ setFieldValue, loading }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  React.useEffect(() => {
    setFieldValue("status", selectedIndex + 1);
  }, [selectedIndex]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <LoadingButton
          loading={loading}
          type="submit"
          sx={{
            backgroundColor: options[selectedIndex].color,
            color: colors.standardWhite,
            "&:hover": {
              backgroundColor: options[selectedIndex].color,
              color: colors.standardWhite,
            },
            borderColor: colors.standardWhite,
          }}
        >
          {options[selectedIndex].text}
        </LoadingButton>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            backgroundColor: options[selectedIndex].color,
            color: colors.standardWhite,
            "&:hover": {
              backgroundColor: options[selectedIndex].color,
              color: colors.standardWhite,
            },
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
