import {
  asyncMacrosActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING, STATUS_INPUT, TEXT_INPUT } from "app/components/DynamicViewGenerator/constants";
import { CLEAN_MACROS_STORE } from "app/redux/modules/Setup/Macros/constants";
import * as Yup from "yup";

const Macros = () => {
  const dispatch = useDispatch();
  const { macros } = useSelector((state) => state);

  const startPage = async () => {
     dispatch(asyncMacrosActions.get());
  }

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_MACROS_STORE });
    }
  }, [dispatch]);
 



  const dataLabels = [
    {
      id: "Id",
      name: "Nome",   
      message: "Mensagem",  
      created_at: "Data criação",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      message: DATA_TYPE_STRING,
      status: DATA_TYPE_CHIP,
      created_at: DATA_TYPE_DATE,
      },
  ];

  const formFieldsType = [
    {
      type: TEXT_INPUT,
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    }, 
    {
      type: TEXT_INPUT,
      value: "message",
      label: "Mensagem",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
      
    },   
    {
      type: STATUS_INPUT,
      value: "status",
      label: "Status",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },   
  ];

  const pageConfig = {
    title: "Macros de atendimento",
    newText: "Novo macro",
    searchText: "Pesquisar",
    data: macros.data,
    loading: macros.loading,
    actions: {
      delete: asyncMacrosActions.delete,
      edit: asyncMacrosActions.edit,
      get: asyncMacrosActions.get,
      search: asyncMacrosActions.get,
      create: asyncMacrosActions.create,
      clean: { type: CLEAN_MACROS_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default Macros;
