import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import { useFetchTaskTypesQuery } from "../../features/serviceOrderFields-slice";
import { FieldSkeleton } from "app/components/CustomInputs/Skeleton";

function TaskTypeSoField({ formik, handlePreviewData, ...props }) {
  const { data, isFetching, isLoading } = useFetchTaskTypesQuery();

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          formik.setFieldValue(
            "service_type",
            value !== null ? value.id : undefined
          );
          handlePreviewData(
            "service_type",
            value !== null ? value.name : undefined
          );
        }}
        value={
          data?.data?.find(
            (option) => option.id === formik.values.service_type
          ) || null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"service_type"}
            name={"service_type"}
            label={"Tipo de serviço a ser realizado"}
            error={
              formik.touched.service_type && Boolean(formik.errors.service_type)
            }
            helperText={
              formik.touched.service_type && formik.errors.service_type
            }
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default TaskTypeSoField;
