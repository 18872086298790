import {
  GET_RESOLUTION_CATEGORIES_REQUEST,
  GET_RESOLUTION_CATEGORIES_SUCCESS,
  GET_RESOLUTION_CATEGORIES_FAILURE,

  CLEAN_RESOLUTION_CATEGORIES_STORE,
  
  DELETE_RESOLUTION_CATEGORIES_REQUEST,
  DELETE_RESOLUTION_CATEGORIES_SUCCESS,
  DELETE_RESOLUTION_CATEGORIES_FAILURE,

  POST_RESOLUTION_CATEGORIES_REQUEST,
  POST_RESOLUTION_CATEGORIES_FAILURE,
  POST_RESOLUTION_CATEGORIES_SUCCESS,

  PUT_RESOLUTION_CATEGORIES_REQUEST,
  PUT_RESOLUTION_CATEGORIES_FAILURE,
  PUT_RESOLUTION_CATEGORIES_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function resolutionCategories(state = initialState, action) {
  switch (action.type) {
    case GET_RESOLUTION_CATEGORIES_REQUEST:
    case DELETE_RESOLUTION_CATEGORIES_REQUEST:
    case POST_RESOLUTION_CATEGORIES_REQUEST:
    case PUT_RESOLUTION_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_RESOLUTION_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_RESOLUTION_CATEGORIES_SUCCESS:
    case POST_RESOLUTION_CATEGORIES_SUCCESS:
    case PUT_RESOLUTION_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_RESOLUTION_CATEGORIES_FAILURE:
    case POST_RESOLUTION_CATEGORIES_FAILURE:
    case DELETE_RESOLUTION_CATEGORIES_FAILURE:
    case PUT_RESOLUTION_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_RESOLUTION_CATEGORIES_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
