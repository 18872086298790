export const appTheme = {
    palette: {
        neutral100: "#FFF",
        principal: "#34495E",
        focus: "#2C3E50",
        gray100: "#CECECE",
        red100: "#E30613",
        red200: "#F65C5E",
        green100: "#43A047",
        green200: "#63b397"
    },
};