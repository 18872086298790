import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const pivoApiSlice = createApi({
  reducerPath: "pivoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchPivos: builder.query({
        query(values) {
          const queryStrings = [];
          if (values.page) {
            queryStrings.push(`page=${values.page}`);
          }
          if (values.orderBy) {
            queryStrings.push(`orderBy=${values.orderBy},${values.order}`);
          }
          if (values.s) {
            queryStrings.push(`s=${values.s}`);
          }
          if (values.company_id) {
            queryStrings.push(`company_id=${values.company_id}`);
          }
          if (values.need_oil_change) {
            queryStrings.push(`need_oil_change=${values.need_oil_change}`);
          }

          return `pivots/?${queryStrings.join("&")}`;
        },
        providesTags: ["Pivo"],
      }),
      fetchSinglePivo: builder.query({
        query(values) {
          return `pivots/${values}`;
        },
        providesTags: ["SinglePivo"],
      }),
      createPivo: builder.mutation({
        query(values) {
          if (values.last_oil_change) {
            values.last_oil_change =
              values.last_oil_change.format("YYYY-MM-DD");
          }

          return {
            url: "pivots",
            method: "POST",
            body: values,
          };
        },
        invalidatesTags: ["Pivo", "SinglePivo"],
      }),
      editPivo: builder.mutation({
        query(values) {
          if (values.last_oil_change) {
            values.last_oil_change =
              values.last_oil_change.format("YYYY-MM-DD");
          }

          return {
            url: `pivots/${values.id}`,
            method: "PUT",
            body: values,
          };
        },
        invalidatesTags: ["Pivo", "SinglePivo"],
      }),
      deletePivo: builder.mutation({
        query(values) {
          return {
            url: `pivots/${values}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["Pivo", "SinglePivo"],
      }),
    };
  },
});

export const {
  useFetchPivosQuery,
  useCreatePivoMutation,
  useEditPivoMutation,
  useDeletePivoMutation,
  useFetchSinglePivoQuery,
} = pivoApiSlice;
