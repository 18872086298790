import {
  GET_TICKETS_NOTIFICATIONS_SUCCESS,
  GET_TICKETS_NOTIFICATIONS_FAILURE,
  GET_TICKETS_NOTIFICATIONS_REQUEST,
  POST_TICKETS_NOTIFICATIONS_REQUEST,
  POST_TICKETS_NOTIFICATIONS_SUCCESS,
  POST_TICKETS_NOTIFICATIONS_FAILURE,

} from "./constants";
import api from "app/services/config";

export const getTicketsNotifications =
  () => async (dispatch, getState) => {
    dispatch({ type: GET_TICKETS_NOTIFICATIONS_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.get(`/tickets`, {
        params: {
          ticket_status_id: 1,
          noPaginate: true
  
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      dispatch({
        type: GET_TICKETS_NOTIFICATIONS_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TICKETS_NOTIFICATIONS_FAILURE,
        payload: error.message,
      });
    }
  };

  export const postTicketNotifications =
  (data) => async (dispatch, getState) => {
    dispatch({ type: POST_TICKETS_NOTIFICATIONS_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.post(`/users/onesignal`, 
      {
        onesignal_player_id: data.id
      },{ 
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      dispatch({
        type: POST_TICKETS_NOTIFICATIONS_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: POST_TICKETS_NOTIFICATIONS_FAILURE,
        payload: error.message,
      });
    }
  };
