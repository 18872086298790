//PRODUCT_FAMILIES Types

export const GET_PRODUCT_FAMILIES_REQUEST = "GET_PRODUCT_FAMILIES_REQUEST";
export const GET_PRODUCT_FAMILIES_FAILURE = "GET_PRODUCT_FAMILIES_FAILURE";
export const GET_PRODUCT_FAMILIES_SUCCESS = "GET_PRODUCT_FAMILIES_SUCCESS";

export const GET_SINGLE_PRODUCT_FAMILIES_REQUEST = 'get-single-PRODUCT_FAMILIES-request'
export const GET_SINGLE_PRODUCT_FAMILIES_FAILURE = 'get-single-PRODUCT_FAMILIES-failure';
export const GET_SINGLE_PRODUCT_FAMILIES_SUCCESS = 'get-single-PRODUCT_FAMILIES-success';

export const POST_PRODUCT_FAMILIES_REQUEST = "POST_PRODUCT_FAMILIES_REQUEST";
export const POST_PRODUCT_FAMILIES_FAILURE = "post-PRODUCT_FAMILIES-failure";
export const POST_PRODUCT_FAMILIES_SUCCESS = "post-PRODUCT_FAMILIES-success";

export const PUT_PRODUCT_FAMILIES_REQUEST = "PUT_PRODUCT_FAMILIES_REQUEST";
export const PUT_PRODUCT_FAMILIES_FAILURE = "put-PRODUCT_FAMILIES-failure";
export const PUT_PRODUCT_FAMILIES_SUCCESS = "put-PRODUCT_FAMILIES-success";

export const DELETE_PRODUCT_FAMILIES_REQUEST = "DELETE_PRODUCT_FAMILIES_REQUEST";
export const DELETE_PRODUCT_FAMILIES_FAILURE = "delete-PRODUCT_FAMILIES-failure";
export const DELETE_PRODUCT_FAMILIES_SUCCESS = "delete-PRODUCT_FAMILIES-success";

export const CLEAN_PRODUCT_FAMILIES_STORE = "CLEAN_PRODUCT_FAMILIES_STORE";