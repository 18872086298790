import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLazyFetchModelsQuery } from "../../features/serviceOrderFields-slice";
import { FieldSkeleton } from "app/components/CustomInputs/Skeleton";

function ModelSoField({ formik, handlePreviewData, ...props }) {
  const [trigger, { data, isFetching, isLoading }] = useLazyFetchModelsQuery();

  useEffect(() => {
    if (!formik.values.product) return;
    trigger(formik.values.product);
  }, [formik.values.product]);

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        disabled={formik.values.product === undefined}
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          formik.setFieldValue("model", value !== null ? value.id : undefined);
          handlePreviewData("model", value !== null ? value.name : undefined);

          formik.setFieldValue("issue", []);
          handlePreviewData("issue", undefined);
        }}
        value={
          data?.data?.find((option) => option.id === formik.values.model) ||
          null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"model"}
            name={"model"}
            label={"Modelo"}
            error={formik.touched.model && Boolean(formik.errors.model)}
            helperText={formik.touched.model && formik.errors.model}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default ModelSoField;
