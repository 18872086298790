import React from "react";
import BusinessIcon from "@mui/icons-material/Business";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FolderItem from "./FolderItem";
import StyledMenu from "app/shared/StyledMenu";
import { MENU_NAMES } from "app/pages/Pivos/utils/constants";
import usePivoContext from "app/pages/Pivos/hooks/usePivoContext";
import { useParams } from "react-router-dom";

const PivoSidebar = () => {
  const { id } = useParams();

  const { setMenuPivo, menuPivo } = usePivoContext();

  const menuUrl = (menu) => {
    return `/pivos/detalhes/${id}/${menu}`;
  };

  const folders = [
    {
      icon: <BusinessIcon fontSize={"small"} />,
      label: "Dashboard",
      slug: "dashboard",
      path: menuUrl(MENU_NAMES.PIVO_DASHBOARD),
      name: MENU_NAMES.PIVO_DASHBOARD,
    },
    {
      icon: <SettingsIcon fontSize={"small"} />,
      label: "Redutores",
      slug: "redutores",
      path: menuUrl(MENU_NAMES.PIVO_REDUCERS),
      name: MENU_NAMES.PIVO_REDUCERS,
    },
    // {
    //   icon: <AccessTimeIcon fontSize={"small"} />,
    //   label: "Histórico",
    //   slug: "history",
    //   path: menuUrl(MENU_NAMES.PIVO_HISTORY),
    //   name: MENU_NAMES.PIVO_HISTORY,
    // },
  ];
  return (
    <StyledMenu sx={{ mb: 2 }}>
      {folders.map((folder) => (
        <FolderItem
          key={folder.slug}
          path={folder.path}
          label={folder.label}
          icon={folder.icon}
          selected={folder.name === menuPivo}
          onClick={() => setMenuPivo(folder.name)}
        />
      ))}
    </StyledMenu>
  );
};

export default PivoSidebar;
