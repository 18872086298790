//FAMILY_ISSUES Types

export const GET_FAMILY_ISSUES_REQUEST = "GET_FAMILY_ISSUES_REQUEST";
export const GET_FAMILY_ISSUES_FAILURE = "GET_FAMILY_ISSUES_FAILURE";
export const GET_FAMILY_ISSUES_SUCCESS = "GET_FAMILY_ISSUES_SUCCESS";

export const GET_SINGLE_FAMILY_ISSUES_REQUEST = 'get-single-FAMILY_ISSUES-request'
export const GET_SINGLE_FAMILY_ISSUES_FAILURE = 'get-single-FAMILY_ISSUES-failure';
export const GET_SINGLE_FAMILY_ISSUES_SUCCESS = 'get-single-FAMILY_ISSUES-success';

export const POST_FAMILY_ISSUES_REQUEST = "POST_FAMILY_ISSUES_REQUEST";
export const POST_FAMILY_ISSUES_FAILURE = "post-FAMILY_ISSUES-failure";
export const POST_FAMILY_ISSUES_SUCCESS = "post-FAMILY_ISSUES-success";

export const PUT_FAMILY_ISSUES_REQUEST = "PUT_FAMILY_ISSUES_REQUEST";
export const PUT_FAMILY_ISSUES_FAILURE = "put-FAMILY_ISSUES-failure";
export const PUT_FAMILY_ISSUES_SUCCESS = "put-FAMILY_ISSUES-success";

export const DELETE_FAMILY_ISSUES_REQUEST = "DELETE_FAMILY_ISSUES_REQUEST";
export const DELETE_FAMILY_ISSUES_FAILURE = "delete-FAMILY_ISSUES-failure";
export const DELETE_FAMILY_ISSUES_SUCCESS = "delete-FAMILY_ISSUES-success";

export const SELECT_AN_FAMILY_ISSUE = "SELECT_AN_FAMILY_ISSUE";

export const CLEAN_FAMILY_ISSUES_STORE = "CLEAN_FAMILY_ISSUES_STORE";