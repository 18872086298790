import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Accordion, Card, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { history } from "app/redux/store";
import { appTheme } from "app/themes/app/theme";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { statusIdToName } from "app/utils/appHelpers";

const ProjectItem = ({ item }) => {
  const [expanded] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/os/detalhes/${item.id}`);
  };

  return (
    <Card sx={{ mb: 1 }} data-testid={`project-item-${item.id}`}>
      <Accordion
        expanded={expanded}
        onChange={handleClick}
        square
        sx={{ borderRadius: 2 }}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            px: 3,
            flex: 1,
            flexDirection: "row-reverse",
            minHeight: 90,

            "& .MuiAccordionSummary-content": {
              alignItems: "center",

              "&.Mui-expanded": {
                margin: "12px 0",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              borderRadius: 1,
              border: 1,
              color: "text.secondary",
              borderColor: "divider",
              transform: "none",
              height: 28,
              width: 28,
              alignItems: "center",
              justifyContent: "center",
              mr: 1,

              "&.Mui-expanded": {
                transform: "none",
                color: "primary.main",
                borderColor: "primary.main",
              },

              "& svg": {
                fontSize: "1.25rem",
              },
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              flex: 1,
            }}
          >
            <Div
              sx={{
                mr: { xs: 0, md: 2 },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid " + appTheme.palette.red100,
                bgcolor: appTheme.palette.neutral100,
                borderRadius: "5px",
                p: (theme) => theme.spacing(1, 2),
                px: 1,
                mb: { xs: 2, md: 0 },
              }}
            >
              <Typography variant={"h6"} color={appTheme.palette.red100} mb={0}>
                {item.so}
              </Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Empresa
              </Typography>
              <Typography variant={"body1"}>
                {item?.unit?.company?.name}
              </Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Tipo de Serviço
              </Typography>
              <Typography variant={"body1"}>
                {item.task && item.task.task_type
                  ? item.task.task_type.name
                  : ""}
              </Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Família
              </Typography>
              <Typography variant={"body1"}>
                {item.product_model
                  ? item.product_model.product.product_family.name
                  : "N/A"}
              </Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Status
              </Typography>
              <Typography variant={"body1"}>
                {statusIdToName(item.status_service_order_id)}
              </Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Aberto por
              </Typography>
              <Typography variant={"body1"}>
                {item.user?.profile
                  ? `${item.user?.profile?.name} ${item.user?.profile?.last_name}`
                  : "N/A"}
              </Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Data de Criação
              </Typography>
              <Typography variant={"body1"}>
                {moment(item.created_at).format("DD/MM/YYYY")}
              </Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Última Atualização
              </Typography>
              <Typography variant={"body1"}>
                {moment(item.updated_at).format("DD/MM/YYYY")}
              </Typography>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};

export default ProjectItem;
