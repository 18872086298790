import React from "react";
import PivoProvider from "../PivoProvider";
import ReducerList from "../Reducers/ReducerList";
import PivoDetailsLayout from "./Components/PivoDetailsLayout";

function PivoDetails() {
  return (
    <PivoProvider>
      <PivoDetailsLayout>
        <ReducerList />
      </PivoDetailsLayout>
    </PivoProvider>
  );
}

export default PivoDetails;
