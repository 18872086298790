import * as Yup from "yup";

export const pivoValidationSchema = Yup.object().shape({
  company_id: Yup.number().required("Obrigatório"),
  unit_id: Yup.number().required("Obrigatório"),
  fiscal_number: Yup.string().required("Obrigatório"),
  serial_number: Yup.string().required("Obrigatório"),
  pivot_spans: Yup.number().required("Obrigatório"),
  hourmeter: Yup.string().required("Obrigatório"),
  resale_id: Yup.number(),
});

export const reducerValidationSchema = Yup.object().shape({
  serial_number: Yup.string().required("Obrigatório"),
  oil_level: Yup.number().required("Obrigatório"),
  has_leak: Yup.number().required("Obrigatório"),
  pivot_id: Yup.number().required("Obrigatório"),
  // last_oil_verification: Yup.string().required("Obrigatório"),
  // date_last_oil_change: Yup.string().required("Obrigatório"),
  viscosity: Yup.number().required("Obrigatório"),
  contamination: Yup.number().required("Obrigatório"),
});
