import React from "react";
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import { useJumboLayoutSidebar } from "@jumbo/hooks";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import List from "@mui/material/List";
import PropTypes from "prop-types";
import { appTheme } from "app/themes/app/theme";
import { useSelector } from "react-redux";

const JumboVerticalNavbar = ({ items, translate }) => {
  const { sidebarOptions } = useJumboLayoutSidebar();

  const { auth } = useSelector((state) => state);

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);
  return (
    <List
      disablePadding
      sx={{
        pr: isMiniAndClosed ? 0 : 2,
        pb: 2,
      }}
    >
      {items.map(
        (item, index) =>
          (item.rolesAccess.includes("*") ||
            item.rolesAccess.includes(auth?.user?.role?.[0]?.name)) && (
            <JumboNavIdentifier translate item={item} key={index} />
          )
      )}
    </List>
  );
};
JumboVerticalNavbar.defaultProps = {
  items: PropTypes.array,
  translate: false,
};

export default JumboVerticalNavbar;
