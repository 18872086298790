import {
  GET_TICKETS_NOTIFICATIONS_REQUEST,
  GET_TICKETS_NOTIFICATIONS_SUCCESS,
  GET_TICKETS_NOTIFICATIONS_FAILURE,
  POST_TICKETS_NOTIFICATIONS_REQUEST,
  POST_TICKETS_NOTIFICATIONS_SUCCESS,
  POST_TICKETS_NOTIFICATIONS_FAILURE,

  CLEAN_TICKETS_NOTIFICATIONS_STORE,
  
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS_NOTIFICATIONS_REQUEST:  
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TICKETS_NOTIFICATIONS_SUCCESS: 
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };   
    case POST_TICKETS_NOTIFICATIONS_SUCCESS: 
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_TICKETS_NOTIFICATIONS_FAILURE: 
    case POST_TICKETS_NOTIFICATIONS_FAILURE: 
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_TICKETS_NOTIFICATIONS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
