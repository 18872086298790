import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  useFetchFamiliesQuery,
  useLazyFetchProductsQuery,
} from "../../features/serviceOrderFields-slice";
import { FieldSkeleton } from "app/components/CustomInputs/Skeleton";

function ProductsSoField({ formik, handlePreviewData, ...props }) {
  const [trigger, { data, isFetching, isLoading }] =
    useLazyFetchProductsQuery();

  useEffect(() => {
    if (!formik.values.family) return;
    trigger(formik.values.family);
  }, [formik.values.family]);

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        disabled={formik.values.family === undefined}
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          formik.setFieldValue(
            "product",
            value !== null ? value.id : undefined
          );
          handlePreviewData("product", value !== null ? value.name : undefined);

          formik.setFieldValue("model", undefined);
          handlePreviewData("model", undefined);

          formik.setFieldValue("issue", []);
          handlePreviewData("issue", undefined);
        }}
        value={
          data?.data?.find((option) => option.id === formik.values.product) ||
          null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"product"}
            name={"product"}
            label={"Produtos"}
            error={formik.touched.product && Boolean(formik.errors.product)}
            helperText={formik.touched.product && formik.errors.product}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default ProductsSoField;
