import React from "react";
import PivoProvider from "../../PivoProvider";
import PivoDetailsLayout from "../Components/PivoDetailsLayout";
import PivotGraphs from "./PivotGraphs";

function PivoDashboard() {
  return (
    <PivoProvider>
      <PivoDetailsLayout>
        <PivotGraphs />
      </PivoDetailsLayout>
    </PivoProvider>
  );
}

export default PivoDashboard;
