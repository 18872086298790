import React from "react";
import FileManagerProvider, { FileManagerContext } from "./FileManagerProvider";
import FileManagerList from "./FileManagerList";
import FileUploadModal from "./components/FileUploadModal/FileUploadModal";
import NewCategoryModal from "./components/NewCategoryModal/NewCategoryModal";

function FileManager() {
  return (
    <FileManagerProvider>
      <NewCategoryModal />
      <FileUploadModal />
      <FileManagerList />
    </FileManagerProvider>
  );
}

export default FileManager;
