import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from "./app/App";
import './app/config/i18n';
import 'index.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// if ('serviceWorker' in navigator) {
//   // Register the service worker after the page has loaded
//   window.addEventListener('load', function() {
//     navigator.serviceWorker.addEventListener('message', event => {
//       console.log(`Received message from service worker: ${event.data}`);
//     });

//     this.navigator.serviceWorker.register('/OneSignalSDKWorker.js').then(function(registration) {
//       console.log('ServiceWorker Onesignal successful with scope: ', registration.scope);
//     }, function(err) {
//       // Registration failed
//       console.log('ServiceWorker Onesignal registration failed: ', err);
//     });

//     navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
//       // Registration was successful
      
//       console.log('ServiceWorker registration successful with scope: ', registration.scope);
//     }, function(err) {
//       // Registration failed
//       console.log('ServiceWorker registration failed: ', err);
//     });
//   });
// }