//TECHNICIAN Types

export const GET_TECHNICIAN_REQUEST = "GET_TECHNICIAN_REQUEST";
export const GET_TECHNICIAN_FAILURE = "GET_TECHNICIAN_FAILURE";
export const GET_TECHNICIAN_SUCCESS = "GET_TECHNICIAN_SUCCESS";

export const GET_SINGLE_TECHNICIAN_REQUEST = "get-single-TECHNICIAN-request";
export const GET_SINGLE_TECHNICIAN_FAILURE = "get-single-TECHNICIAN-failure";
export const GET_SINGLE_TECHNICIAN_SUCCESS = "get-single-TECHNICIAN-success";

export const GET_FREE_TECHNICIAN_REQUEST = "get-free-TECHNICIAN-request";
export const GET_FREE_TECHNICIAN_FAILURE = "get-free-TECHNICIAN-failure";
export const GET_FREE_TECHNICIAN_SUCCESS = "get-free-TECHNICIAN-success";

export const POST_TECHNICIAN_REQUEST = "POST_TECHNICIAN_REQUEST";
export const POST_TECHNICIAN_FAILURE = "post-TECHNICIAN-failure";
export const POST_TECHNICIAN_SUCCESS = "post-TECHNICIAN-success";

export const PUT_TECHNICIAN_REQUEST = "PUT_TECHNICIAN_REQUEST";
export const PUT_TECHNICIAN_FAILURE = "put-TECHNICIAN-failure";
export const PUT_TECHNICIAN_SUCCESS = "put-TECHNICIAN-success";

export const DELETE_TECHNICIAN_REQUEST = "DELETE_TECHNICIAN_REQUEST";
export const DELETE_TECHNICIAN_FAILURE = "delete-TECHNICIAN-failure";
export const DELETE_TECHNICIAN_SUCCESS = "delete-TECHNICIAN-success";

export const SELECT_AN_TECHNICIAN = "SELECT_AN_TECHNICIAN";

export const CLEAN_TECHNICIAN_STORE = "CLEAN_TECHNICIAN_STORE";
