import { appTheme } from "app/themes/app/theme";
import React from "react";
import ReactLoading from 'react-loading';

export default function Loading() {

    return (
        <div
            style={{
                position: "absolute",
                width: "100vw",
                height: "100%",
                backgroundColor: "rgba(0,0,0,.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 999999,
                top: 0,
                left: 0,
                bottom: 0
            }}
        >
            <ReactLoading type="spin" color={appTheme.palette.red100} height={70} width={70} />
        </div>
    );
}