//RESELLER Types

export const GET_RESELLER_REQUEST = "GET_RESELLER_REQUEST";
export const GET_RESELLER_FAILURE = "GET_RESELLER_FAILURE";
export const GET_RESELLER_SUCCESS = "GET_RESELLER_SUCCESS";

export const GET_SINGLE_RESELLER_REQUEST = 'get-single-RESELLER-request'
export const GET_SINGLE_RESELLER_FAILURE = 'get-single-RESELLER-failure';
export const GET_SINGLE_RESELLER_SUCCESS = 'get-single-RESELLER-success';

export const POST_RESELLER_REQUEST = "POST_RESELLER_REQUEST";
export const POST_RESELLER_FAILURE = "post-RESELLER-failure";
export const POST_RESELLER_SUCCESS = "post-RESELLER-success";

export const PUT_RESELLER_REQUEST = "PUT_RESELLER_REQUEST";
export const PUT_RESELLER_FAILURE = "put-RESELLER-failure";
export const PUT_RESELLER_SUCCESS = "put-RESELLER-success";

export const DELETE_RESELLER_REQUEST = "DELETE_RESELLER_REQUEST";
export const DELETE_RESELLER_FAILURE = "delete-RESELLER-failure";
export const DELETE_RESELLER_SUCCESS = "delete-RESELLER-success";

export const CLEAN_RESELLER_STORE = "CLEAN_RESELLER_STORE";