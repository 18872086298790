import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack, Typography } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";

import { CLEAN_CUSTOMERS_STORE } from "app/redux/modules/Users/Customers/constants";
import { asyncCompaniesActions, asyncUserSacActions } from "app/redux/reducers";
import {
  DATA_TYPE_CHIP,
  DATA_TYPE_DATE,
  DATA_TYPE_STRING,
  EMAIL_INPUT,
  MASKED_INPUT,
  PASSWORD_INPUT,
  PHONE_INPUT,
  PHOTO_UPLOAD_INPUT,
  SELECT_AUTOCOMPLETE_INPUT,
  STATUS_INPUT,
  TEXT_INPUT,
  UNIT_INPUT,
} from "app/components/DynamicViewGenerator/constants";
import { CLEAN_SAC_STORE } from "app/redux/modules/Users/Sac/constants";
import * as Yup from "yup";
import api from "app/services/config";

const Sac = () => {
  const dispatch = useDispatch();
  const { sac, auth, companies } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);

  const startPage = async () => {
    dispatch(asyncUserSacActions.get("asc", "id"));
    dispatch(
      asyncCompaniesActions.getCompanies("asc", "id", "1", "", "", true)
    );
  };
  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_SAC_STORE });
    };
  }, [dispatch]);

  useEffect(() => {
    if (companies.data) {
      setNewSelectOptions(
        companies.data.data &&
          companies.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [companies]);

  const dataLabels = [
    {
      avatar: "Avatar",
      name: "Nome",
      last_name: "Último Nome",
      // cpf: "Cpf",
      phone: "Telefone",
      email: "E-mail",
      //id: "Código",
      created_at: "Data criação",
      status: "Status",
    },
  ];

  const dataType = [
    {
      avatar: {
        parent: "profile",
        name: "avatar",
      },
      id: "string",
      name: {
        parent: "profile",
        name: "name",
      },
      cpf: {
        parent: "profile",
        name: "cpf",
      },
      phone: {
        parent: "profile",
        name: "phone",
      },
      email: "string",
      last_name: {
        parent: "profile",
        name: "last_name",
      },
      created_at: DATA_TYPE_DATE,
      status: DATA_TYPE_CHIP,
    },
  ];

  const formFieldsType = [
    {
      type: PHOTO_UPLOAD_INPUT,
      value: "avatar",
      label: "Avatar",
      required: false,
      action: "",
      parent: "profile",
    },

    {
      type: TEXT_INPUT,
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      parent: "profile",
      validation: Yup.string().required("Obrigatório"),
    },

    {
      type: TEXT_INPUT,
      value: "last_name",
      label: "Último Nome",
      required: true,
      action: "",
      parent: "profile",
      validation: Yup.string().required("Obrigatório"),
    },
    {
      type: MASKED_INPUT,
      value: "cpf",
      label: "Cpf",
      required: true,
      mask: "999.999.999-99",
      action: "",
      parent: "profile",
      validation: Yup.string()
        .transform((value) => value.replace(/[^\d]/g, ""))
        .min(11, "Cpf inválido")
        .required("Obrigatório"),
    },
    {
      type: EMAIL_INPUT,
      value: "email",
      label: "E-mail",
      required: true,
      action: "",
      parent: "",
      validation: Yup.string()
        .email("Email inválido")
        .required("Obrigatório")
        .test("", "E-mail já utilizado", async (value) => {
          if (value) {
            try {
              const response = await api.get(`users/has-email?email=${value}`, {
                headers: {
                  Authorization: `Bearer ${auth.token}`,
                },
              });
              return !response.data;
            } catch (error) {
              return true;
            }
          }
        }),
    },
    {
      type: PHONE_INPUT,
      value: "phone",
      label: "Telefone",
      required: true,
      mask: "(99) 9 9999-9999",
      action: "",
      parent: "profile",
      validation: Yup.string()
        .transform((value) => value.replace(/[^\d]/g, ""))
        .min(10, "Número inválido")
        .required("Obrigatório"),
    },
    {
      type: SELECT_AUTOCOMPLETE_INPUT,
      value: "company_id",
      label: "Empresa",
      options: newSelectOptions,
      required: true,
      action: "",
      parent: "profile",
      child: "unit",
      validation: Yup.string().required("Obrigatório"),
    },
    {
      type: UNIT_INPUT,
      value: "unit_id",
      label: "Unidade",
      required: true,
      action: "",
      parent: "profile",
      validation: Yup.string().required("Obrigatório"),
    },
    {
      type: PASSWORD_INPUT,
      value: "password",
      label: "Senha",
      required: true,
      action: "",
      parent: "",
      validation: Yup.string()
        .required("Obrigatório")
        .min(6, "Insira o mínimo de 6 caracteres"),
    },
    {
      type: STATUS_INPUT,
      value: "status",
      label: "Status",
      required: true,
      action: "",
      parent: "",
      validation: Yup.string().required("Obrigatório"),
    },
  ];

  const pageConfig = {
    title: "Sac",
    newText: "Novo sac",
    searchText: "Pesquisar",
    data: sac.data,
    loading: sac.loading,
    actions: {
      delete: asyncUserSacActions.delete,
      edit: asyncUserSacActions.edit,
      get: asyncUserSacActions.get,
      search: asyncUserSacActions.get,
      create: asyncUserSacActions.create,
      clean: { type: CLEAN_SAC_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default Sac;
