import {
  GET_PRODUCT_MODEL_REQUEST,
  GET_PRODUCT_MODEL_SUCCESS,
  GET_PRODUCT_MODEL_FAILURE,

  CLEAN_PRODUCT_MODEL_STORE,
  
  DELETE_PRODUCT_MODEL_REQUEST,
  DELETE_PRODUCT_MODEL_SUCCESS,
  DELETE_PRODUCT_MODEL_FAILURE,

  POST_PRODUCT_MODEL_REQUEST,
  POST_PRODUCT_MODEL_FAILURE,
  POST_PRODUCT_MODEL_SUCCESS,

  PUT_PRODUCT_MODEL_REQUEST,
  PUT_PRODUCT_MODEL_FAILURE,
  PUT_PRODUCT_MODEL_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function productModels(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_MODEL_REQUEST:
    case DELETE_PRODUCT_MODEL_REQUEST:
    case POST_PRODUCT_MODEL_REQUEST:
    case PUT_PRODUCT_MODEL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PRODUCT_MODEL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PRODUCT_MODEL_SUCCESS:
    case POST_PRODUCT_MODEL_SUCCESS:
    case PUT_PRODUCT_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_MODEL_FAILURE:
    case POST_PRODUCT_MODEL_FAILURE:
    case DELETE_PRODUCT_MODEL_FAILURE:
    case PUT_PRODUCT_MODEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_PRODUCT_MODEL_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
