import React, { useEffect, useState } from "react";
import { TimelineItem } from "@mui/lab";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  LocationOn,
  MiscellaneousServices,
  Today,
  EventAvailable,
  Schedule,
  Person,
  Business,
  CoffeeMaker,
  ReportProblem,
  Person2,
  Notes,
  Engineering,
  TipsAndUpdates,
  SettingsSuggest,
  Build,
  Photo,
  FmdBad,
  EmojiObjects,
} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import moment from "moment";
import Grid from "@mui/material/Grid";

const ScheduleItem = ({ item }) => {
  const ticketsStatus = [
    "Aberto",
    "Técnico a caminho",
    "Técnico chegou",
    "Resolvido",
    "Pendente",
    "Orfão",
    "Cancelado",
  ];

  const [stagesImages, setStagesImages] = useState({
    OPEN: [],
    ATTENDACCE: [],
    FINISH: [],
  });

  const labels = {
    OPEN: "Mídias de Abertura",
    ATTENDACCE: "Mídias de Chegada",
    FINISH: "Mídias de Fechamento",
  };

  const taskTypes = {
    standard: "Padrão",
    reducer_maintenance: "Manutenção de Pivô",
  };

  useEffect(() => {
    const stages = {
      OPEN: [],
      ATTENDACCE: [],
      FINISH: [],
    };

    console.log("item.attachments > ", item.attachments);

    if (item.attachments)
      item.attachments.map((attachment) => {
        switch (attachment.stage) {
          case "FINISH":
            stages.FINISH.push(attachment);
            break;
          case "ATTENDACCE":
            stages.ATTENDACCE.push(attachment);
            break;
          default:
            stages.OPEN.push(attachment);
            break;
        }
      });

    setStagesImages(stages);
  }, [item]);

  function getTecsList() {
    const list = [];

    if (item.external_user_technicians)
      item.external_user_technicians.map((tec) => {
        if (tec.user)
          list.push(`${tec.user.profile.name} ${tec.user.profile.last_name}`);
      });

    return list.length > 0 ? list.join(", ") : "N/A";
  }

  // function getPartsList() {
  //   const list = [];

  //   if (item.product_model_parts && item.product_model_parts.length > 0)
  //     item.product_model_parts.map((part) => {
  //       list.push(part.name);
  //     });

  //   return list.length > 0 ? list.join(", ") : "N/A";
  // }

  function getFamilyName() {
    if (item.product_model)
      return item.product_model.product.product_family.name;

    return "N/A";
  }

  function getProductName() {
    if (item.product_model) return item.product_model.product.name;

    return "N/A";
  }

  function getModelName() {
    if (item.product_model) return item.product_model.name;

    return "N/A";
  }

  function getProblem(item) {
    if (item.product_family_issues)
      return item.product_family_issues?.map((item) => item.name).join(", ");

    return "N/A";
  }

  function getSolutions(item) {
    if (item.product_family_solutions)
      return item.product_family_solutions?.map((item) => item.name).join(", ");

    return "N/A";
  }

  function getPivots(item) {
    if (item.pivots) return item.pivots[0].serial_number;

    return "N/A";
  }

  function getReducers(item) {
    if (item.reducers)
      return item.reducers?.map((item) => item.serial_number).join(", ");

    return "N/A";
  }

  function getPartsList(item) {
    if (item.product_family_parts)
      return item.product_family_parts?.map((item) => item.name).join(", ");

    return "N/A";
  }

  return (
    <TimelineItem
      sx={{
        "&::before": {
          display: "none",
        },
      }}
    >
      <List disablePadding sx={{ width: "100%" }}>
        <Grid
          container
          rowSpacing={1}
          alignItems="flex-start"
          justifyContent="space-between"
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              Dados da abertura da OS
            </Typography>
            <Divider component="li" />
          </Grid>
          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <MiscellaneousServices />
              </ListItemIcon>
              <ListItemText
                primary="Tipo de OS"
                secondary={taskTypes[item.so_type]}
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <CoffeeMaker />
              </ListItemIcon>
              <ListItemText
                primary="Tipo de Serviço"
                secondary={item.task ? item.task.task_type.name : "N/A"}
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <LocationOn />
              </ListItemIcon>
              <ListItemText
                primary="Técnicos Responsáveis"
                secondary={getTecsList()}
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Business />
              </ListItemIcon>
              <ListItemText
                primary="Status"
                secondary={
                  item.status_service_order && item.status_service_order.name
                }
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Today />
              </ListItemIcon>
              <ListItemText
                primary="Data de criação"
                secondary={moment(item.created_at).format("DD/MM/YYYY")}
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Today />
              </ListItemIcon>
              <ListItemText
                primary="Data de agendamento"
                secondary={
                  item.task
                    ? moment(item.task.scheduled_at).format("DD/MM/YYYY HH:mm")
                    : ""
                }
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Person />
              </ListItemIcon>
              <ListItemText
                primary="Nota Fiscal"
                secondary={item.invoice || "N/A"}
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Person />
              </ListItemIcon>
              <ListItemText
                primary="Número de Série"
                secondary={item.serial_number || "N/A"}
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <FmdBad />
              </ListItemIcon>
              <ListItemText
                primary="Ocorrências"
                secondary={getProblem(item)}
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <MiscellaneousServices />
              </ListItemIcon>
              <ListItemText primary="Produto" secondary={getProductName()} />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <MiscellaneousServices />
              </ListItemIcon>
              <ListItemText primary="Modelo" secondary={getModelName()} />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <MiscellaneousServices />
              </ListItemIcon>
              <ListItemText primary="Família" secondary={getFamilyName()} />
            </ListItem>
          </Grid>

          {item.so_type === "reducer_maintenance" && (
            <>
              <Grid item xs={4}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ p: (theme) => theme.spacing(1.25, 3) }}
                >
                  <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                    <MiscellaneousServices />
                  </ListItemIcon>
                  <ListItemText
                    primary="Pivôs"
                    secondary={getPivots(item) || "N/A"}
                  />
                </ListItem>
              </Grid>

              <Grid item xs={4}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ p: (theme) => theme.spacing(1.25, 3) }}
                >
                  <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                    <MiscellaneousServices />
                  </ListItemIcon>
                  <ListItemText
                    primary="Redutores"
                    secondary={getReducers(item) || "N/A"}
                  />
                </ListItem>
              </Grid>

              <Grid item xs={4}></Grid>
            </>
          )}

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Schedule />
              </ListItemIcon>
              <ListItemText
                primary="Descrição"
                secondary={item.description || "N/A"}
              />
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Schedule />
              </ListItemIcon>
              <ListItemText
                primary="Observações Internas"
                secondary={item.internal_observations || "N/A"}
              />
            </ListItem>
          </Grid>

          {Object.keys(stagesImages)
            .filter((i) => i === "OPEN")
            .map((key) => (
              <Grid item xs={12}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ p: (theme) => theme.spacing(1.25, 3) }}
                >
                  <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                    <Photo />
                  </ListItemIcon>
                  <Stack>
                    <>
                      <>
                        <ListItemText
                          primary={labels[key]}
                          secondary={stagesImages[key].length > 0 ? "" : "N/A"}
                          secondaryTypographyProps={{
                            ml: 2,
                          }}
                        />
                        <Stack flexDirection="row">
                          {stagesImages[key].map((image) => (
                            <a
                              href={`https://storage.googleapis.com/krebs-os-bucket/attachments/${image.name}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {image.type.includes("image") && (
                                <img
                                  alt="Imagem Técnico"
                                  style={{
                                    width: 240,
                                    objectFit: "contain",
                                    border: "1px solid #ddd",
                                    margin: "0 3px",
                                    cursor: "pointer",
                                    height: 220,
                                    padding: 0,
                                    borderRadius: 10,
                                  }}
                                  src={`https://storage.googleapis.com/krebs-os-bucket/attachments/${image.name}`}
                                />
                              )}

                              {image.type.includes("video") && (
                                <video
                                  alt="Vídeo Técnico"
                                  style={{
                                    width: 280,
                                    objectFit: "contain",
                                    border: "1px solid #ddd",
                                    margin: "0 3px",
                                    cursor: "pointer",
                                    height: 220,
                                    padding: 0,
                                    borderRadius: 10,
                                  }}
                                  src={`https://storage.googleapis.com/krebs-os-bucket/attachments/${image.name}`}
                                />
                              )}
                            </a>
                          ))}
                        </Stack>
                      </>
                    </>
                  </Stack>
                </ListItem>
              </Grid>
            ))}

          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              Informações do cliente{" "}
            </Typography>
            <Divider component="li" />
          </Grid>
          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <LocationOn />
              </ListItemIcon>
              <ListItemText
                primary="Cliente"
                secondary={
                  item.responsible_user
                    ? item.responsible_user.profile.name
                    : ""
                }
              />
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <LocationOn />
              </ListItemIcon>
              <ListItemText
                primary="Unidade"
                secondary={item.unit ? item.unit.name : "N/A"}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <LocationOn />
              </ListItemIcon>
              <ListItemText
                primary="Endereço"
                secondary={item.unit ? item.unit.address : "N/A"}
              />
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              Dados do Início do Atendimento
            </Typography>
            <Divider component="li" />
          </Grid>

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <ReportProblem />
              </ListItemIcon>
              <ListItemText
                primary="Observações da Chegada"
                secondary={item.arrived_observations || "N/A"}
              />
            </ListItem>
          </Grid>

          {Object.keys(stagesImages)
            .filter((i) => i === "ATTENDACCE")
            .map((key) => (
              <Grid item xs={12}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ p: (theme) => theme.spacing(1.25, 3) }}
                >
                  <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                    <Photo />
                  </ListItemIcon>
                  <Stack>
                    <>
                      <>
                        <ListItemText
                          primary={labels[key]}
                          secondary={stagesImages[key].length > 0 ? "" : "N/A"}
                          secondaryTypographyProps={{
                            ml: 2,
                          }}
                        />
                        <Stack flexDirection="row">
                          {stagesImages[key].map((image) => (
                            <a
                              href={`https://storage.googleapis.com/krebs-os-bucket/attachments/${image.name}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {image.type.includes("image") && (
                                <img
                                  alt="Imagem Técnico"
                                  style={{
                                    width: 240,
                                    objectFit: "contain",
                                    border: "1px solid #ddd",
                                    margin: "0 3px",
                                    cursor: "pointer",
                                    height: 220,
                                    padding: 0,
                                    borderRadius: 10,
                                  }}
                                  src={`https://storage.googleapis.com/krebs-os-bucket/attachments/${image.name}`}
                                />
                              )}

                              {image.type.includes("video") && (
                                <video
                                  alt="Vídeo Técnico"
                                  style={{
                                    width: 280,
                                    objectFit: "contain",
                                    border: "1px solid #ddd",
                                    margin: "0 3px",
                                    cursor: "pointer",
                                    height: 220,
                                    padding: 0,
                                    borderRadius: 10,
                                  }}
                                  src={`https://storage.googleapis.com/krebs-os-bucket/attachments/${image.name}`}
                                />
                              )}
                            </a>
                          ))}
                        </Stack>
                      </>
                    </>
                  </Stack>
                </ListItem>
              </Grid>
            ))}

          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              Dados da Resolução da OS
            </Typography>
            <Divider component="li" />
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <EventAvailable />
              </ListItemIcon>
              <ListItemText
                primary="Data da resolução"
                secondary={
                  item.resolution_at
                    ? moment(item.resolution_at).format("DD/MM/YYYY HH:mm")
                    : "N/A"
                }
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <MiscellaneousServices />
              </ListItemIcon>
              <ListItemText
                primary="Status Máquina"
                secondary={
                  item.status_equipment ? item.status_equipment.name : "N/A"
                }
              />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <MiscellaneousServices />
              </ListItemIcon>
              <ListItemText
                primary="Peças"
                secondary={getPartsList(item) || "N/A"}
              />
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <EmojiObjects />
              </ListItemIcon>
              <ListItemText
                primary="Solução"
                secondary={getSolutions(item) || "N/A"}
              />
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Person2 />
              </ListItemIcon>
              <ListItemText
                primary="Assinante"
                secondary={item.signature_owner || "N/A"}
              />
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Person2 />
              </ListItemIcon>
              <ListItemText
                primary="Assinatura"
                secondary={item.signature ? "" : "N/A"}
              />
            </ListItem>

            {item.signature && (
              <Stack ml={7} mb={1}>
                <img
                  alt="Assinatura Responsável"
                  style={{
                    width: 250,
                    objectFit: "contain",
                    border: "1px solid #ddd",
                    margin: "0 3px",
                    cursor: "pointer",
                    height: 150,
                    padding: 0,
                    borderRadius: 10,
                  }}
                  src={`data:image/jpeg;base64,${item.signature}`}
                />
              </Stack>
            )}
          </Grid>

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <ReportProblem />
              </ListItemIcon>
              <ListItemText
                primary="Observações da Resolução"
                secondary={item.resolution_note || "N/A"}
              />
            </ListItem>
          </Grid>

          <Grid item xs={12}>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Schedule />
              </ListItemIcon>
              <ListItemText
                primary="Observações do Cliente"
                secondary={item.client_observations || "N/A"}
              />
            </ListItem>
          </Grid>

          {Object.keys(stagesImages)
            .filter((i) => i === "FINISH")
            .map((key) => (
              <Grid item xs={12}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ p: (theme) => theme.spacing(1.25, 3) }}
                >
                  <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                    <Photo />
                  </ListItemIcon>
                  <Stack>
                    <>
                      <>
                        <ListItemText
                          primary={labels[key]}
                          secondary={stagesImages[key].length > 0 ? "" : "N/A"}
                          secondaryTypographyProps={{
                            ml: 2,
                          }}
                        />
                        <Stack flexDirection="row">
                          {stagesImages[key].map((image) => (
                            <a
                              href={`https://storage.googleapis.com/krebs-os-bucket/attachments/${image.name}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {image.type.includes("image") && (
                                <img
                                  alt="Imagem Técnico"
                                  style={{
                                    width: 240,
                                    objectFit: "contain",
                                    border: "1px solid #ddd",
                                    margin: "0 3px",
                                    cursor: "pointer",
                                    height: 220,
                                    padding: 0,
                                    borderRadius: 10,
                                  }}
                                  src={`https://storage.googleapis.com/krebs-os-bucket/attachments/${image.name}`}
                                />
                              )}

                              {image.type.includes("video") && (
                                <video
                                  alt="Vídeo Técnico"
                                  style={{
                                    width: 280,
                                    objectFit: "contain",
                                    border: "1px solid #ddd",
                                    margin: "0 3px",
                                    cursor: "pointer",
                                    height: 220,
                                    padding: 0,
                                    borderRadius: 10,
                                  }}
                                  src={`https://storage.googleapis.com/krebs-os-bucket/attachments/${image.name}`}
                                />
                              )}
                            </a>
                          ))}
                        </Stack>
                      </>
                    </>
                  </Stack>
                </ListItem>
              </Grid>
            ))}

          {/* <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Notes />
                    </ListItemIcon>
                    <ListItemText primary="Observação" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Person />
                    </ListItemIcon>
                    <ListItemText primary="Solicitante" secondary={"BackOffice"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Engineering />
                    </ListItemIcon>
                    <ListItemText primary="Técnico responsável" secondary={"Francisco Silva"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Notes />
                    </ListItemIcon>
                    <ListItemText primary="Observações do técnico" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <TipsAndUpdates />
                    </ListItemIcon>
                    <ListItemText primary="Diagnóstico de problemas" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <SettingsSuggest />
                    </ListItemIcon>
                    <ListItemText primary="Diagnóstico de peças" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Photo />
                    </ListItemIcon>
                    
                    <Stack>
                        <ListItemText
                            primary="Fotos do equipamento"
                            secondary={"N/A"}
                        />
                    </Stack>
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <CoffeeMaker />
                    </ListItemIcon>
                    <ListItemText primary="Funcionamento da máquina" secondary={"N/A"} />
                </ListItem>
                <Divider component="li"/>

                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.primary'}}>
                        <Build />
                    </ListItemIcon>
                    <ListItemText primary="Status" secondary={ticketsStatus[1]} />
                </ListItem> */}
        </Grid>
      </List>
    </TimelineItem>
  );
};

export default ScheduleItem;
