import React from "react";
import DvrIcon from "@mui/icons-material/Dvr";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AttachFile, FolderOpen, WaterDrop } from "@mui/icons-material";

//
// ADMIN
// CLIENT
// TECHNICIAN
// SAC
// RESALE
// READ_ONLY
const menus = [
  {
    label: "Atendimento",
    type: "section",
    rolesAccess: ["ADMIN", "RESALE", "READ_ONLY", "SAC"],
    children: [],
  },
  {
    uri: "/os",
    label: "Ordens de Serviço",
    type: "collapsible",
    rolesAccess: ["ADMIN", "RESALE", "READ_ONLY, SAC"],

    icon: <DvrIcon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/os/aberto",
        label: "Aberto",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/os/a-caminho",
        label: "A Caminho",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/os/chegou",
        label: "Chegou",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/os/resolvido",
        label: "Resolvido",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/os/pendente",
        label: "Pendente",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/os/cancelado",
        label: "Cancelado",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
    ],
  },
  {
    uri: "/agenda",
    label: "Agenda",
    type: "nav-item",
    rolesAccess: ["ADMIN", "RESALE", "READ_ONLY, SAC"],
    icon: <CalendarTodayIcon sx={{ fontSize: 20 }} />,
  },
  {
    label: "Tickets de Suporte",
    type: "collapsible",
    icon: <SupportAgentIcon sx={{ fontSize: 20 }} />,
    rolesAccess: ["ADMIN", "RESALE", "SAC", "READ_ONLY"],

    children: [
      {
        uri: "/tickets/todos",
        label: "Todos",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/tickets/aberto",
        label: "Aberto",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/tickets/pendente",
        label: "Pendente",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/tickets/resolvido",
        label: "Resolvido",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/tickets/fechado",
        label: "Fechado",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
      {
        uri: "/tickets/em-andamento",
        label: "Em Andamento",
        type: "nav-item",
        // icon: <GraphicEqIcon sx={{fontSize: 20}}/>
      },
    ],
  },

  {
    label: "Redutores",
    type: "section",
    rolesAccess: ["ADMIN", "RESALE", "SAC", "READ_ONLY"],
    children: [],
  },
  {
    rolesAccess: ["ADMIN", "RESALE", "SAC", "READ_ONLY"],
    uri: "/pivos",
    label: "Pivôs",
    type: "nav-item",
    icon: <WaterDrop sx={{ fontSize: 20 }} />,
  },
  {
    label: "Gerenciamento de Arquivos",
    type: "section",
    rolesAccess: ["ADMIN", "RESALE", "SAC", "READ_ONLY"],
    children: [],
  },
  {
    rolesAccess: ["ADMIN", "RESALE", "SAC", "READ_ONLY"],
    uri: "/gerenciamento-de-arquivos/arquivos",
    label: "Arquivos",
    type: "nav-item",
    icon: <AttachFile sx={{ fontSize: 20 }} />,
  },
  // {
  //   rolesAccess: ["ADMIN"],
  //   uri: "/gerenciamento-de-arquivos/categorias",
  //   label: "Categorias",
  //   type: "nav-item",
  //   icon: <FolderOpen sx={{ fontSize: 20 }} />,
  // },
  {
    label: "Configurações",
    type: "section",
    rolesAccess: ["ADMIN", "RESALE"],

    children: [],
  },
  {
    label: "Usuários",
    type: "collapsible",
    rolesAccess: ["ADMIN", "RESALE"],
    icon: <PeopleAlt sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/usuarios/clientes",
        label: "Clientes",
        type: "nav-item",
      },
      {
        uri: "/usuarios/tecnicos",
        label: "Técnicos",
        type: "nav-item",
      },
      {
        uri: "/usuarios/administradores",
        label: "Administradores",
        type: "nav-item",
        rolesAccess: ["ADMIN", "SAC"],
      },
      {
        uri: "/usuarios/sac",
        label: "Sac",
        type: "nav-item",
        rolesAccess: ["ADMIN", "SAC"],
      },
      {
        uri: "/usuarios/revendas",
        label: "Revendas",
        type: "nav-item",
        rolesAccess: ["ADMIN", "SAC"],
      },
      {
        uri: "/usuarios/somente-leitura",
        label: "Somente Leitura",
        type: "nav-item",
        rolesAccess: ["ADMIN", "SAC"],
      },
    ],
  },
  {
    label: "Empresas",
    type: "collapsible",
    rolesAccess: ["ADMIN", "RESALE"],

    icon: <LocationCityIcon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/empresas",
        label: "Empresas",
        type: "nav-item",
      },
      {
        uri: "/empresas/unidades",
        label: "Unidades",
        type: "nav-item",
      },
    ],
  },
  {
    label: "Produtos",
    type: "collapsible",
    rolesAccess: ["ADMIN"],
    icon: <ProductionQuantityLimitsIcon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/produtos/familias",
        label: "Famílias",
        type: "nav-item",
      },
      {
        uri: "/produtos/product",
        label: "Produtos",
        type: "nav-item",
      },
      {
        uri: "/produtos/modelos",
        label: "Modelos",
        type: "nav-item",
      },
      {
        uri: "/produtos/pecas",
        label: "Peças",
        type: "nav-item",
      },
      {
        uri: "/produtos/solucoes-de-familias",
        label: "Soluções",
        type: "nav-item",
      },
      {
        uri: "/produtos/ocorrencias-de-familias",
        label: "Ocorrências",
        type: "nav-item",
      },
    ],
  },

  {
    label: "Configurações",
    type: "collapsible",
    icon: <SettingsIcon sx={{ fontSize: 20 }} />,
    rolesAccess: ["ADMIN"],
    children: [
      {
        uri: "/configuracoes/categorias-de-tickets",
        label: "Categorias de Tickets",
        type: "nav-item",
      },
      {
        uri: "/configuracoes/tipos-de-atividades",
        label: "Tipos de Atividade",
        type: "nav-item",
      },
    ],
  },
];

export default menus;
