import {
  GET_PRODUCT_FAMILIES_REQUEST,
  GET_PRODUCT_FAMILIES_SUCCESS,
  GET_PRODUCT_FAMILIES_FAILURE,

  CLEAN_PRODUCT_FAMILIES_STORE,
  
  DELETE_PRODUCT_FAMILIES_REQUEST,
  DELETE_PRODUCT_FAMILIES_SUCCESS,
  DELETE_PRODUCT_FAMILIES_FAILURE,

  POST_PRODUCT_FAMILIES_REQUEST,
  POST_PRODUCT_FAMILIES_FAILURE,
  POST_PRODUCT_FAMILIES_SUCCESS,

  PUT_PRODUCT_FAMILIES_REQUEST,
  PUT_PRODUCT_FAMILIES_FAILURE,
  PUT_PRODUCT_FAMILIES_SUCCESS,
} from "./constants";
 
const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function productFamilies(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_FAMILIES_REQUEST:
    case DELETE_PRODUCT_FAMILIES_REQUEST:
    case POST_PRODUCT_FAMILIES_REQUEST:
    case PUT_PRODUCT_FAMILIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PRODUCT_FAMILIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PRODUCT_FAMILIES_SUCCESS:
    case POST_PRODUCT_FAMILIES_SUCCESS:
    case PUT_PRODUCT_FAMILIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_FAMILIES_FAILURE:
    case POST_PRODUCT_FAMILIES_FAILURE:
    case DELETE_PRODUCT_FAMILIES_FAILURE:
    case PUT_PRODUCT_FAMILIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_PRODUCT_FAMILIES_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
