import {
  GET_PRODUCT_FAMILIES_SUCCESS,
  GET_PRODUCT_FAMILIES_FAILURE,
  GET_PRODUCT_FAMILIES_REQUEST,
  DELETE_PRODUCT_FAMILIES_FAILURE,
  DELETE_PRODUCT_FAMILIES_REQUEST,
  DELETE_PRODUCT_FAMILIES_SUCCESS,
  POST_PRODUCT_FAMILIES_REQUEST,
  POST_PRODUCT_FAMILIES_FAILURE,
  POST_PRODUCT_FAMILIES_SUCCESS,
  PUT_PRODUCT_FAMILIES_REQUEST,
  PUT_PRODUCT_FAMILIES_FAILURE,
  PUT_PRODUCT_FAMILIES_SUCCESS,
} from "./constants";

import api from "app/services/config";
import { SET_ALERT } from "../../Alerts/constants";

export const getProductFamilies =
  (order, orderBy, status, page, searchKey, noPaginate) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_PRODUCT_FAMILIES_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/product-families`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: status ? status : search.data.status,
          page: search.data.page,
          s: searchKey,
          noPaginate: noPaginate,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_PRODUCT_FAMILIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCT_FAMILIES_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteProductFamilies = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_PRODUCT_FAMILIES_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/product-families/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_PRODUCT_FAMILIES_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAMILIES_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" },
    });
  } finally {
    dispatch(getProductFamilies("asc", "id"));
  }
};

export const createProductFamilies = (data) => async (dispatch, getState) => {
  dispatch({ type: POST_PRODUCT_FAMILIES_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.post(
      `/product-families`,
      {
        name: data.name,
        description: data.description,
        status: data.status,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    dispatch({
      type: POST_PRODUCT_FAMILIES_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Criado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: POST_PRODUCT_FAMILIES_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao criar", variant: "error" },
    });
  } finally {
    dispatch(getProductFamilies("asc", "id"));
  }
};

export const editProductFamilies = (data, id) => async (dispatch, getState) => {
  dispatch({ type: PUT_PRODUCT_FAMILIES_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.put(
      `/product-families/${id}`,
      {
        name: data.name,
        description: data.description,
        status: data.status,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    dispatch({
      type: PUT_PRODUCT_FAMILIES_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Editado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: PUT_PRODUCT_FAMILIES_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao editar", variant: "error" },
    });
  } finally {
    dispatch(getProductFamilies("asc", "id"));
  }
};
