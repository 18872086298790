import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";
import { ORIGIN_SELECT_DATA } from "../../untils/constants";

function TechOriginSoField({ formik, handlePreviewData, ...props }) {
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        data-testid={"input-technicians_origin"}
        filterSelectedOptions={true}
        options={ORIGIN_SELECT_DATA.data || []}
        disabled={formik.values.time_end === ""}
        fullWidth
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          formik.setFieldValue(
            "technicians_origin",
            value !== null ? value.value : ""
          );

          formik.setFieldValue("responsibles", []);
          handlePreviewData("responsibles", "");
          formik.setFieldValue("resale", "");
        }}
        value={
          ORIGIN_SELECT_DATA.data.find(
            (option) => option.value === formik.values.technicians_origin
          ) || null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"technicians_origin"}
            name={"technicians_origin"}
            label={"Origem dos Técnicos"}
            error={
              formik.touched.technicians_origin &&
              Boolean(formik.errors.technicians_origin)
            }
            helperText={
              formik.touched.technicians_origin &&
              formik.errors.technicians_origin
            }
            // InputProps={{
            //   ...params.InputProps,

            //   endAdornment: (
            //     <React.Fragment>
            //       {isLoading || isFetching ? (
            //         <CircularProgress color="inherit" size={20} />
            //       ) : null}
            //       {params.InputProps.endAdornment}
            //     </React.Fragment>
            //   ),
            // }}
          />
        )}
      />
    </FormControl>
  );
}

export default TechOriginSoField;
