//PARTS Types

export const GET_PARTS_REQUEST = "GET_PARTS_REQUEST";
export const GET_PARTS_FAILURE = "GET_PARTS_FAILURE";
export const GET_PARTS_SUCCESS = "GET_PARTS_SUCCESS";

export const GET_SINGLE_PARTS_REQUEST = 'get-single-PARTS-request'
export const GET_SINGLE_PARTS_FAILURE = 'get-single-PARTS-failure';
export const GET_SINGLE_PARTS_SUCCESS = 'get-single-PARTS-success';

export const POST_PARTS_REQUEST = "POST_PARTS_REQUEST";
export const POST_PARTS_FAILURE = "post-PARTS-failure";
export const POST_PARTS_SUCCESS = "post-PARTS-success";

export const PUT_PARTS_REQUEST = "PUT_PARTS_REQUEST";
export const PUT_PARTS_FAILURE = "put-PARTS-failure";
export const PUT_PARTS_SUCCESS = "put-PARTS-success";

export const DELETE_PARTS_REQUEST = "DELETE_PARTS_REQUEST";
export const DELETE_PARTS_FAILURE = "delete-PARTS-failure";
export const DELETE_PARTS_SUCCESS = "delete-PARTS-success";

export const CLEAN_PARTS_STORE = "CLEAN_PARTS_STORE";