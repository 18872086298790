import React from "react";
import { useSelector } from "react-redux";

function useUserRole() {
  const { auth } = useSelector((state) => state);

  const role = auth.user.role[0].name;

  return {
    role,
  };
}

export default useUserRole;
