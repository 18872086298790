import React, { useEffect } from 'react';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import {alpha, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Divider from "@mui/material/Divider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import List from "@mui/material/List";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Stack from "@mui/material/Stack";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import Div from "@jumbo/shared/Div";
import moment from 'moment';
import useContactsApp from '../../hooks/useContactsApp';

const ContactDetail = ({contact, onClose, field}) => {
    const fields = {
        "unit": [
            {
                label: "Nome",
                field: "name",
                type: "text"
            },
            {
                label: "Endereço",
                field: "address",
                type: "text"
            },
            {
                label: "Informações de Endereço",
                field: "address_add_info",
                type: "text"
            },
            {
                label: "Classificação",
                field: "classification",
                type: "text"
            },
            {
                label: "Descrição",
                field: "description",
                type: "text"
            },
            {
                label: "Status",
                field: "status",
                type: "boolean"
            }
        ],
        "tickets": [
            {
                label: "Título",
                field: "title",
                type: "text"
            },
            {
                label: "Data Criação",
                field: "created_at",
                type: "date"
            },
            {
                label: "Categoria",
                field: "category.name",
                type: "text"
            },
            {
                label: "Canal",
                field: "channel.name",
                type: "text"
            },
            {
                label: "Solicitante",
                field: "attendant.profile.name",
                type: "text"
            },
            {
                label: "Responsável do Atendimento",
                field: "responsible_user.profile.name",
                type: "text"
            },
            {
                label: "Status",
                field: "status.name",
                type: "text"
            },
        ],
        "serviceOrders": [
            {
                label: "Data de Criação",
                field: "created_at",
                type: "date"
            },
            {
                label: "Data de Agendamento",
                field: "task.scheduled_at",
                type: "date"
            },
            {
                label: "Descrição",
                field: "description",
                type: "text"
            },
            {
                label: "Endereço",
                field: "unit.address",
                type: "text"
            },
            {
                label: "Status",
                field: "status_service_order.name",
                type: "text"
            },
            {
                label: "Tipo de Serviço",
                field: "task.task_type.name",
                type: "text"
            },
            {
                label: "Última atualização",
                field: "updated_at",
                type: "date"
            }
        ]
    }

    function getFieldValue(item) {
        const fieldArray = item.field.split(".");

        let value = contact;

        for (let i = 0; i < fieldArray.length; i++) {
            const f = fieldArray[i];
            
            if(value[f]) {
                value = value[f];
            } else {
                value = "N/A";
                break;
            }
        }

        switch (item.type) {
            case "date":
                return moment(value).format("DD/MM/YYYY");
            case "boolean":
                return value ? "Ativo" : "Inativo";
            default:
                return value;
        }
    }

    return (
        <Div sx={{m: theme => theme.spacing(-2.5, -3)}}>
            <CardHeader
                title={field == "unit" ? `Unidade ${contact.id}` : `Ticket ${contact.id}`}
                action={
                    <IconButton onClick={onClose}><CloseIcon/></IconButton>
                }
            />
            <List disablePadding>
                {
                    fields[field].map((fi, index) => (
                        <>
                            <ListItem sx={{px: 4}}>
                                <ListItemAvatar sx={{minWidth: 66}}>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            height: 48,
                                            width: 48,
                                            bgcolor: theme => alpha(theme.palette.primary.main, .15)
                                        }}
                                    >
                                        <CorporateFareIcon sx={{color: 'primary.light'}}/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>{fi.label}</Typography>}
                                    secondary={<Typography variant={"h5"} mb={0}>{getFieldValue(fi)}</Typography>}
                                />
                            </ListItem>
                            
                            {
                                index == fields[field].length - 1 && <Divider component={"li"} />
                            }
                        </>
                    ))
                }

                {/* <ListItem sx={{px: 4}}>
                    <ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <CorporateFareIcon sx={{color: 'primary.light'}}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Nome</Typography>}
                        secondary={<Typography variant={"h5"} mb={0}>{contact?.name}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"}/> */}
            </List>
        </Div>
    );
};

export default ContactDetail;
