import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLazyFetchIssuesQuery } from "../../features/serviceOrderFields-slice";
import { FieldSkeleton } from "app/components/CustomInputs/Skeleton";

function IssueSoField({ formik, handlePreviewData, ...props }) {
  const [trigger, { data, isFetching, isLoading }] = useLazyFetchIssuesQuery();

  useEffect(() => {
    if (!formik.values.family) return;
    trigger(formik.values.family);
  }, [formik.values.family]);

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        multiple
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        disabled={formik.values.model === undefined}
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          const options = value.map((option) => option.id);
          formik.setFieldValue("issue", options);
          // formik.setFieldValue("issue", options !== null ? options : undefined);
          const optionsPreview = value.map((options) => options.name);
          handlePreviewData("issue", optionsPreview.join(", "));
        }}
        value={
          data?.data?.filter((option) =>
            formik.values.issue?.includes(option.id)
          ) || []
        }
        renderOption={(props, option, { selected }) => {
          const { ...optionsProps } = props;

          return <li {...optionsProps}>{option.name}</li>;
        }}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"issue"}
            name={"issue"}
            label={"Ocorrências"}
            error={formik.touched.issue && Boolean(formik.errors.issue)}
            helperText={formik.touched.issue && formik.errors.issue}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default IssueSoField;
