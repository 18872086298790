import React, { useEffect } from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import {
  Pagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Stack,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useState } from "react";
import ProjectsList from "./components/ProjectsList";
import { useParams, useNavigate } from "react-router-dom";
import JumboSearch from "@jumbo/components/JumboSearch";
import Div from "@jumbo/shared/Div";
import SortIcon from "@mui/icons-material/Sort";
import { appTheme } from "app/themes/app/theme";
import { Content } from "./components/style";
import api from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { CLEAN_SERVICE_ORDERS_STORE } from "app/redux/modules/ServiceOrders/constants";
import ChamadosSkeleton from "./components/ChamadosSkeleton";

const ChamadosTecnicos = ({ companyId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [order, setOrder] = useState("asc");
  const [paramsOrder, setParams] = useState({});
  const [continueFetching, setContinueFetching] = useState(true);
  const [novoChamado, setNovoChamado] = useState(false);
  const [serviceOrdes, setServiceOrdes] = useState([]);
  const { auth } = useSelector((state) => state);
  const { statusOs } = useParams();
  const [companyIdFilter, setCompanyIdFilter] = useState([
    { id: 0, name: "Ver Todos" },
  ]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const status = [
    {
      id: 1,
      name: "Aberto",
    },
    {
      id: 2,
      name: "A Caminho",
    },
    {
      id: 3,
      name: "Chegou",
    },
    {
      id: 4,
      name: "Resolvido",
    },
    {
      id: 5,
      name: "Pendente",
    },
    {
      id: 6,
      name: "Cancelado",
    },
  ];

  const fields = [
    { name: "Ordem de Serviço", value: "so" },
    { name: "Nota Fiscal", value: "invoice" },
    { name: "Data de Abertura", value: "created_at" },
    { name: "Data de Resolução", value: "resolution_at" },
    { name: "Descrição", value: "description" },
    /* { name: "Tipo de Serviço", value: "" }, */
  ];

  const { setActiveLayout } = useJumboApp();
  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  /* const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }; */

  async function getOrders() {
    setLoading(true);

    try {
      const params = {
        ...paramsOrder,
        orderBy: paramsOrder.orderBy ? `${paramsOrder.orderBy},${order}` : "",
        status_service_order_id:
          statusOs &&
          status.find(
            (item) =>
              item.name.toLocaleLowerCase() === statusOs.replace("-", " ")
          )?.id,
        company_id: selectedCompanyId === 0 ? null : selectedCompanyId,
      };

      const { data } = await api.get(
        `${
          companyId ? `companies/${companyId}/service-orders` : "service-orders"
        }?page=${page}`,
        {
          params,
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      setServiceOrdes(data.data);
      setTotalPages(data.meta.last_page);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  async function getCompaniesForFilter() {
    try {
      const response = await api.get("companies/has-open-tickets", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setCompanyIdFilter([{ id: 0, name: "Ver Todos" }, ...response.data]);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCompaniesForFilter();
  }, []);

  React.useEffect(() => {
    getOrders();
  }, [page, paramsOrder, order, statusOs, selectedCompanyId]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function toggleOrder() {
    setOrder(order == "asc" ? "desc" : "asc");
  }

  return (
    <Content>
      {/* <ModalNovoChamado
                open={novoChamado}
                setOpen={setNovoChamado}
            /> */}

      <Div>
        <Div className="flex min-[1650px]:flex-row flex-col py-4 justify-between">
          <Typography className="flex items-center" variant={"h2"}>
            Lista de Ordens de Serviço
          </Typography>

          {!companyId && (
            <Div className="flex flex-wrap xl:flex-row items-center">
              <Div className="filter-item sm:flex hidden">
                <FormControl
                  className="m-2 2xl:min-w-[350px] min-w-[280px]"
                  sx={{ minWidth: 200 }}
                  size="small"
                >
                  <Autocomplete
                    filterSelectedOptions={true}
                    options={companyIdFilter}
                    fullWidth
                    InputProps={{
                      autoComplete: "off",
                    }}
                    onChange={(event, value) =>
                      setSelectedCompanyId(value !== null ? value.id : "")
                    }
                    defaultValue={companyIdFilter.find(
                      (option) => option.id === selectedCompanyId
                    )}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        className="p-0"
                        {...params}
                        fullWidth
                        id={"companyIdFilter"}
                        name={"companyIdFilter"}
                        label={"Filtrar por empresa"}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            paddingTop: 0,
                            paddingBottom: 0,
                            height: 38,
                          }, // Remove padding by setting it to 0
                        }}
                        InputLabelProps={{
                          style: {
                            lineHeight: "0.5rem", // Change the color of the label
                            overflow: "visible", // Make the label text bold
                            // Add more CSS styles as needed
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Div>
              <Div className="filter-item sm:flex hidden">
                <FormControl
                  className="m-2 2xl:min-w-[200px] min-w-[142px]"
                  sx={{ minWidth: 200 }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-status">Status</InputLabel>
                  <Select
                    labelId="demo-select-small-status"
                    id="demo-select-small-status"
                    value={paramsOrder.status_service_order_id}
                    label="Status"
                    onChange={(event) => {
                      navigate(`/os/${event.target.value}`);
                    }}
                  >
                    <MenuItem value="">
                      <em>Selecione...</em>
                    </MenuItem>
                    <MenuItem name="aberto" value="aberto">
                      Aberto
                    </MenuItem>
                    <MenuItem name="a-caminho" value="a-caminho">
                      A caminho
                    </MenuItem>
                    <MenuItem name="chegou" value="chegou">
                      Chegou
                    </MenuItem>
                    <MenuItem name="resolvido" value="resolvido">
                      Resolvido
                    </MenuItem>
                    <MenuItem name="pendente" value="pendente">
                      Pendente
                    </MenuItem>
                    <MenuItem name="cancelado" value="cancelado">
                      Cancelado
                    </MenuItem>
                  </Select>
                </FormControl>
              </Div>

              <Div className="filter-item sm:flex hidden mr-2">
                <FormControl
                  className="m-2 2xl:min-w-[200px] min-w-[142px]"
                  sx={{ minWidth: 250 }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-order">
                    Ordenar por
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-order"
                    id="demo-select-small-order"
                    value={paramsOrder.orderBy}
                    label="Order"
                    onChange={(event) => {
                      setParams({
                        ...paramsOrder,
                        orderBy: event.target.value,
                      });
                    }}
                  >
                    <MenuItem key="0" value="">
                      <em>Selecione...</em>
                    </MenuItem>
                    {fields.map((field) => (
                      <MenuItem key={field.value} value={field.value}>
                        {field.name}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value="equipamento_nome">Máquina</MenuItem>
                                            <MenuItem value="tempo_chamado">Tempo</MenuItem>
                                            <MenuItem value="tecnico_id">Técnico</MenuItem>
                                            <MenuItem value="created_at">Data de Abertura</MenuItem>
                                            <MenuItem value="client">Solicitante</MenuItem>
                                            <MenuItem value="empresa">Empresa</MenuItem>
                                            <MenuItem value="ocorrencia_reportada">Ocorrência</MenuItem> */}
                  </Select>
                </FormControl>
                <Button
                  sx={{
                    m: 1,
                    borderColor: appTheme.palette.red100,
                    color: appTheme.palette.red100,
                    "&:hover": {
                      borderColor: appTheme.palette.red200,
                    },
                  }}
                  variant="outlined"
                  onClick={toggleOrder}
                >
                  <SortIcon
                    sx={{
                      transform: `rotateX(${order == "desc" ? 0 : 180}deg)`,
                    }}
                  />
                </Button>
              </Div>

              <FormControl className="min-[1500px]:w-[212px] w-[130px]">
                <JumboSearch
                  onChange={(e) => {
                    setContinueFetching(false);
                    setPage(1);
                    setContinueFetching(true);
                    setParams({
                      ...paramsOrder,
                      s: e,
                    });
                  }}
                  placeholder="Pesquisar"
                  sx={{
                    display: { md: "block" },
                    flex: 1,
                    bgcolor: "#FFF",
                  }}
                />
              </FormControl>

              <Button
                data-testid="btn-new-so"
                id="btn-new-so"
                variant="contained"
                color="success"
                sx={{
                  ml: 3,
                }}
                onClick={() => {
                  dispatch({ type: CLEAN_SERVICE_ORDERS_STORE });
                  navigate("/os/novo");
                }}
              >
                Novo
              </Button>
            </Div>
          )}
        </Div>
      </Div>

      {loading ? (
        <ChamadosSkeleton />
      ) : (
        <>
          <Stack data-testid={`service-order-list`} style={{ marginTop: 10 }}>
            <ProjectsList data={serviceOrdes} />
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => setPage(value)}
              shape="rounded"
              variant="outlined"
              color="secondary"
            />
          </Stack>
        </>
      )}
    </Content>
  );
};

export default ChamadosTecnicos;
