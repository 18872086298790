import React from "react";
import {
  Card,
  Grid,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import LineChartSales from "./LineChartSales";
import ChartOrderRevenue from "./ChartOrderRevenue";
import { dataItems, menuItems } from "./data";
import JumboContent from "@jumbo/components/JumboContent";
import Div from "@jumbo/shared/Div";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import RenderPivoData from "./RenderPivoData";
import { useFetchSinglePivoQuery } from "app/pages/Pivos/features/pivos/pivos-slice";
import { useParams } from "react-router-dom";

const HeaderChart = () => {
  const { id } = useParams();
  const { data, isFetching, isLoading } = useFetchSinglePivoQuery(id);
  const theme = useTheme();

  if (isLoading || isFetching) {
    return (
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        sx={{ p: (theme) => theme.spacing(0, 1, 2) }}
      >
        <Skeleton
          variant="rectangular"
          style={{
            borderRadius: 15,
            height: "450px",
            width: "100%",
          }}
        />
      </Stack>
    );
  }

  return (
    <Card>
      <JumboContent
        title={
          <Typography variant={"h3"} color={"common.white"}>
            Pivô #{data.serial_number}
          </Typography>
        }
        bgColor={"#3BD2A2"}
        sx={{ color: "common.white" }}
      >
        <RenderPivoData />
      </JumboContent>
      <JumboContent>
        <Grid container>
          <Grid
            sx={{ py: 2, borderRight: "1px solid #f5f7fa" }}
            item
            xs={12}
            lg={6}
          >
            <Div
              sx={{
                p: 5,
              }}
            >
              <Typography variant={"h4"} color={"text.secondary"} gutterBottom>
                Detalhes do pivo
              </Typography>
              <List>
                <ListItem>
                  Número de série:{" "}
                  <Typography sx={{ mx: 1 }} color={"text.secondary"}>
                    {data.serial_number}
                  </Typography>
                </ListItem>
                <ListItem>
                  Número da nota fiscal:
                  <Typography sx={{ mx: 1 }} color={"text.secondary"}>
                    {data.fiscal_number}
                  </Typography>
                </ListItem>

                <ListItem>
                  Empresa:
                  <Typography sx={{ mx: 1 }} color={"text.secondary"}>
                    {data.unit?.company?.name}
                  </Typography>
                </ListItem>
              </List>
            </Div>
          </Grid>
          <Grid sx={{ py: 2 }} item xs={12} lg={6}>
            <Div>
              <LineChartSales />
            </Div>
          </Grid>
        </Grid>
      </JumboContent>
    </Card>
  );
};

export default HeaderChart;
