//PRODUCT_MODEL Types

export const GET_PRODUCT_MODEL_REQUEST = "GET_PRODUCT_MODEL_REQUEST";
export const GET_PRODUCT_MODEL_FAILURE = "GET_PRODUCT_MODEL_FAILURE";
export const GET_PRODUCT_MODEL_SUCCESS = "GET_PRODUCT_MODEL_SUCCESS";

export const GET_SINGLE_PRODUCT_MODEL_REQUEST = 'get-single-PRODUCT_MODEL-request'
export const GET_SINGLE_PRODUCT_MODEL_FAILURE = 'get-single-PRODUCT_MODEL-failure';
export const GET_SINGLE_PRODUCT_MODEL_SUCCESS = 'get-single-PRODUCT_MODEL-success';

export const POST_PRODUCT_MODEL_REQUEST = "POST_PRODUCT_MODEL_REQUEST";
export const POST_PRODUCT_MODEL_FAILURE = "post-PRODUCT_MODEL-failure";
export const POST_PRODUCT_MODEL_SUCCESS = "post-PRODUCT_MODEL-success";

export const PUT_PRODUCT_MODEL_REQUEST = "PUT_PRODUCT_MODEL_REQUEST";
export const PUT_PRODUCT_MODEL_FAILURE = "put-PRODUCT_MODEL-failure";
export const PUT_PRODUCT_MODEL_SUCCESS = "put-PRODUCT_MODEL-success";

export const DELETE_PRODUCT_MODEL_REQUEST = "DELETE_PRODUCT_MODEL_REQUEST";
export const DELETE_PRODUCT_MODEL_FAILURE = "delete-PRODUCT_MODEL-failure";
export const DELETE_PRODUCT_MODEL_SUCCESS = "delete-PRODUCT_MODEL-success";

export const CLEAN_PRODUCT_MODEL_STORE = "CLEAN_PRODUCT_MODEL_STORE";