import React, { useCallback } from "react";
import { PIVO_ACTIONS } from "../Pivos/utils/constants";
import { FILE_MANAGER_CONSTANTS } from "./helpers/constants";

const FileManagerContext = React.createContext(null);

function init(initialValue) {
  return {
    searchKeyWord: initialValue.s,
    modalIsOpen: initialValue.modalIsOpen,
    modalCategoryIsOpen: initialValue.modalCategoryIsOpen,
  };
}

function appReducer(state, action) {
  switch (action.type) {
    case FILE_MANAGER_CONSTANTS.KEY_WORD:
      return {
        ...state,
        searchKeyWord: action.payload,
      };
    case FILE_MANAGER_CONSTANTS.MODAL_OPEN:
      return {
        ...state,
        modalIsOpen: action.payload,
      };
    case FILE_MANAGER_CONSTANTS.OPEN_MODAL_CATEGORY:
      return {
        ...state,
        modalCategoryIsOpen: action.payload,
      };

    default:
      return state;
  }
}

const FileManagerProvider = ({ children }) => {
  const [app, setApp] = React.useReducer(
    appReducer,
    {
      searchKeyWord: 1,
      modalIsOpen: false,
      modalCategoryIsOpen: false,
    },
    init
  );
  React.useEffect(() => {
    console.log(app);
  }, [app]);
  const setKeyWord = useCallback(
    (value) => {
      setApp({ type: FILE_MANAGER_CONSTANTS.KEY_WORD, payload: value });
    },
    [setApp]
  );

  const setOpenModal = useCallback(
    (value) => {
      setApp({ type: FILE_MANAGER_CONSTANTS.MODAL_OPEN, payload: value });
    },
    [setApp]
  );
  const setOpenModalCategory = useCallback(
    (value) => {
      setApp({
        type: FILE_MANAGER_CONSTANTS.OPEN_MODAL_CATEGORY,
        payload: value,
      });
    },
    [setApp]
  );

  const contextValue = React.useMemo(
    () => ({
      ...app,
      setOpenModalCategory,
      setKeyWord,
      setOpenModal,
    }),
    [app, setKeyWord, setOpenModal, setOpenModalCategory]
  );

  return (
    <FileManagerContext.Provider value={contextValue}>
      {children}
    </FileManagerContext.Provider>
  );
};

export { FileManagerContext };
export default FileManagerProvider;
