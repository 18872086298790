//ADMINISTRATORS Types

export const GET_ADMINISTRATORS_REQUEST = "GET_ADMINISTRATORS_REQUEST";
export const GET_ADMINISTRATORS_FAILURE = "GET_ADMINISTRATORS_FAILURE";
export const GET_ADMINISTRATORS_SUCCESS = "GET_ADMINISTRATORS_SUCCESS";

export const GET_SINGLE_ADMINISTRATORS_REQUEST = 'get-single-ADMINISTRATORS-request'
export const GET_SINGLE_ADMINISTRATORS_FAILURE = 'get-single-ADMINISTRATORS-failure';
export const GET_SINGLE_ADMINISTRATORS_SUCCESS = 'get-single-ADMINISTRATORS-success';

export const POST_ADMINISTRATORS_REQUEST = "POST_ADMINISTRATORS_REQUEST";
export const POST_ADMINISTRATORS_FAILURE = "post-ADMINISTRATORS-failure";
export const POST_ADMINISTRATORS_SUCCESS = "post-ADMINISTRATORS-success";

export const PUT_ADMINISTRATORS_REQUEST = "PUT_ADMINISTRATORS_REQUEST";
export const PUT_ADMINISTRATORS_FAILURE = "put-ADMINISTRATORS-failure";
export const PUT_ADMINISTRATORS_SUCCESS = "put-ADMINISTRATORS-success";

export const DELETE_ADMINISTRATORS_REQUEST = "DELETE_ADMINISTRATORS_REQUEST";
export const DELETE_ADMINISTRATORS_FAILURE = "delete-ADMINISTRATORS-failure";
export const DELETE_ADMINISTRATORS_SUCCESS = "delete-ADMINISTRATORS-success";

export const CLEAN_ADMINISTRATORS_STORE = "CLEAN_ADMINISTRATORS_STORE";