import axios from "axios";
import {
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAILURE,
  GET_EMAIL_REQUEST,
} from "./constants";

import api from "app/services/config";

export const getEmail = (email) => async (dispatch, getState) => {
    dispatch({ type: GET_EMAIL_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.get(`users/has-email?email=${email}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_EMAIL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_EMAIL_FAILURE,
        payload: error.message,
      });
    }
  };
