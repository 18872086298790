import React, {useEffect, useState} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';

import moment from 'moment';
import 'moment/locale/pt-br';

import {calendarData, cultures} from "./data";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {Autocomplete, Backdrop, Button, CircularProgress, Divider, Fade, List, ListItem, ListItemText, MenuItem, Modal, Select, Stack, TextField} from "@mui/material";
import CalendarWrapper from "./CalendarWrapper";
import api from "../../services/config";
import Div from '@jumbo/shared/Div/Div';
import { useDispatch, useSelector } from 'react-redux';
import { TimelineItem } from '@mui/lab';
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import JumboContent from '@jumbo/components/JumboContent/JumboContent';
import { appTheme } from 'app/themes/app/theme';
import { useNavigate } from 'react-router-dom';
import { asyncServiceOrdersActions } from 'app/redux/reducers';

const today = new Date();
const currentYear = today.getFullYear();

const localizer = momentLocalizer(moment);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    maxHeight: "95%"
};

const Agenda = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {auth} = useSelector(state => state);

    const [tecs, setTecs] = useState([]);

    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    
    const [tecId, setTecId] = useState(null);
    const [period, setPeriod] = useState(moment().format("YYYY-MM"));
    const [event, setEvent] = useState(null);

    async function fetchSchedule() {
        setTasks([]);
        setLoading(true);
        try {
            const { data } = await api.get(`users/${tecId}/tasks-period`, {
                params: {
                    type : "MONTH",
                    period
                }
            });

            console.log("DATA SCHEDULE > > ", data);
            setTasks(data.map(task => {
                return {
                    ...task,
                    title: task.note,
                    start: new Date(task.scheduled_at),
                    end: new Date(task.end_at)
                    
                };
            }));
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    async function fetchTecs(s) {
        try {
            const {data} = await api.get(
                "users",
                {
                    params: {
                        s,
                        status: 1,
                        role_id: 3
                    },
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                }
            );

            console.log("TECS > > ", data)
            setTecs(data.data);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        fetchTecs("");
    }, []);

    useEffect(() => {
        if(tecId) {
            fetchSchedule();
        }
    }, [tecId, period]);

    async function modifyServiceOrder(id) {
        navigate(`/os/editar/${id}`);     
    }

    return (
        <React.Fragment>
            <Typography variant={"h1"} mb={3}>Agenda de Técnico</Typography>
            <Typography variant={"h5"} color={"text.primary"}>Selecione o Técnico</Typography>
            <FormControl fullWidth sx={{mb: 2}}>
                <Autocomplete
                    disablePortal
                    options={tecs}
                    getOptionLabel={tec => `${tec.profile.name} ${tec.profile.last_name}`}
                    fullWidth
                    noOptionsText="Pesquisar"
                    onChange={(event, newValue) => {
                        if(newValue)
                            setTecId(newValue.id);
                        else
                            setTecId(null);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            id="tecs"
                            name="tecs"
                            label="Técnico"
                            /* value={formik.values.client}
                            error={formik.touched.client && Boolean(formik.errors.client)}
                            helperText={formik.touched.client && formik.errors.client} */
                            onChange={e => {
                                fetchTecs(e.target.value);
                            }}
                        />
                    )}
                />
            </FormControl>

            {
                event && (
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={event != null}
                        onClose={() => setEvent(null)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={event != null}>
                            <Div sx={style}>
                                <JumboCardQuick noWrapper>
                                    <JumboContent
                                        bgColor={[appTheme.palette.principal, appTheme.palette.focus]}
                                        sx={{color: "common.white"}}
                                    >
                                        <Stack alignItems={"center"} pt={4} mb={2}>
                                            <Typography textAlign={{ xs: "center", md: "left" }} variant={"h1"} color={"common.white"}>
                                                OS: #{event.service_order.so}
                                            </Typography>
                                        </Stack>
                                    </JumboContent>
                                    <JumboContent sx={{p: 3}}>
                                        <Typography>
                                            Tarefa Agendada de {moment(event.scheduled_at).format("DD/MM/YYYY HH:mm")} {' '}
                                            à {moment(event.scheduled_at).format("DD/MM/YYYY") == moment(event.end_at).format("DD/MM/YYYY") ? moment(event.end_at).format("HH:mm") : moment(event.end_at).format("DD/MM/YYYY HH:mm")}
                                        </Typography>

                                        <Typography>
                                            Cliente: {event.service_order.responsible_user.profile.name} {event.service_order.responsible_user.profile.last_name}
                                        </Typography>

                                        <Typography>
                                            Endereço: {event.service_order.unit.address}
                                        </Typography>

                                        <Button
                                            color="error"
                                            variant="contained"
                                            sx={{
                                                mt: 5,
                                                width: "100%"
                                            }}
                                            onClick={() => {
                                                modifyServiceOrder(event.service_order.id)
                                                /* dispatch(asyncServiceOrdersActions.get(event.service_order.id));
                                                navigate(`/chamados/reabrir/${event.service_order.id}`) */
                                            }}
                                        >
                                            Modificar OS
                                        </Button>
                                    </JumboContent>
                                </JumboCardQuick>
                            </Div>
                            {/* <Div sx={style}>
                                <TimelineItem
                                    sx={{
                                        '&::before': {
                                            display: 'none'
                                        }
                                    }}
                                >
                                    <List
                                        disablePadding
                                        sx={{ width: "100%" }}
                                    >
                                        <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                                            <ListItemText primary="OS" secondary={`#${event.service_order.so}`} />
                                        </ListItem>
                                        <Divider component="li"/>
                                    </List>
                                </TimelineItem>
                            </Div> */}

                            {/* <Div sx={style}>
                                <Stack>
                                    <Typography sx={{ p: "5px" }}>
                                        OS: #{event.service_order.so}
                                    </Typography>

                                    <Typography sx={{ p: "5px" }}>
                                        Tarefa Agendada de {moment(event.scheduled_at).format("DD/MM/YYYY HH:mm")} {' '}
                                        à {moment(event.scheduled_at).format("DD/MM/YYYY") == moment(event.end_at).format("DD/MM/YYYY") ? moment(event.end_at).format("HH:mm") : moment(event.end_at).format("DD/MM/YYYY HH:mm")}
                                    </Typography>
                                </Stack>

                                <Button color="error" variant="contained">
                                    Tirar técnico da tarefa
                                </Button>
                            </Div> */}
                        </Fade>
                    </Modal>
                )
            }

            <CalendarWrapper>
                {
                    loading && (
                        <Div
                            style={{
                                background: "rgba(0,0,0,.5)",
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                zIndex: 999999,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <CircularProgress />
                        </Div>
                    )
                }

                <Calendar
                    localizer={localizer}
                    events={tasks}
                    step={60}
                    min={new Date(0, 0, 0, 8, 0, 0, 0)}
                    max={new Date(0, 0, 0, 23, 0, 0, 0)}
                    showMultiDayTimes
                    culture="pt-br"
                    messages={{
                        next: "Próximo",
                        previous: "Anterior",
                        today: "Hoje",
                        month: "Mês",
                        week: "Semana",
                        day: "Dia",
                        noEventsInRange: "Nenhuma tarefa nesse dia"
                    }}
                    onNavigate={date => setPeriod(moment(date).format("YYYY-MM"))}
                    onSelectEvent={setEvent}
                    defaultDate={new Date()}
                    style={{height: 600}}
                />
            </CalendarWrapper>
        </React.Fragment>
    );
};

export default Agenda;
