import React from "react";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import NotificationBirthday from "./NotificationBirthday";
import NotificationInvitation from "./NotificationInvitation";
import NotificationSharedPost from "./NotificationSharedPost";
import NotificationPost from "./NotificationPost";
import { notifications } from "./fake-data";
import List from "@mui/material/List";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import { CardActions, ThemeProvider } from "@mui/material";
import { useJumboHeaderTheme, useJumboTheme } from "@jumbo/hooks";
import { useEffect } from "react";
import api from "app/services/config";
import { useState } from "react";
import { asuncNotificationsActions } from "app/redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NotificationsDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { theme } = useJumboTheme();
  const { headerTheme } = useJumboHeaderTheme();
  const { auth, tickets, notifications } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <ThemeProvider theme={headerTheme}>
            <JumboIconButton
              badge={{ content: notifications?.data?.length }}
              elevation={25}
            >
              <NotificationImportantOutlinedIcon sx={{ fontSize: "1rem" }} />
            </JumboIconButton>
          </ThemeProvider>
        }
        disableInsideClick
      >
        <Div sx={{ width: 360, maxWidth: "100%" }}>
          <CardHeader title={"Chamados em Aberto"} />
          <List disablePadding>
            {notifications?.data?.map((item, index) => {
              return <NotificationInvitation key={index} item={item} />;
            })}
          </List>
          <Divider />
          {more && (
            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                sx={{
                  textTransform: "none",
                  fontWeight: "normal",
                  "&:hover": { bgcolor: "transparent" },
                }}
                size={"small"}
                variant="text"
                endIcon={!loading ? <ArrowForwardIcon /> : <></>}
                disableRipple
                disabled={loading}
                onClick={() => navigate("/tickets")}
              >
                Veja todos os tickets...{" "}
              </Button>
            </CardActions>
          )}
        </Div>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default NotificationsDropdown;
