import {
  GET_FAMILY_ISSUES_REQUEST,
  GET_FAMILY_ISSUES_SUCCESS,
  GET_FAMILY_ISSUES_FAILURE,

  CLEAN_FAMILY_ISSUES_STORE,
  
  DELETE_FAMILY_ISSUES_REQUEST,
  DELETE_FAMILY_ISSUES_SUCCESS,
  DELETE_FAMILY_ISSUES_FAILURE,

  POST_FAMILY_ISSUES_REQUEST,
  POST_FAMILY_ISSUES_FAILURE,
  POST_FAMILY_ISSUES_SUCCESS,

  PUT_FAMILY_ISSUES_REQUEST,
  PUT_FAMILY_ISSUES_FAILURE,
  PUT_FAMILY_ISSUES_SUCCESS,
  SELECT_AN_FAMILY_ISSUE,
} from "./constants";

const initialState = {
  data: [],
  selectedIssues: [],
  loading: false,
  error: null,
};

export default function familyIssues(state = initialState, action) {
  switch (action.type) {
    case GET_FAMILY_ISSUES_REQUEST:
    case DELETE_FAMILY_ISSUES_REQUEST:
    case POST_FAMILY_ISSUES_REQUEST:
    case PUT_FAMILY_ISSUES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_FAMILY_ISSUES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_FAMILY_ISSUES_SUCCESS:
    case POST_FAMILY_ISSUES_SUCCESS:
    case PUT_FAMILY_ISSUES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_FAMILY_ISSUES_FAILURE:
    case POST_FAMILY_ISSUES_FAILURE:
    case DELETE_FAMILY_ISSUES_FAILURE:
    case PUT_FAMILY_ISSUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECT_AN_FAMILY_ISSUE:
      return {
        ...state,
        selectedIssues: action.payload,
      }
    case CLEAN_FAMILY_ISSUES_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        selectedIssues: [],
        error: null,
      };
    default:
      return state;
  }
}
