//MACROS Types

export const GET_MACROS_REQUEST = "GET_MACROS_REQUEST";
export const GET_MACROS_FAILURE = "GET_MACROS_FAILURE";
export const GET_MACROS_SUCCESS = "GET_MACROS_SUCCESS";

export const GET_SINGLE_MACROS_REQUEST = 'get-single-MACROS-request'
export const GET_SINGLE_MACROS_FAILURE = 'get-single-MACROS-failure';
export const GET_SINGLE_MACROS_SUCCESS = 'get-single-MACROS-success';

export const POST_MACROS_REQUEST = "POST_MACROS_REQUEST";
export const POST_MACROS_FAILURE = "post-MACROS-failure";
export const POST_MACROS_SUCCESS = "post-MACROS-success";

export const PUT_MACROS_REQUEST = "PUT_MACROS_REQUEST";
export const PUT_MACROS_FAILURE = "put-MACROS-failure";
export const PUT_MACROS_SUCCESS = "put-MACROS-success";

export const DELETE_MACROS_REQUEST = "DELETE_MACROS_REQUEST";
export const DELETE_MACROS_FAILURE = "delete-MACROS-failure";
export const DELETE_MACROS_SUCCESS = "delete-MACROS-success";

export const CLEAN_MACROS_STORE = "CLEAN_MACROS_STORE";