import {
  CLEAN_CITIES_STORE,
  GET_CITIES_REQUEST,
  GET_CITIES_FAILURE,
  GET_CITIES_SUCCESS, 
  
} from "./constants";
 
const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function cities(state = initialState, action) {
  switch (action.type) {
    case GET_CITIES_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
      };
      case GET_CITIES_REQUEST:    
      return {
        ...state,
        loading: true,
        error: null,
      };
      case GET_CITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  
    case CLEAN_CITIES_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
