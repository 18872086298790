import React from "react";
import { notificationIcons } from "./notificationIcons";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { Link, Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import Span from "@jumbo/shared/Span";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const NotificationInvitation = ({ item }) => {
  const navigate = useNavigate();

  return (
    <ListItemButton
      component={"li"}
      alignItems={"flex-start"}
      onClick={() =>
        navigate(`/tickets/responder/${item.id}`, { replace: true })
      }
    >
      <ListItemAvatar>
        <Avatar
          src={`${process.env.REACT_APP_PHOTO_PATH}${item.responsible_user.profile.avatar}`}
        />
      </ListItemAvatar>
      <ListItemText>
        <Link underline={"none"} href="#/">
          {item.responsible_user.profile.name}
        </Link>{" "}
        criou um ticket de atendimento!
        <Typography
          component="span"
          sx={{
            display: "flex",
            fontSize: "90%",
            mt: 0.5,
          }}
        >
          {notificationIcons["INVITATION"]}
          <Span sx={{ color: "text.secondary", ml: 1 }}>
            {moment(item.updated_at).format("DD/MM/YYYY HH:mm")}
          </Span>
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};

export default NotificationInvitation;
