import { FormControl } from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";

function FinalDateSo({ formik, handlePreviewData }) {
  return (
    <FormControl sx={{ mb: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          data-testid={"input-end"}
          disablePast
          disabled={formik.values.time === ""}
          id="end"
          name="end"
          label="Final"
          format="DD/MM/YYYY"
          minDate={formik.values.scheduled_at}
          value={formik.values.end}
          onChange={(newValue) => {
            if (newValue && newValue.format("DD/MM/YYYY") != "Invalid Date") {
              formik.setFieldValue("end", newValue, true);
              const date = newValue.format("DD/MM/YYYY");
              formik.setFieldValue("time_end", "");

              handlePreviewData((previewData) => ({
                ...previewData,
                endAt: [date, previewData.endAt[1]],
              }));
            }
          }}
          slotProps={{
            textField: {
              variant: "outlined",
              error: formik.touched.end && Boolean(formik.errors.end),
              helperText: formik.touched.end && formik.errors.end,
              onBlur: () => {
                if (formik.values["end"]) {
                  const start = formik.values["scheduled_at"];
                  const end = formik.values["end"];

                  const diff = end.diff(start, "d");

                  if (diff < 0) {
                    formik.setFieldValue("end", start);

                    handlePreviewData((previewData) => ({
                      ...previewData,
                      endAt: [start.format("DD/MM/YYYY"), previewData.endAt[1]],
                    }));
                  }
                }
              },
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
}

export default FinalDateSo;
