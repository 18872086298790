import {
  asyncFilterActions,
  asyncFamilySolutionsActions,
  asyncProductModelActions,
  asyncProductFamiliesActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING } from "app/components/DynamicViewGenerator/constants";
import { CLEAN_PRODUCT_MODEL_STORE } from "app/redux/modules/Products/productModel/constants";
import * as Yup from "yup";
import { CLEAN_FAMILY_SOLUTIONS_STORE } from "app/redux/modules/Products/FamilySolutions/constants";

const FamilySolutions = () => {
  const dispatch = useDispatch();
  const { familySolutions, productFamilies, filterProductModel, auth } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);
  const [categoryFilter, setCategoryFilter] = React.useState([]);

  const startPage = async () => {
     dispatch(asyncFamilySolutionsActions.get("asc", "id"))
     dispatch(asyncProductFamiliesActions.getProductFamilies("asc", "name",1,1,"",true));
     dispatch(asyncFilterActions.getProductModelsFilter())

  }

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_FAMILY_SOLUTIONS_STORE });
      dispatch({ type: CLEAN_PRODUCT_MODEL_STORE });

    }
  }, [dispatch]);
 
  useEffect(() => {
    if (productFamilies.data) {
      setCategoryFilter(
        productFamilies.data.data && productFamilies.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [productFamilies]);


  useEffect(() => {
    if (productFamilies.data) {
      setNewSelectOptions(
        productFamilies.data.data && productFamilies.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [productFamilies]);


  const dataLabels = [
    {
      id: "Id",
      name: "Nome",
      description: "Descrição",
      product_family_id: "Família do Produto",
      created_at: "Data de Criação",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      description: DATA_TYPE_STRING,
      status: DATA_TYPE_CHIP,
      created_at: DATA_TYPE_DATE,
      product_family_id: {
        parent: "product_family",
        name: "name",
      },
    },
  ];

  const formFieldsType = [
    {
      type: "text",
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "text",
      value: "description",
      label: "Descrição",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },    
    {
      type: "select",
      value: "product_family_id",
      label: "Família do Produto",
      options: newSelectOptions,
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: "status",
      value: "status",
      label: "Status",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },
  ];

  const pageConfig = {
    title: "Soluções de Família",
    newText: "Nova solução",
    searchText: "Pesquisar",
    data: familySolutions.data,
    loading: familySolutions.loading,
    category: categoryFilter,
    actions: {
      delete: asyncFamilySolutionsActions.delete,
      edit: asyncFamilySolutionsActions.edit,
      get: asyncFamilySolutionsActions.get,
      search: asyncFamilySolutionsActions.get,
      create: asyncFamilySolutionsActions.create,
      clean: { type: CLEAN_FAMILY_SOLUTIONS_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default FamilySolutions;
