import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFetchReducersQuery } from "../../features/serviceOrderFields-slice";
import { useDispatch, useSelector } from "react-redux";
import { FieldSkeleton } from "app/pages/Tickets/Skeleton";
import { setSelectedReducers } from "../../features/so-slice";

function ReducerField({ formik, ...props }) {
  const { pivot_id, reducers_ids } = useSelector((state) => state.soSlice);

  const dispatch = useDispatch();
  const { data, isFetching, isLoading, refetch } = useFetchReducersQuery(
    formik.values.pivot_id
      ? formik.values.pivot_id
      : {
          skip: true,
        }
  );
  const newAllOption = { id: "all", serial_number: "Todos" };
  const selectData = data?.data.length > 0 ? [newAllOption, ...data.data] : [];

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        multiple
        filterSelectedOptions={true}
        options={selectData || []}
        loading={isLoading || isFetching}
        fullWidth
        onChange={(e, newValue) => {
          if (newValue.length === 0) {
            dispatch(setSelectedReducers([]));
            formik.setFieldValue("reducers_ids", []);
            return;
          }

          if (newValue.find((value) => value.id === "all")) {
            const allReducers = selectData.map((option) => option.id);
            dispatch(
              setSelectedReducers(
                selectData.filter((option) => option.id !== "all")
              )
            );
            formik.setFieldValue(
              "reducers_ids",
              allReducers.filter((id) => id !== "all")
            );
            return;
          }

          const valueFormated = newValue.map((value) => value.id);
          dispatch(setSelectedReducers(newValue));
          formik.setFieldValue("reducers_ids", valueFormated);
        }}
        value={reducers_ids || []}
        InputProps={{
          autoComplete: "off",
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.serial_number}
          </li>
        )}
        getOptionLabel={(option) => option.serial_number}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"reducers_ids"}
            name={"reducers_ids"}
            label={"Redutores"}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default ReducerField;
