import {
  asyncTicketsCategoriesActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING, STATUS_INPUT, TEXT_INPUT } from "app/components/DynamicViewGenerator/constants";
import { CLEAN_TICKETS_CATEGORIES_STORE } from "app/redux/modules/Setup/TicketsCategories/constants";
import * as Yup from "yup";

const TicketsCategories = () => {
  const dispatch = useDispatch();
  const { ticketsCategories } = useSelector((state) => state);

  const startPage = async () => {
     dispatch(asyncTicketsCategoriesActions.get());
  }

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_TICKETS_CATEGORIES_STORE });
    }
  }, [dispatch]);
 



  const dataLabels = [
    {
      id: "Id",
      name: "Nome",   
      // message: "Mensagem",  
      created_at: "Data criação",
      // status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      // message: DATA_TYPE_STRING,
      // status: DATA_TYPE_CHIP,
      created_at: DATA_TYPE_DATE,
      },
  ];

  const formFieldsType = [
    {
      type: TEXT_INPUT,
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    }, 
    // {
    //   type: TEXT_INPUT,
    //   value: "message",
    //   label: "Mensagem",
    //   required: true,
    //   action: "",
    //   validation: Yup.string().required("Obrigatório")
    // },   
    // {
    //   type: STATUS_INPUT,
    //   value: "status",
    //   label: "Status",
    //   required: true,
    //   action: "",
    //   validation: Yup.string().required("Obrigatório")
    // },   
  ];

  const pageConfig = {
    title: "Categorias de Tickets",
    newText: "Nova categoria",
    searchText: "Pesquisar",
    data: ticketsCategories.data,
    loading: ticketsCategories.loading,
    actions: {
      delete: asyncTicketsCategoriesActions.delete,
      edit: asyncTicketsCategoriesActions.edit,
      get: asyncTicketsCategoriesActions.get,
      search: asyncTicketsCategoriesActions.get,
      create: asyncTicketsCategoriesActions.create,
      clean: { type: CLEAN_TICKETS_CATEGORIES_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default TicketsCategories;
