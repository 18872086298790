import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { useFetchCompanyFieldQuery } from "app/redux/features/fields-slices";
import React from "react";

function CompanyField({ formikRef, ...props }) {
  const formik = formikRef;

  const { data, isLoading, isFetching } = useFetchCompanyFieldQuery();

  return (
    <FormControl fullWidth className="bg-white">
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data || []}
        loading={isLoading || isFetching}
        fullWidth
        disabled={data === undefined}
        onChange={(_, value) => {
          formik.setFieldValue(
            "company_id",
            value !== null ? value.id : undefined
          );

          formik.setFieldValue("unit_id", undefined);
          formik.setFieldValue("client_id", undefined);
        }}
        value={
          data?.find((option) => option.id === formik.values.company_id) || null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.value}
          </li>
        )}
        getOptionLabel={(option) => option.value || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            className="w-full text-sm text-gray-500"
            size="medium"
            fullWidth
            id={"company_id_id"}
            name={"company_id_id"}
            label={"Empresa"}
            InputLabelProps={{
              className: "text-sm pt-[3px]",
            }}
            error={
              formik.touched.company_id && Boolean(formik.errors.company_id)
            }
            helperText={formik.touched.company_id && formik.errors.company_id}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default CompanyField;
