import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import { useFetchFamiliesQuery } from "../../features/serviceOrderFields-slice";
import { FieldSkeleton } from "app/components/CustomInputs/Skeleton";

function FamilySoField({ formik, handlePreviewData, ...props }) {
  const { data, isFetching, isLoading } = useFetchFamiliesQuery();

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          formik.setFieldValue("family", value !== null ? value.id : undefined);
          handlePreviewData("family", value !== null ? value.name : undefined);

          formik.setFieldValue("product", undefined);
          handlePreviewData("product", undefined);

          formik.setFieldValue("model", undefined);
          handlePreviewData("model", undefined);

          formik.setFieldValue("issue", []);
          handlePreviewData("issue", undefined);
        }}
        value={
          data?.data?.find((option) => option.id === formik.values.family) ||
          null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"family"}
            name={"family"}
            label={"Família"}
            error={formik.touched.family && Boolean(formik.errors.family)}
            helperText={formik.touched.family && formik.errors.family}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default FamilySoField;
