import {
  GET_TICKETS_REQUEST,
  GET_TICKETS_SUCCESS,
  GET_TICKETS_FAILURE,

  GET_TICKETS_FROM_COMPANY_REQUEST,
  GET_TICKETS_FROM_COMPANY_SUCCESS,
  GET_TICKETS_FROM_COMPANY_FAILURE,

  CLEAN_TICKETS_STORE,
  
  DELETE_TICKETS_REQUEST,
  DELETE_TICKETS_SUCCESS,
  DELETE_TICKETS_FAILURE,

  POST_TICKETS_REQUEST,
  POST_TICKETS_FAILURE,
  POST_TICKETS_SUCCESS,

  PUT_TICKETS_REQUEST,
  PUT_TICKETS_FAILURE,
  PUT_TICKETS_SUCCESS,
  GET_SINGLE_TICKETS_REQUEST,
  GET_SINGLE_TICKETS_SUCCESS,
  GET_SINGLE_TICKETS_FAILURE,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function tickets(state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS_REQUEST:
    case GET_SINGLE_TICKETS_REQUEST:
    case DELETE_TICKETS_REQUEST:
    case POST_TICKETS_REQUEST:
    case PUT_TICKETS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TICKETS_SUCCESS:
    case GET_SINGLE_TICKETS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_TICKETS_SUCCESS:
    case POST_TICKETS_SUCCESS:
    case PUT_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_TICKETS_FAILURE:
    case POST_TICKETS_FAILURE:
    case DELETE_TICKETS_FAILURE:
    case PUT_TICKETS_FAILURE:
    case GET_SINGLE_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_TICKETS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
