//TICKETS_RESPONSES Types

export const GET_TICKETS_RESPONSES_REQUEST = "GET_TICKETS_RESPONSES_REQUEST";
export const GET_TICKETS_RESPONSES_FAILURE = "GET_TICKETS_RESPONSES_FAILURE";
export const GET_TICKETS_RESPONSES_SUCCESS = "GET_TICKETS_RESPONSES_SUCCESS";

export const POST_TICKETS_RESPONSES_REQUEST = "POST_TICKETS_RESPONSES_REQUEST";
export const POST_TICKETS_RESPONSES_FAILURE = "post-TICKETS_RESPONSES-failure";
export const POST_TICKETS_RESPONSES_SUCCESS = "post-TICKETS_RESPONSES-success";



export const CLEAN_TICKETS_RESPONSES_STORE = "CLEAN_TICKETS_RESPONSES_STORE";