//TASK_TYPES Types

export const GET_TASK_TYPES_REQUEST = "GET_TASK_TYPES_REQUEST";
export const GET_TASK_TYPES_FAILURE = "GET_TASK_TYPES_FAILURE";
export const GET_TASK_TYPES_SUCCESS = "GET_TASK_TYPES_SUCCESS";

export const GET_SINGLE_TASK_TYPES_REQUEST = 'get-single-TASK_TYPES-request'
export const GET_SINGLE_TASK_TYPES_FAILURE = 'get-single-TASK_TYPES-failure';
export const GET_SINGLE_TASK_TYPES_SUCCESS = 'get-single-TASK_TYPES-success';

export const POST_TASK_TYPES_REQUEST = "POST_TASK_TYPES_REQUEST";
export const POST_TASK_TYPES_FAILURE = "post-TASK_TYPES-failure";
export const POST_TASK_TYPES_SUCCESS = "post-TASK_TYPES-success";

export const PUT_TASK_TYPES_REQUEST = "PUT_TASK_TYPES_REQUEST";
export const PUT_TASK_TYPES_FAILURE = "put-TASK_TYPES-failure";
export const PUT_TASK_TYPES_SUCCESS = "put-TASK_TYPES-success";

export const DELETE_TASK_TYPES_REQUEST = "DELETE_TASK_TYPES_REQUEST";
export const DELETE_TASK_TYPES_FAILURE = "delete-TASK_TYPES-failure";
export const DELETE_TASK_TYPES_SUCCESS = "delete-TASK_TYPES-success";

export const CLEAN_TASK_TYPES_STORE = "CLEAN_TASK_TYPES_STORE";