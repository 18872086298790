import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  CLEAN_CUSTOMERS_STORE,
  DELETE_CUSTOMERS_REQUEST,
  DELETE_CUSTOMERS_SUCCESS,
  DELETE_CUSTOMERS_FAILURE,
  POST_CUSTOMERS_REQUEST,
  POST_CUSTOMERS_FAILURE,
  POST_CUSTOMERS_SUCCESS,
  PUT_CUSTOMERS_REQUEST,
  PUT_CUSTOMERS_FAILURE,
  PUT_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_TICKETS_REQUEST,
  GET_CUSTOMERS_TICKETS_SUCCESS,
  GET_CUSTOMERS_TICKETS_FAILURE,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function customers(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMERS_REQUEST:
    case DELETE_CUSTOMERS_REQUEST:
    case POST_CUSTOMERS_REQUEST:
    case PUT_CUSTOMERS_REQUEST:
    case GET_CUSTOMERS_TICKETS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CUSTOMERS_SUCCESS:
    case GET_CUSTOMERS_TICKETS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_CUSTOMERS_SUCCESS:
    case POST_CUSTOMERS_SUCCESS:
    case PUT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_CUSTOMERS_FAILURE:
    case POST_CUSTOMERS_FAILURE:
    case DELETE_CUSTOMERS_FAILURE:
    case PUT_CUSTOMERS_FAILURE:
    case GET_CUSTOMERS_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_CUSTOMERS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
