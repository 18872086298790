import React from "react";
import { Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import PieChartContaminationGraph from "./PieChartContaminationGraph";

const PieChartContamination = () => {
  return (
    <JumboCardQuick
      title={
        <Typography variant={"h6"} mb={0}>
          Redutores com contaminação
        </Typography>
      }
      noWrapper
      sx={{ textAlign: "center" }}
    >
      <PieChartContaminationGraph />
    </JumboCardQuick>
  );
};

export default PieChartContamination;
