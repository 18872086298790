import { Skeleton, Stack } from "@mui/material";
import React from "react";

function SkeletonsCard() {
  return (
    <Stack direction={"row"} flexWrap={"wrap"}>
      <Skeleton
        variant="rectangular"
        style={{
          borderRadius: 15,
          height: "255px",
          width: "100%",
        }}
      />
    </Stack>
  );
}

export default SkeletonsCard;
