import React from 'react';
import ContactsAppContext from "./ContactsAppContext";
import {CONTACT_APP_ACTIONS} from "./utils/constants";
import { useParams } from 'react-router-dom';
import api from "../../../../services/config";
import { useSelector } from 'react-redux';

const init = (appState) => appState;

const contactsAppReducer = (state, action) => {
    switch (action.type) {
        case CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedContacts: action?.payload
            };
        case CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH:
            return {
                ...state,
                refreshContactsList: action.payload,
            };
        case CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH:
            return {
                ...state,
                refreshLabelsList: action.payload,
            };
        case CONTACT_APP_ACTIONS.SET_COMPANY:
            return {
                ...state,
                company: action.payload,
                loadingCompany: false
            }
        case CONTACT_APP_ACTIONS.SET_FIELD:
            return {
                ...state,
                field: action.payload
            }
        default:
            return state;
    }
};

const ContactsAppProvider = ({children}) => {
    const { id } = useParams();
    const { auth } = useSelector(state => state);

    async function showCompany() {
        try {
            const { data } = await api.get(
                `companies/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                }
            );

            console.log("COMPANY > > ", data);
            setContactsApp({type: CONTACT_APP_ACTIONS.SET_COMPANY, payload: data});
        } catch (error) {
            console.log("ERROR > ", error);
        }
    }

    React.useEffect(() => {
        showCompany();
    }, [id]);

    const [contactsApp, setContactsApp] = React.useReducer(contactsAppReducer, {
        selectedContacts: [],
        refreshContactsList: false,
        refreshLabelsList: false,
        company: null,
        loadingCompany: true,
        field: "unit",
    }, init);

    const setField = React.useCallback((field) => {
        setContactsApp({type: CONTACT_APP_ACTIONS.SET_FIELD, payload: field});
    }, [setContactsApp])

    const setSelectedContacts = React.useCallback((contacts) => {
        setContactsApp({type: CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS, payload: contacts});
    }, [setContactsApp]);

    const setContactsListRefresh = React.useCallback((refresh) => {
        setContactsApp({type: CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH, payload: refresh})
    }, [setContactsApp]);

    const setLabelsListRefresh = React.useCallback((refresh) => {
        setContactsApp({type: CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH, payload: refresh})
    }, [setContactsApp]);

    const contextValue = React.useMemo(() => ({
        ...contactsApp,
        setSelectedContacts: setSelectedContacts,
        setContactsListRefresh: setContactsListRefresh,
        setLabelsListRefresh: setLabelsListRefresh,
        setField: setField
    }), [contactsApp]);

    return (
        <ContactsAppContext.Provider value={contextValue}>
            {children}
        </ContactsAppContext.Provider>
    );
};

export default ContactsAppProvider;
