import React from "react";
import { useParams } from "react-router-dom";
import ContactsApp from "./contacts/ContactsApp";
import ContactsAppProvider from "./contacts/ContactsAppProvider";

const CompanyDetails = () => {
    return (
        <ContactsAppProvider>
            <ContactsApp />
        </ContactsAppProvider>
    )
}

export default CompanyDetails;