//USERS Types

export const PUT_USERS_REQUEST = "PUT_USERS_REQUEST";
export const PUT_USERS_FAILURE = "put-USERS-failure";
export const PUT_USERS_SUCCESS = "put-USERS-success";

export const CREATE_USERS_REQUEST = "CREATE_USERS_REQUEST";
export const CREATE_USERS_FAILURE = "create-USERS-failure";
export const CREATE_USERS_SUCCESS = "create-USERS-success";

export const DELETE_USERS_REQUEST = "DELETE_USERS_REQUEST";
export const DELETE_USERS_FAILURE = "delete-USERS-failure";
export const DELETE_USERS_SUCCESS = "delete-USERS-success";

export const CLEAN_USERS_STORE = "CLEAN_USERS_STORE";
