import {
  GET_TICKETS_CATEGORIES_SUCCESS,
  GET_TICKETS_CATEGORIES_FAILURE,
  GET_TICKETS_CATEGORIES_REQUEST,
  DELETE_TICKETS_CATEGORIES_FAILURE,
  DELETE_TICKETS_CATEGORIES_REQUEST,
  DELETE_TICKETS_CATEGORIES_SUCCESS,
  POST_TICKETS_CATEGORIES_REQUEST,
  POST_TICKETS_CATEGORIES_FAILURE,
  POST_TICKETS_CATEGORIES_SUCCESS,
  PUT_TICKETS_CATEGORIES_REQUEST,
  PUT_TICKETS_CATEGORIES_FAILURE,
  PUT_TICKETS_CATEGORIES_SUCCESS,
} from "./constants";
import api from "app/services/config";

export const getTicketsCategories =
  (order, orderBy, status, page, searchKey, noPaginate) => async (dispatch, getState) => {
    dispatch({ type: GET_TICKETS_CATEGORIES_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/tickets/categories`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          page: search.data.page,
          s: searchKey,
  

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_TICKETS_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TICKETS_CATEGORIES_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteTicketsCategories = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_TICKETS_CATEGORIES_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/tickets/categories/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_TICKETS_CATEGORIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_TICKETS_CATEGORIES_FAILURE,
      payload: error.message,
    });
  } finally {
    dispatch(getTicketsCategories("asc", "id"));
  }
};
export const createTicketsCategories =
  (data) => async (dispatch, getState) => {
    dispatch({ type: POST_TICKETS_CATEGORIES_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.post(
        `/tickets/categories`,
        {
          name: data.name,
          code: data.code,
          description: data.description,
          product_model_id: data.product_model_id,
          status: data.status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: POST_TICKETS_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: POST_TICKETS_CATEGORIES_FAILURE,
        payload: error.message,
      });
    } finally {
      dispatch(getTicketsCategories("asc", "id"));
    }
  };

  export const editTicketsCategories =
  (data, id) => async (dispatch, getState) => {
    dispatch({ type: PUT_TICKETS_CATEGORIES_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.put(
        `/tickets/categories/${id}`,
        {
          name: data.name,
          code: data.code,
          description: data.description,
          product_model_id: data.product_model_id,
          status: data.status,

        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: PUT_TICKETS_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: PUT_TICKETS_CATEGORIES_FAILURE,
        payload: error.message,
      });
    } finally {
      dispatch(getTicketsCategories("asc", "id"));
    }
  };
