import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import Header from "../shared/headers/Header";
import Sidebar from "../shared/sidebars/Sidebar";
import Footer from "../shared/footers/Footer";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";
import JumboCustomizer from "../../shared/JumboCustomizer/JumboCustomizer";
import JumboCustomizerButton from "../../shared/JumboCustomizer/JumboCustomizerButton";
import {
  useJumboHeaderTheme,
  useJumboLayoutSidebar,
  useJumboTheme,
} from "@jumbo/hooks";
import { SIDEBAR_STYLES } from "@jumbo/utils/constants";
import { headerTheme as theme4 } from "../../themes/header/theme4";
import { headerTheme as defaultTheme } from "../../themes/header/default";
import { useApp } from "../../hooks";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "app/redux/actions/auth";

const VerticalDefault = ({ children }) => {
  const { setJumboLayoutOptions } = useJumboLayout();
  const { headerTheme, setHeaderTheme } = useJumboHeaderTheme();
  const { theme } = useJumboTheme();
  const appBarBgColor =
    headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background;
  const { sidebarOptions } = useJumboLayoutSidebar();
  const appData = useApp();

  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  React.useEffect(() => {
    setJumboLayoutOptions(layoutConfig);
  }, []);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (
      appBarBgColor === "#F5F7FA" &&
      sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
    ) {
      setHeaderTheme({ ...theme, ...theme4 });
      appData.setAppState({ prevHeaderBgColor: "#F5F7FA" });
    } else {
      if (
        appData.prevHeaderBgColor &&
        appData.prevHeaderBgColor === "#F5F7FA"
      ) {
        setHeaderTheme({ ...theme, ...defaultTheme });
      }
    }
  }, [sidebarOptions.style]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log(auth.user);

    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/login");

    dispatch(logout());
  };

  return (
    <JumboLayout
      header={<Header />}
      sidebar={<Sidebar />}
      /* footer={<Footer/>} */
      headerSx={{
        height: 80,
      }}
    >
      {/* <div
                style={{
                    marginTop:  window.location.pathname.includes("tickets/responder") ? 10 : -30,
                    marginRight: window.location.pathname.includes("tickets/responder") ? 50 : 0,
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
              <Avatar
                src={`https://storage.googleapis.com/krebs-os-bucket/avatars/${auth.user ? auth.user.profile.avatar : ""}`}
                alt={auth.user ? auth.user.profile.name : ""}
                onClick={handleMenu}
                sx={{
                    cursor: "pointer",
                    width: 60,
                    height: 60,
                    boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.2)"
                }}
              />

              <Menu
                sx={{
                    zIndex: 99999
                }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
 */}
      {children}
      {/*             <JumboCustomizer/>
            <JumboCustomizerButton/> */}
    </JumboLayout>
  );
};

export default VerticalDefault;
