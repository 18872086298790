import {
  GET_EMAIL_REQUEST,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAILURE,

  CLEAN_EMAIL_STORE,
  
  DELETE_EMAIL_REQUEST,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL_FAILURE,

  POST_EMAIL_REQUEST,
  POST_EMAIL_FAILURE,
  POST_EMAIL_SUCCESS,

  PUT_EMAIL_REQUEST,
  PUT_EMAIL_FAILURE,
  PUT_EMAIL_SUCCESS,
} from "./constants";
 
const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function email(state = initialState, action) {
  switch (action.type) {
    case GET_EMAIL_REQUEST:
    case DELETE_EMAIL_REQUEST:
    case POST_EMAIL_REQUEST:
    case PUT_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EMAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_EMAIL_SUCCESS:
    case POST_EMAIL_SUCCESS:
    case PUT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_EMAIL_FAILURE:
    case POST_EMAIL_FAILURE:
    case DELETE_EMAIL_FAILURE:
    case PUT_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_EMAIL_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
