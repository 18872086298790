//ISSUES Types

export const GET_ISSUES_REQUEST = "GET_ISSUES_REQUEST";
export const GET_ISSUES_FAILURE = "GET_ISSUES_FAILURE";
export const GET_ISSUES_SUCCESS = "GET_ISSUES_SUCCESS";

export const GET_SINGLE_ISSUES_REQUEST = 'get-single-ISSUES-request'
export const GET_SINGLE_ISSUES_FAILURE = 'get-single-ISSUES-failure';
export const GET_SINGLE_ISSUES_SUCCESS = 'get-single-ISSUES-success';

export const POST_ISSUES_REQUEST = "POST_ISSUES_REQUEST";
export const POST_ISSUES_FAILURE = "post-ISSUES-failure";
export const POST_ISSUES_SUCCESS = "post-ISSUES-success";

export const PUT_ISSUES_REQUEST = "PUT_ISSUES_REQUEST";
export const PUT_ISSUES_FAILURE = "put-ISSUES-failure";
export const PUT_ISSUES_SUCCESS = "put-ISSUES-success";

export const DELETE_ISSUES_REQUEST = "DELETE_ISSUES_REQUEST";
export const DELETE_ISSUES_FAILURE = "delete-ISSUES-failure";
export const DELETE_ISSUES_SUCCESS = "delete-ISSUES-success";

export const CLEAN_ISSUES_STORE = "CLEAN_ISSUES_STORE";