import React from "react";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { FieldSkeleton } from "./Skeleton";

export default function MultiSelectInput({
  fieldName,
  fieldLabel,
  formik,
  data,
  dataValue,
  handleDataValue,
  handlePreviewData,
  subLevelData,
  loading,
}) {
  const dispatch = useDispatch();
 
  return (
    <>
      {loading ? (
        <FieldSkeleton />
      ) : (
        <FormControl sx={{ mb: 1 }}>
          <Autocomplete
            data-testid={`input-${fieldName}`}
            key={fieldName}
            multiple
            id="tags-outlined"
            options={data || []}
            value={dataValue || []}
            getOptionLabel={
              subLevelData
                ? (field) => field[subLevelData].name
                : (field) => field.name
            }
            filterSelectedOptions
            disabled={!(data && data.length > 0)}
            onChange={(e, newValue) => {
              if (newValue.length > 0) {
                dispatch(handleDataValue(newValue));
                const valueFormated = newValue.map((vl) => vl.id);
                const namesArray = newValue.map((vl) =>
                  subLevelData ? vl[subLevelData].name : vl.name
                );

                formik.setFieldValue(fieldName, valueFormated);

                handlePreviewData(fieldName, namesArray.join(", "));
              } else {
                dispatch(handleDataValue([]));
                formik.setFieldValue(fieldName, []);
                handlePreviewData(fieldName, "");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                id={fieldName}
                name={fieldName}
                label={fieldLabel}
                value={formik.values[fieldName]}
                error={
                  formik.touched[fieldName] && Boolean(formik.errors[fieldName])
                }
                helperText={
                  formik.touched[fieldName] && formik.errors[fieldName]
                }
              />
            )}
          />
        </FormControl>
      )}
    </>
  );
}
