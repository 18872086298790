import {
  CLEAN_STATES_STORE,
  GET_STATES_REQUEST,
  GET_STATES_FAILURE,
  GET_STATES_SUCCESS, 
  
} from "./constants";
 
const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function states(state = initialState, action) {
  switch (action.type) {
    case GET_STATES_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
      };
      case GET_STATES_REQUEST:    
      return {
        ...state,
        loading: true,
        error: null,
      };
      case GET_STATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  
    case CLEAN_STATES_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
