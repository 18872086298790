import { FormControl, MenuItem, TextField } from "@mui/material";
import { freeTimes } from "../../untils/constants";

function FinalHourSo({ formik, handlePreviewData, previewData }) {
  return (
    <FormControl sx={{ mb: 1 }}>
      <TextField
        fullWidth
        data-testid={"input-time_end"}
        select
        disabled={formik.values.end === null}
        id="time_end"
        name="time_end"
        label="Horário Final"
        value={formik.values.time_end}
        error={formik.touched.time_end && Boolean(formik.errors.time_end)}
        helperText={formik.touched.time_end && formik.errors.time_end}
        onChange={(e) => {
          formik.handleChange(e);
          handlePreviewData((previewData) => ({
            ...previewData,
            endAt: [previewData.endAt[0], e.target.value],
          }));
        }}
      >
        {freeTimes
          .filter((time) => time >= previewData.scheduled_at[1])
          .map((freeTime, index) => (
            <MenuItem
              key={index}
              data-testid={`select-option-time_end-${index}`}
              value={freeTime}
            >
              {freeTime}
            </MenuItem>
          ))}
      </TextField>
    </FormControl>
  );
}

export default FinalHourSo;
