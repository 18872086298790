import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import OilLevel from "./OilLevel";
import PieChartReducersWithLeak from "./PieChartReducersWithLeak";
import PieChartContamination from "./PieChartContamination";
import PieChartViscosity from "./PieChartViscosity";
import HeaderChart from "./HeaderChart";
import useCurrentPivot from "../../hooks/useCurrentPivot";
import PieChartOilLevel from "./PieChartOilLevel";

function PivotGraphs() {
  const { refetch } = useCurrentPivot();
  useEffect(() => {
    refetch();
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderChart />
        </Grid>
        <Grid item xs={3}>
          <PieChartOilLevel />
        </Grid>
        <Grid item xs={3}>
          <PieChartReducersWithLeak />
        </Grid>
        <Grid item xs={3}>
          <PieChartViscosity />
        </Grid>
        <Grid item xs={3}>
          <PieChartContamination />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PivotGraphs;
