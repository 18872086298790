import React from "react";
import PivoProvider from "./PivoProvider";
import PivoList from "./Pivots/PivoList";
import ReducerList from "./Reducers/ReducerList";

function PivotReducers() {
  return (
    <PivoProvider>
      <PivoList />
    </PivoProvider>
  );
}

export default PivotReducers;
