//COMPANIES Types

export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";

export const GET_RESALES_REQUEST = "GET_RESALES_REQUEST";
export const GET_RESALES_FAILURE = "GET_RESALES_FAILURE";
export const GET_RESALES_SUCCESS = "GET_RESALES_SUCCESS";

export const GET_SINGLE_COMPANIES_REQUEST = 'get-single-COMPANIES-request'
export const GET_SINGLE_COMPANIES_FAILURE = 'get-single-COMPANIES-failure';
export const GET_SINGLE_COMPANIES_SUCCESS = 'get-single-COMPANIES-success';

export const POST_COMPANIES_REQUEST = "POST_COMPANIES_REQUEST";
export const POST_COMPANIES_FAILURE = "post-COMPANIES-failure";
export const POST_COMPANIES_SUCCESS = "post-COMPANIES-success";

export const PUT_COMPANIES_REQUEST = "PUT_COMPANIES_REQUEST";
export const PUT_COMPANIES_FAILURE = "put-COMPANIES-failure";
export const PUT_COMPANIES_SUCCESS = "put-COMPANIES-success";

export const DELETE_COMPANIES_REQUEST = "DELETE_COMPANIES_REQUEST";
export const DELETE_COMPANIES_FAILURE = "delete-COMPANIES-failure";
export const DELETE_COMPANIES_SUCCESS = "delete-COMPANIES-success";

export const CLEAN_COMPANIES_STORE = "CLEAN_COMPANIES_STORE";

export const GET_COMPANIES_TYPES_REQUEST = "GET_COMPANIES_TYPES_REQUEST";
export const GET_COMPANIES_TYPES_FAILURE = "GET_COMPANIES_TYPES_failure";
export const GET_COMPANIES_TYPES_SUCCESS = "GET_COMPANIES_TYPES_success";
export const CLEAN_COMPANIES_TYPES_STORE = "CLEAN_COMPANIES_TYPES_STORE";
