import { AUTHLOGIN, AUTHLOGOUT } from "app/utils/constants/auth";

const INIT_STATE = {
    token: null,
    user: null,
};

export default function auth(state = INIT_STATE, action) {
    switch (action.type) {
        case AUTHLOGIN: {
            return {
                ...state,
                ...action.payload
            }
        }
        case AUTHLOGOUT: {
            return INIT_STATE;
        }
        default: {
            return state;
        }
    }
};
