import { Pagination, Typography } from "@mui/material";
import React, { useContext } from "react";
import PivoListItens from "./Components/PivoListItens";
import { PivoContext } from "../PivoProvider";
import PivoListHeader from "./Components/PivoListHeader";
import PivoModal from "./Components/PivoModal";
import { useFetchPivosQuery } from "../features/pivos/pivos-slice";
import Loading from "app/components/Loading";

function PivoList() {
  // const data = PivoMockData;
  const pivot = useContext(PivoContext);
  const search = {
    page: pivot.page,
    order: pivot.order,
    orderBy: pivot.order_by,
    s: pivot.s,
    company_id: pivot.company_id,
    need_oil_change: pivot.need_oil_change,
  };

  const { data, isLoading } = useFetchPivosQuery(search);

  if (isLoading) return <Loading />;

  if (data?.data.length === 0) {
    return (
      <React.Fragment>
        <PivoModal />
        <PivoListHeader />
        <Typography variant={"h4"} mb={3}>
          Nenhum pivô para listar
        </Typography>{" "}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PivoModal />
      <PivoListHeader />
      {data?.data.map((pivos) => (
        <PivoListItens
          key={pivos.id}
          data-testid={`pivo-item-${pivos.id}`}
          item={pivos}
        />
      ))}
      <Pagination
        count={data?.meta?.last_page}
        page={pivot.page}
        onChange={(_, value) => pivot.setPage(value)}
        shape="rounded"
        variant="outlined"
        color="secondary"
      />
    </React.Fragment>
  );
}

export default PivoList;
