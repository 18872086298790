import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useListCategoryInputQuery } from "app/pages/FileManager/features/fileManager-slice";
import React from "react";

function FileCategoriesInput({ formikRef, label }) {
  const { data, isLoading, isFetching } = useListCategoryInputQuery({
    category_id: "",
  });
  const formik = formikRef;

  return (
    <FormControl className="w-full" sx={{ minWidth: 250 }} size="small">
      <Autocomplete
        filterSelectedOptions={true}
        options={data || []}
        loading={isLoading}
        fullWidth
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          formik.setFieldValue(
            "file_manager_category_id",
            value !== null ? value.id : undefined
          );
          formik.setFieldValue("category", value);
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"file_manager_category_id"}
            name={"file_manager_category_id"}
            label={label}
            error={
              formik.touched.file_manager_category_id &&
              Boolean(formik.errors.file_manager_category_id)
            }
            helperText={
              formik.touched.file_manager_category_id &&
              formik.errors.file_manager_category_id
            }
            InputProps={{
              ...params.InputProps,
              style: {
                paddingTop: 0,
                paddingBottom: 0,
                height: 38,
              },
              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            InputLabelProps={{
              style: {
                lineHeight: "0.5rem",
                overflow: "visible",
              },
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default FileCategoriesInput;
