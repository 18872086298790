import { SET_ALERT } from "../../Alerts/constants";
import {
  GET_SOLUTIONS_SUCCESS,
  GET_SOLUTIONS_FAILURE,
  GET_SOLUTIONS_REQUEST,
  DELETE_SOLUTIONS_FAILURE,
  DELETE_SOLUTIONS_REQUEST,
  DELETE_SOLUTIONS_SUCCESS,
  POST_SOLUTIONS_REQUEST,
  POST_SOLUTIONS_FAILURE,
  POST_SOLUTIONS_SUCCESS,
  PUT_SOLUTIONS_REQUEST,
  PUT_SOLUTIONS_FAILURE,
  PUT_SOLUTIONS_SUCCESS,
} from "./constants";
import api from "app/services/config";

export const getSolutions =
  (order, orderBy, status, page, searchKey, noPaginate) => async (dispatch, getState) => {
    dispatch({ type: GET_SOLUTIONS_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/product-models/solutions`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: search.data.status,
          page: search.data.page,
          s: searchKey,
          noPaginate: noPaginate,
          product_model_id: search.data.category,
          

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_SOLUTIONS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SOLUTIONS_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteSolutions = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_SOLUTIONS_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/product-models/solutions/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_SOLUTIONS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" }, 
    })
  } catch (error) {
    dispatch({
      type: DELETE_SOLUTIONS_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" }, 
    })
  } finally {
    dispatch(getSolutions("asc", "id"));
  }
};
export const createSolutions =
  (data) => async (dispatch, getState) => {
    dispatch({ type: POST_SOLUTIONS_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.post(
        `/product-models/solutions`,
        {
          name: data.name,
          code: data.code,
          description: data.description,
          product_model_id: data.product_model_id,
          status: data.status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: POST_SOLUTIONS_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Criado com sucesso", variant: "success" }, 
      })
    } catch (error) {
      dispatch({
        type: POST_SOLUTIONS_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao criar", variant: "error" }, 
      })
    } finally {
      dispatch(getSolutions("asc", "id"));
    }
  };

  export const editSolutions =
  (data, id) => async (dispatch, getState) => {
    dispatch({ type: PUT_SOLUTIONS_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.put(
        `/product-models/solutions/${id}`,
        {
          name: data.name,
          code: data.code,
          description: data.description,
          product_model_id: data.product_model_id,
          status: data.status,

        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      dispatch({
        type: PUT_SOLUTIONS_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Editado com sucesso", variant: "success" }, 
      })      
    } catch (error) {
      dispatch({
        type: PUT_SOLUTIONS_FAILURE,
        payload: error.message,
      });
      dispatch({
        type: SET_ALERT,
        payload: { message: "Erro ao editar", variant: "error" }, 
      })      
    } finally {
      dispatch(getSolutions("asc", "id"));
    }
  };
