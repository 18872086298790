import {
  GET_TICKETS_RESPONSES_REQUEST,
  GET_TICKETS_RESPONSES_SUCCESS,
  GET_TICKETS_RESPONSES_FAILURE,

  CLEAN_TICKETS_RESPONSES_STORE,


  POST_TICKETS_RESPONSES_REQUEST,
  POST_TICKETS_RESPONSES_FAILURE,
  POST_TICKETS_RESPONSES_SUCCESS,


} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function ticketsResponses(state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS_RESPONSES_REQUEST:
     case POST_TICKETS_RESPONSES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TICKETS_RESPONSES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case POST_TICKETS_RESPONSES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_TICKETS_RESPONSES_FAILURE:
    case POST_TICKETS_RESPONSES_FAILURE:

      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_TICKETS_RESPONSES_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
