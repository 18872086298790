import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { useFetchFamilyFieldQuery } from "app/redux/features/fields-slices";
import React from "react";

function FamilyField({ formikRef, ...props }) {
  const formik = formikRef;

  const { data, isLoading, isFetching } = useFetchFamilyFieldQuery();
  return (
    <FormControl fullWidth className="bg-white">
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        onChange={(_, value) => {
          formik.setFieldValue(
            "family_id",
            value !== null ? value.id : undefined
          );
        }}
        value={
          data?.data.find((option) => option.id === formik.values.family_id) ||
          null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            className="w-full text-sm text-gray-500"
            size="medium"
            fullWidth
            id={"family_id"}
            name={"family_id"}
            label={"Família de Produtos Krebs"}
            InputLabelProps={{
              className: "text-sm pt-[3px]",
            }}
            error={formik.touched.family_id && Boolean(formik.errors.family_id)}
            helperText={formik.touched.family_id && formik.errors.family_id}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default FamilyField;
