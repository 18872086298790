import React, { useEffect, useRef } from "react";
import { AnswerSkeleton } from "../Skeleton";
import {
  useFetchResponsesQuery,
  useLazyFetchResponsesQuery,
} from "../features/Tickets/tickets-slice";
import { useNavigate, useParams } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { normalizeDate, renderAvatar, firstLetter } from "app/utils/appHelpers";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { CloudDone, Mail, WhatsApp } from "@mui/icons-material";
import vCard from "vcf";
import VcardReader from "app/components/VcardReader";
import { setPage } from "app/redux/modules/Search/actions";
import { Pagination } from "@mui/material";

const isHtml = (str) => {
  const htmlRegex = /(<([^>]+)>)/gi;
  return htmlRegex.test(str);
};
const icons = [
  <WhatsApp className={"h-3 w-3 self-center"} />,
  <Mail className={"h-3 w-3 self-center"} />,
  <CloudDone className={"h-3 w-3 self-center"} />,
];

const RenderResponse = ({ item }) => {
  if (isHtml(item.response)) {
    return (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: item.response,
          }}
        />
        {item.attachments.length > 0 && (
          <>
            <div>
              <p>Anexos:</p>
              <ul>
                {item.attachments.map((attachment) => (
                  <li className="py-2">
                    <div>
                      <SimCardDownloadIcon
                        style={{ fontSize: 25, cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_BUCKET_PATH}ticket-responses/${attachment.name}`
                          );
                        }}
                      />
                      <a
                        target="_blank"
                        href={`${process.env.REACT_APP_BUCKET_PATH}ticket-responses/${attachment.name}`}
                      >
                        {attachment.name}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </>
    );
  }
  if (item.response && !item?.whatsapp_message?.media_content_type) {
    return <div>{item.response}</div>;
  }
  if (item.whatsapp_message?.media_content_type === "text/vcard") {
    return (
      <>
        {item.response && <div>{item.response}</div>}
        <VcardReader
          url={`https://krebs-os-bucket.storage.googleapis.com/${item.whatsapp_message.media_name}`}
        />
      </>
    );
  }
  if (item.whatsapp_message?.message_type === "document") {
    return (
      <>
        {item.response && <div>{item.response}</div>}
        <div>
          <SimCardDownloadIcon
            style={{ fontSize: 50, cursor: "pointer" }}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_BUCKET_PATH}${item.whatsapp_message.media_name}`
              );
            }}
          />
          Baixar arquivo
        </div>
      </>
    );
  }
  if (item.whatsapp_message?.media_content_type === "image/jpeg") {
    return (
      <>
        {item.response && <div>{item.response}</div>}

        <img
          src={`${process.env.REACT_APP_BUCKET_PATH}${item.whatsapp_message.media_name}`}
          alt="Imagem"
          style={{ width: "fit-content" }}
        />
      </>
    );
  }
  if (item.whatsapp_message?.media_content_type === "audio/ogg") {
    return (
      <>
        {item.response && <div>{item.response}</div>}

        <audio controls>
          <source
            src={`${process.env.REACT_APP_BUCKET_PATH}${item.whatsapp_message.media_name}`}
            type="audio/ogg"
          />
        </audio>
      </>
    );
  }
  if (item.whatsapp_message?.media_content_type === "video/mp4") {
    return (
      <>
        {item.response && <div>{item.response}</div>}
        <video controls style={{ maxWidth: "250px" }}>
          <source
            src={`${process.env.REACT_APP_BUCKET_PATH}${item.whatsapp_message.media_name}`}
            type="video/mp4"
          />
        </video>
      </>
    );
  }
  return null;
};

function TicketResponse() {
  const [page, setPage] = React.useState(1);

  const { id } = useParams();
  const currentId = useRef(id);
  const { data, isLoading, isFetching, isError } = useFetchResponsesQuery({
    id: id,
    page: page,
  });

  const navigate = useNavigate();

  if (isLoading || isFetching) {
    return <AnswerSkeleton />;
  }
  return (
    <div>
      <div className="flex flex-col flex-1">
        {data?.data?.map((item, index) => (
          <div
            className={`${
              item.is_public ? "bg-white" : "bg-red-50"
            }  py-6 px-4 border-t-[1px] border-solid border-gray-200 flex`}
            key={index}
          >
            {item?.user?.profile?.avatar ? (
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate(`/usuarios/${item.user_id}/detalhes`);
                }}
              >
                {renderAvatar(item?.user?.profile?.avatar)}
              </div>
            ) : (
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/usuarios/${item.user_id}/detalhes`);
                }}
              >
                {firstLetter(item?.user?.profile?.name)}
              </div>
            )}

            <div className="flex flex-col flex-1 ">
              <div className="flex justify-between">
                <span
                  className="cursor-pointer text-sm font-semibold"
                  onClick={() => {
                    navigate(`/usuarios/${item.user_id}/detalhes`);
                  }}
                >
                  {item?.user?.profile?.name}
                  {" - "}
                  {icons[item?.ticket_channel_id - 1]}
                  {item?.is_public === 0 && " - Comentário interno"}
                </span>
                <span className="cursor-pointer text-sm font-semibold">
                  {normalizeDate(item?.created_at)}
                </span>
              </div>
              <div className="mt-2 pl-1">
                <RenderResponse item={item} />
              </div>
            </div>
          </div>
        ))}
        <Pagination
          count={data.meta.last_page}
          page={data.meta.current_page}
          onChange={(_, value) => setPage(value)}
          shape="rounded"
          className="py-4 border-t-[1px] border-solid border-gray-200"
        />
      </div>
    </div>
  );
}

export default TicketResponse;
