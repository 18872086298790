import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";

import { asyncCompaniesActions, asyncProductFamiliesActions } from "app/redux/reducers";
import { CLEAN_COMPANIES_STORE } from "app/redux/modules/Customers/Companies/constants";
import { ADDRESS_INPUT, CITY_INPUT, DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING, MASKED_INPUT, SELECT_AUTOCOMPLETE_INPUT, STATUS_INPUT, TEXT_INPUT, UF_INPUT } from "app/components/DynamicViewGenerator/constants";
import * as Yup from "yup";

const Companies = () => {
  const dispatch = useDispatch();
  const { companies, companiesTypes, productFamilies, auth } = useSelector((state) => state);
  const [newSelectOptions, setNewSelectOptions] = React.useState([]);
  const [newSelectFamilyOptions, setNewSelectFamilyOptions] = React.useState([]);

  const startPage = () => {
    dispatch(asyncCompaniesActions.getCompanies("asc", "id",));
    dispatch(asyncCompaniesActions.getCompaniesTypes("asc", "id"));
    dispatch(asyncProductFamiliesActions.getProductFamilies("asc", "id",1,1,"",true));  

  };
  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_COMPANIES_STORE });
    };
  }, []);

  useEffect(() => {
    if (companiesTypes.data) {
      setNewSelectOptions(
        companiesTypes.data &&
          companiesTypes.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [companiesTypes]);

  useEffect(() => {
    if (productFamilies.data) {
      setNewSelectFamilyOptions(
        productFamilies.data.data && productFamilies.data.data.map(({ id, name }) => ({ id, name }))
      );
    }
  }, [productFamilies]);

  const dataLabels = [
    {
      id: "Código",
      name: "Empresa",
      company_type: "Tipo de empresa",   
      product_family_id: "Família",
      city: "Cidade",
      uf: "UF",
      status: "Status",
    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      city: DATA_TYPE_STRING,
      uf: DATA_TYPE_STRING,
      description: DATA_TYPE_STRING,
      cnpj: DATA_TYPE_STRING,
      company_type: {
        parent: "company_type",
        name: "name",
      },
      product_family_id: {
        parent: "product_family",
        name: "name",
      }, 
      status: DATA_TYPE_CHIP,
    },
  ];

  const formFieldsType = [
    {
      type: TEXT_INPUT,
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      parent: "",      
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: TEXT_INPUT,
      value: "description",
      label: "Descrição",
      required: true,
      action: "",
      parent: "",      
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: MASKED_INPUT,
      value: "cnpj",
      label: "CNPJ",
      mask: "999.999.999/9999-99",
      required: true,
      action: "",
      parent: "",
      validation: Yup.string().transform(value => value.replace(/[^\d]/g, '')).min(15, "CNPJ inválido").required("Obrigatório")
    },
    // {
    //   type: UF_INPUT,
    //   value: "uf",
    //   label: "Estado",
    //   required: true,
    //   action: "",
    //   parent: "",
    //   validation: Yup.string().required("Obrigatório")
    // },
    // {
    //   type: CITY_INPUT,
    //   value: "city",
    //   label: "Cidade",
    //   required: true,
    //   action: "",
    //   parent: "",
    //   validation: Yup.string().required("Obrigatório")
    // },
    {
      type: ADDRESS_INPUT,
      value: "address",
      label: "Endereço",
      required: true,
      action: "",
      createOnly: true,

    },  
    {
      type: TEXT_INPUT,
      value: "address_add_info",
      label: "Complemento",
      required: false,
      action: "",
      validation: "",
      createOnly: true,

    },
    {
      type: SELECT_AUTOCOMPLETE_INPUT,
      value: "product_family_id",
      label: "Família",
      required: true,
      options: newSelectFamilyOptions,
      action: "",
      parent: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: SELECT_AUTOCOMPLETE_INPUT,
      value: "company_type_id",
      label: "Tipo de empresa",
      required: true,
      options: newSelectOptions,
      action: "",
      parent: "",
      validation: Yup.string().required("Obrigatório")
    },
    {
      type: STATUS_INPUT,
      value: "status",
      label: "Status",
      required: true,
      action: "",
      parent: "",
      validation: Yup.string().required("Obrigatório")
    },
  ];

  const pageConfig = {
    title: "Empresas",
    newText: "Nova empresa",
    searchText: "Pesquisar",
    data: companies.data,
    loading: companies.loading,
    actions: {
      delete: asyncCompaniesActions.deleteCompanies,
      edit: asyncCompaniesActions.editCompanies,
      get: asyncCompaniesActions.getCompanies,
      search: asyncCompaniesActions.getCompanies,
      create: asyncCompaniesActions.createCompanies,
      clean: { type: CLEAN_COMPANIES_STORE },
    },
    createDataType: formFieldsType,
    detailsPage: "/empresas/detalhes/",
    statesCitySearch: true, 
    familySearch: true,
    familyData: newSelectFamilyOptions,
    more: true,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default Companies;
