import {
  GET_SOLUTIONS_REQUEST,
  GET_SOLUTIONS_SUCCESS,
  GET_SOLUTIONS_FAILURE,

  CLEAN_SOLUTIONS_STORE,
  
  DELETE_SOLUTIONS_REQUEST,
  DELETE_SOLUTIONS_SUCCESS,
  DELETE_SOLUTIONS_FAILURE,

  POST_SOLUTIONS_REQUEST,
  POST_SOLUTIONS_FAILURE,
  POST_SOLUTIONS_SUCCESS,

  PUT_SOLUTIONS_REQUEST,
  PUT_SOLUTIONS_FAILURE,
  PUT_SOLUTIONS_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function solutions(state = initialState, action) {
  switch (action.type) {
    case GET_SOLUTIONS_REQUEST:
    case DELETE_SOLUTIONS_REQUEST:
    case POST_SOLUTIONS_REQUEST:
    case PUT_SOLUTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SOLUTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_SOLUTIONS_SUCCESS:
    case POST_SOLUTIONS_SUCCESS:
    case PUT_SOLUTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_SOLUTIONS_FAILURE:
    case POST_SOLUTIONS_FAILURE:
    case DELETE_SOLUTIONS_FAILURE:
    case PUT_SOLUTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_SOLUTIONS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
