import React from "react";
import PivoProvider from "../PivoProvider";
import PivoDetailsLayout from "../Pivots/Components/PivoDetailsLayout";
import HistoryList from "./HistoryList";

function PivotHistory() {
  return (
    <PivoProvider>
      <PivoDetailsLayout>
        <HistoryList />
      </PivoDetailsLayout>
    </PivoProvider>
  );
}

export default PivotHistory;
