import {
  UPLOAD_ATTACHMENTS_FAILURE,
  UPLOAD_ATTACHMENTS_REQUEST,
  UPLOAD_ATTACHMENTS_SUCCESS,
} from "./constants";
import { history } from "app/redux/store";
import api from "app/services/config";

export const uploadAttachments =
  (data, order_id) => async (dispatch, getState) => {
    dispatch({ type: UPLOAD_ATTACHMENTS_REQUEST });

    const formData = new FormData();

    data.map((arq) => {
      formData.append("files", arq.file);
      formData.append("description", arq.description);
    });

    formData.append("service_order_id", `${order_id}`);
    formData.append("stage", "OPEN");

    try {
      const { auth } = getState();
      const response = await api.post(`attachments`, formData, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({ type: UPLOAD_ATTACHMENTS_SUCCESS });
    } catch (error) {
      dispatch({
        type: UPLOAD_ATTACHMENTS_FAILURE,
        payload: error.message,
      });
    }
  };
