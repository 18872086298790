import createDndFileUploadPlugin from "@draft-js-plugins/drag-n-drop-upload";
import { FormControl } from "@mui/material";
import { apiHost } from "app/services/config";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useSelector } from "react-redux";
import api from "app/services/config";

function EditorField({ formikRef }) {
  const formik = formikRef;
  const dndFileUploadPlugin = createDndFileUploadPlugin();
  const auth = useSelector((state) => state.auth);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    formik.setFieldValue(
      `description`,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  }, [editorState]);

  const insertFileLinkIntoEditor = (fileUrl, fileName) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: fileUrl }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    const textWithEntity = Modifier.insertText(
      newEditorState.getCurrentContent(),
      newEditorState.getSelection(),
      fileName,
      null,
      entityKey
    );
    setEditorState(
      EditorState.push(newEditorState, textWithEntity, "insert-characters")
    );
  };

  const uploadCallback = (file, callback) => {
    console.log("ta vindo aqui: ", file);
    return new Promise((resolve, reject) => {
      const fileImage = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      const formData = new FormData();

      formData.append(`files`, fileImage);

      api
        .post("/files-upload", formData, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("foi: datra", data);
          const fileName = data[0].name;
          const fileExtension = fileName.split(".").pop().toLowerCase();
          const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
          if (!imageExtensions.includes(fileExtension)) {
            insertFileLinkIntoEditor(
              `https://storage.googleapis.com/krebs-os-bucket/${data[0].name}`,
              `https://storage.googleapis.com/krebs-os-bucket/${data[0].name}`
            );
          }
          resolve({
            data: {
              link: `https://storage.googleapis.com/krebs-os-bucket/${data[0].name}`,
            },
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        plugins={[dndFileUploadPlugin]}
        toolbar={{
          image: {
            uploadCallback: uploadCallback,
            previewImage: false,
            alt: { present: true, mandatory: false },
            inputAccept:
              "image/gif,image/jpeg,image/jpg,image/png,image/svg,application/pdf,video/mp4,audio/ogg",
          },
        }}
      />
    </FormControl>
  );
}

export default EditorField;
