//TICKETS_CATEGORIES Types

export const GET_TICKETS_CATEGORIES_REQUEST = "GET_TICKETS_CATEGORIES_REQUEST";
export const GET_TICKETS_CATEGORIES_FAILURE = "GET_TICKETS_CATEGORIES_FAILURE";
export const GET_TICKETS_CATEGORIES_SUCCESS = "GET_TICKETS_CATEGORIES_SUCCESS";

export const GET_SINGLE_TICKETS_CATEGORIES_REQUEST = 'get-single-TICKETS_CATEGORIES-request'
export const GET_SINGLE_TICKETS_CATEGORIES_FAILURE = 'get-single-TICKETS_CATEGORIES-failure';
export const GET_SINGLE_TICKETS_CATEGORIES_SUCCESS = 'get-single-TICKETS_CATEGORIES-success';

export const POST_TICKETS_CATEGORIES_REQUEST = "POST_TICKETS_CATEGORIES_REQUEST";
export const POST_TICKETS_CATEGORIES_FAILURE = "post-TICKETS_CATEGORIES-failure";
export const POST_TICKETS_CATEGORIES_SUCCESS = "post-TICKETS_CATEGORIES-success";

export const PUT_TICKETS_CATEGORIES_REQUEST = "PUT_TICKETS_CATEGORIES_REQUEST";
export const PUT_TICKETS_CATEGORIES_FAILURE = "put-TICKETS_CATEGORIES-failure";
export const PUT_TICKETS_CATEGORIES_SUCCESS = "put-TICKETS_CATEGORIES-success";

export const DELETE_TICKETS_CATEGORIES_REQUEST = "DELETE_TICKETS_CATEGORIES_REQUEST";
export const DELETE_TICKETS_CATEGORIES_FAILURE = "delete-TICKETS_CATEGORIES-failure";
export const DELETE_TICKETS_CATEGORIES_SUCCESS = "delete-TICKETS_CATEGORIES-success";

export const CLEAN_TICKETS_CATEGORIES_STORE = "CLEAN_TICKETS_CATEGORIES_STORE";