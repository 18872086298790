import React from "react";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { FieldSkeleton } from "./Skeleton";

export default function MenuSelectInput({
  fieldName,
  fieldLabel,
  formik,
  data,
  handlePreviewData,
  getNextFieldData,
  setPropertyLocation,
  dependsOn,
  dependentFields,
  loading,
}) {
  const dispatch = useDispatch();

  return (
    <>
      {loading ? (
        <FieldSkeleton />
      ) : (
        <FormControl sx={{ mb: 1 }}>
          <TextField
            data-testid={`input-${fieldName}`}
            key={fieldName}
            fullWidth
            select
            id={fieldName}
            name={fieldName}
            label={fieldLabel}
            disabled={dependsOn && !formik.values[dependsOn]}
            value={formik.values[fieldName] || ""}
            error={
              formik.touched[fieldName] && Boolean(formik.errors[fieldName])
            }
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            onChange={(e) => {
              formik.handleChange(e);
              handlePreviewData(fieldName, name);
              getNextFieldData && dispatch(getNextFieldData(e.target.value));

              dependentFields &&
                dependentFields.map((field) => {
                  formik.setFieldValue(field, "");
                  handlePreviewData([field], "");
                });
            }}
          >
            {data.data && data.data.length > 0 ? (
              data.data.map((item, index) => (
                <MenuItem
                  data-testid={`select-option-${fieldName}-${index}`}
                  key={index}
                  value={item.value}
                >
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No options</MenuItem>
            )}
          </TextField>
        </FormControl>
      )}
    </>
  );
}
