import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import SortIcon from "@mui/icons-material/Sort";
import { useNavigate } from "react-router-dom";
import JumboSearch from "@jumbo/components/JumboSearch";
import { appTheme } from "app/themes/app/theme";
import { PivoContext } from "../../PivoProvider";
import { PIVOT_ORDER_BY_OPTIONS, PIVOT_STATUS } from "../../utils/constants";
import {
  useFetchCompaniesForSelectQuery,
  useFetchCompaniesQuery,
} from "../../features/Company/company-slice";

function PivoListHeader() {
  const company = useFetchCompaniesQuery();
  const pivot = useContext(PivoContext);
  const navigate = useNavigate();

  return (
    <Div>
      <Div className="flex min-[1650px]:flex-row flex-col py-4 justify-between">
        <Typography className="flex items-center" variant={"h2"}>
          Lista de Pivôs
        </Typography>

        <Div className="flex flex-wrap xl:flex-row items-center">
          <Div className="filter-item sm:flex hidden">
            <FormControl
              className="m-2 2xl:min-w-[400px] min-w-[280px]"
              sx={{ minWidth: 200 }}
              size="small"
            >
              <Autocomplete
                filterSelectedOptions={true}
                options={company.data || []}
                loading={company.isLoading}
                fullWidth
                InputProps={{
                  autoComplete: "off",
                }}
                onChange={(_, value) =>
                  pivot.setCompanyId(value !== null ? value.id : "")
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    className="p-0"
                    {...params}
                    fullWidth
                    id={"companyIdFilter"}
                    name={"companyIdFilter"}
                    label={"Filtrar por empresa"}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        paddingTop: 0,
                        paddingBottom: 0,
                        height: 38,
                      },
                      endAdornment: (
                        <React.Fragment>
                          {company.isLoading || company.isFetching ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        lineHeight: "0.5rem",
                        overflow: "visible",
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Div>
          <Div className="filter-item sm:flex hidden">
            <FormControl
              className="m-2 2xl:min-w-[200px] min-w-[142px]"
              sx={{ minWidth: 200 }}
              size="small"
            >
              <InputLabel id="demo-select-small-status">Status</InputLabel>
              <Select
                labelId="demo-select-small-status"
                id="demo-select-small-status"
                value={pivot.need_oil_change}
                label="Status"
                onChange={(value) => pivot.setNeedOilChange(value.target.value)}
              >
                {PIVOT_STATUS.map((field, index) => (
                  <MenuItem key={index} value={field.value}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Div>

          <Div className="filter-item sm:flex hidden mr-2">
            <FormControl
              className="m-2 2xl:min-w-[200px] min-w-[142px]"
              sx={{ minWidth: 250 }}
              size="small"
            >
              <InputLabel id="demo-select-small-order">Ordenar por</InputLabel>
              <Select
                labelId="demo-select-small-order"
                id="demo-select-small-order"
                value={pivot.orderBy}
                label="Order"
                onChange={(event) => {
                  pivot.setOrderBy(event.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Selecione...</em>
                </MenuItem>
                {PIVOT_ORDER_BY_OPTIONS.map((field, index) => (
                  <MenuItem key={index} value={field.value}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              sx={{
                m: 1,
                borderColor: appTheme.palette.red100,
                color: appTheme.palette.red100,
                "&:hover": {
                  borderColor: appTheme.palette.red200,
                },
              }}
              variant="outlined"
              onClick={pivot.setOrder}
            >
              <SortIcon
                sx={{
                  transform: `rotateX(${pivot.order === "desc" ? 0 : 180}deg)`,
                }}
              />
            </Button>
          </Div>

          <FormControl className="min-[1500px]:w-[212px] w-[130px]">
            <JumboSearch
              onChange={(e) => {
                pivot.setPage(1);
                pivot.setSearchKey(e);
              }}
              placeholder="Pesquisar"
              sx={{
                display: { md: "block" },
                flex: 1,
                bgcolor: "#FFF",
              }}
            />
          </FormControl>

          <Button
            data-testid="btn-new-so"
            id="btn-new-so"
            variant="contained"
            color="success"
            sx={{
              ml: 3,
            }}
            onClick={() => pivot.setPivoModalOpen()}
          >
            Novo
          </Button>
        </Div>
      </Div>
    </Div>
  );
}

export default PivoListHeader;
