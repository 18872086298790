import JumboSearch from "@jumbo/components/JumboSearch";
import {
  Autocomplete,
  Button,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { IndexListingContext } from "./IndexListingProvider";
import { appTheme } from "app/themes/app/theme";
import SortIcon from "@mui/icons-material/Sort";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

const OrderIcon = () => {
  const { searchParams, setSearchParams } = useContext(IndexListingContext);
  return (
    <Button
      className="h-9"
      sx={{
        borderColor: appTheme.palette.red100,
        color: appTheme.palette.red100,
        "&:hover": {
          borderColor: appTheme.palette.red200,
        },
      }}
      variant="outlined"
      onClick={() =>
        setSearchParams(
          searchParams.order === "asc"
            ? { ...searchParams, order: "desc" }
            : { ...searchParams, order: "asc" }
        )
      }
    >
      <SortIcon
        sx={{
          transform: `rotateX(${searchParams.order === "desc" ? 0 : 180}deg)`,
        }}
      />
    </Button>
  );
};
const AutoCompleteSearchInput = ({ headerFilter }) => {
  const { searchParams, setSearchParams } = useContext(IndexListingContext);
  React.useEffect(() => {
    if (headerFilter.defaultValue && !searchParams[headerFilter.key]) {
      setSearchParams({
        ...searchParams,
        [headerFilter.key]: headerFilter.defaultValue,
      });
    }
    console.log("headerFilter", headerFilter, searchParams);
  }, []);

  return (
    <FormControl
      className="flex flex-row w-full items-center gap-2"
      size="small"
    >
      <Autocomplete
        filterSelectedOptions={true}
        options={headerFilter.data || []}
        fullWidth
        value={
          headerFilter.data?.find(
            (option) => option.id === searchParams[headerFilter.key]
          ) || null
        }
        onChange={(_, value) =>
          setSearchParams(
            value !== null
              ? { ...searchParams, [headerFilter.key]: value.id }
              : { ...searchParams, [headerFilter.key]: undefined }
          )
        }
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={headerFilter.key}
            name={headerFilter.key}
            label={headerFilter.label}
            InputProps={{
              ...params.InputProps,
              style: {
                paddingTop: 0,
                paddingBottom: 0,
                height: 38,
              },
            }}
            InputLabelProps={{
              style: {
                lineHeight: "0.5rem",
                overflow: "visible",
              },
            }}
          />
        )}
      />
      {headerFilter.key === "orderBy" && <OrderIcon />}
    </FormControl>
  );
};
function IndexListingRTKHeader({ headerFilters, children, newRoute }) {
  const { searchParams, setSearchParams } = useContext(IndexListingContext);
  const handleSearch = debounce((text) => {
    setSearchParams({
      ...searchParams,
      s: text,
    });
  }, 100);
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-row justify-between">
        <Typography className="flex items-center" variant={"h2"}>
          {children}
        </Typography>
        <Button
          data-testid="btn-new-so"
          id="btn-new-so"
          variant="contained"
          color="success"
          sx={{
            ml: 3,
          }}
          onClick={() => navigate(newRoute)}
        >
          Novo
        </Button>
      </div>
      <div
        className={`py-4 grid gap-2`}
        style={{
          gridTemplateColumns: `repeat(${
            headerFilters.length + 1
          }, minmax(0, 1fr))`,
        }}
      >
        {headerFilters.map((headerFilter, index) => (
          <AutoCompleteSearchInput key={index} headerFilter={headerFilter} />
        ))}
        <FormControl className="w-full">
          <JumboSearch
            onChange={(text) =>
              setSearchParams({
                ...searchParams,
                s: text,
              })
            }
            placeholder="Pesquisar"
            sx={{
              display: { md: "block" },
              flex: 1,
              bgcolor: "#FFF",
            }}
          />
        </FormControl>
      </div>
    </div>
  );
}

export default IndexListingRTKHeader;
