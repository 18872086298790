//TICKETS Types

export const START_NEW_TICKET_REQUEST = "START_NEW_TICKET_REQUEST";
export const START_NEW_TICKET_FAILURE = "START_NEW_TICKET_FAILURE";
export const START_NEW_TICKET_SUCCESS = "START_NEW_TICKET_SUCCESS";

export const GET_SINGLE_TICKETS_DETAIL_REQUEST = 'get-single-TICKETS-DETAIL-request'
export const GET_SINGLE_TICKETS_DETAIL_FAILURE = 'get-single-TICKETS-DETAIL-failure';
export const GET_SINGLE_TICKETS_DETAIL_SUCCESS = 'get-single-TICKETS-DETAIL-success';

export const CLEAN_TICKETS_DETAILS_STORE = "CLEAN_TICKETS_DETAILS_STORE";