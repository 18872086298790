import {
  GET_READONLY_REQUEST,
  GET_READONLY_SUCCESS,
  GET_READONLY_FAILURE,

  CLEAN_READONLY_STORE,
  
  DELETE_READONLY_REQUEST,
  DELETE_READONLY_SUCCESS,
  DELETE_READONLY_FAILURE,

  POST_READONLY_REQUEST,
  POST_READONLY_FAILURE,
  POST_READONLY_SUCCESS,

  PUT_READONLY_REQUEST,
  PUT_READONLY_FAILURE,
  PUT_READONLY_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function readonly(state = initialState, action) {
  switch (action.type) {
    case GET_READONLY_REQUEST:
    case DELETE_READONLY_REQUEST:
    case POST_READONLY_REQUEST:
    case PUT_READONLY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_READONLY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_READONLY_SUCCESS:
    case POST_READONLY_SUCCESS:
    case PUT_READONLY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_READONLY_FAILURE:
    case POST_READONLY_FAILURE:
    case DELETE_READONLY_FAILURE:
    case PUT_READONLY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_READONLY_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
