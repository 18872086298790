import Div from "@jumbo/shared/Div";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import SortIcon from "@mui/icons-material/Sort";
import { useParams } from "react-router-dom";
import JumboSearch from "@jumbo/components/JumboSearch";
import { appTheme } from "app/themes/app/theme";
import { PivoContext } from "../../PivoProvider";

import Loading from "app/components/Loading";
import { useFetchSinglePivoQuery } from "../../features/pivos/pivos-slice";
import { REDUCER_ORDER_BY_OPTIONS } from "../../utils/constants";

function ReducersListHeader() {
  const { id } = useParams();
  const {
    setOpenReducerEdit,
    reducerSearch,
    setReducerPage,
    setReducerOrder,
    setReducerOrderBy,
    setReducerSearch,
  } = useContext(PivoContext);
  const pivoDetails = useFetchSinglePivoQuery(id);

  if (pivoDetails.isLoading || pivoDetails.isFetching) {
    return <Loading />;
  }

  return (
    <Div>
      <Div className="flex min-[1650px]:flex-row flex-col py-4 justify-between">
        <Typography className="flex items-center" variant={"h2"}>
          Lista de Redutores do Pivô #{pivoDetails.data.serial_number}
        </Typography>

        <Div className="flex flex-wrap xl:flex-row items-center">
          <Div className="filter-item sm:flex hidden mr-2">
            <FormControl
              className="m-2 2xl:min-w-[200px] min-w-[142px]"
              sx={{ minWidth: 250 }}
              size="small"
            >
              <InputLabel id="demo-select-small-order">Ordenar por</InputLabel>
              <Select
                labelId="demo-select-small-order"
                id="demo-select-small-order"
                value={reducerSearch.orderBy}
                label="Order"
                onChange={(event) => {
                  setReducerOrderBy(event.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Selecione...</em>
                </MenuItem>
                {REDUCER_ORDER_BY_OPTIONS.map((field, index) => (
                  <MenuItem key={index} value={field.value}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              sx={{
                m: 1,
                borderColor: appTheme.palette.red100,
                color: appTheme.palette.red100,
                "&:hover": {
                  borderColor: appTheme.palette.red200,
                },
              }}
              variant="outlined"
              onClick={() => setReducerOrder()}
            >
              <SortIcon
                sx={{
                  transform: `rotateX(${
                    reducerSearch.order === "desc" ? 0 : 180
                  }deg)`,
                }}
              />
            </Button>
          </Div>

          <FormControl className="min-[1500px]:w-[212px] w-[130px]">
            <JumboSearch
              onChange={(e) => {
                setReducerPage(1);
                setReducerSearch(e);
              }}
              placeholder="Pesquisar"
              sx={{
                display: { md: "block" },
                flex: 1,
                bgcolor: "#FFF",
              }}
            />
          </FormControl>

          <Button
            data-testid="btn-new-so"
            id="btn-new-so"
            variant="contained"
            color="success"
            sx={{
              ml: 3,
            }}
            onClick={() => setOpenReducerEdit()}
          >
            Novo
          </Button>
        </Div>
      </Div>
    </Div>
  );
}

export default ReducersListHeader;
