import React from "react";
import PivoSidebar from "./PivoSidebar";
import { Grid } from "@mui/material";

function PivoDetailsLayout({ children }) {
  return (
    <>
      <Grid container>
        <Grid item xs={2}>
          <PivoSidebar />
        </Grid>
        <Grid item xs={10}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}

export default PivoDetailsLayout;
