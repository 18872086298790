export const PIVO_ACTIONS = {
  SET_PIVO: "set-pivo",
  SET_SEARCH_KEY: "set-search",
  SET_ORDER_BY: "set-order-by",
  SET_ORDER: "set-order",
  SET_PAGE: "set-page",
  SET_COMPANY_ID: "set-company-id",
  SET_NEED_OIL_CHANGE: "set-need-oil-change",
  RESET_PIVO_SEARCH: "reset-pivo-search",
  SET_OPEN_MODAL: "set-open-modal",
  SET_REDUCER_EDIT: "set-reducer-edit",
  SET_PIVOT_EDIT: "set-pivot-edit",
  SET_OPEN_PIVOT_EDIT: "set-open-pivot-edit",
  SET_OPEN_REDUCER_EDIT: "set-open-reducer-edit",
  SET_COMPANY_ID_FOR_UNIT_CONTROL: "set-company-id-for-unit-control",
  SET_COMPANY_FOR_SELECT: "set-company-for-select",
  SET_REDUCERS_SEARCH: "set-reducers-search",
  SET_PIVO_MENU: "set-pivo-menu",
};

export const REDUCERS_ACTIONS = {
  SET_REDUCERS_SEARCH: "set-reducers-search",
  SET_REDUCER_OPEN_EDIT: "set-reducer-open-edit",
  SET_REDUCER_EDIT_ITEM: "set-reducer-edit-item",
  SET_REDUCER_SEARCH: "set-reducer-search",
  SET_REDUCER_PAGE: "set-reducer-page",
  SET_REDUCER_ORDER_BY: "set-reducer-order-by",
  SET_REDUCER_ORDER: "set-reducer-order",
};

export const PIVOT_ORDER_BY_OPTIONS = [
  { value: "id", label: "Id" },
  { value: "serial_number", label: "Número de série" },
  { value: "last_oil_change", label: "Última troca de óleo" },
  { value: "need_oil_change", label: "Precisa de troca de óleo" },
];
export const REDUCER_ORDER_BY_OPTIONS = [
  { value: "id", label: "Id" },
  { value: "has_leak", label: "Vazamento" },
  { value: "viscosity", label: "Viscosidade" },
  { value: "oil_level", label: "Nível do óleo" },
];

export const PIVOT_STATUS = [
  {
    value: null,
    label: "Todos",
  },
  {
    value: true,
    label: "Precisa troca",
  },
  {
    value: false,
    label: "Não precisa troca",
  },
];

export const MENU_NAMES = {
  PIVO_REDUCERS: "redutores",
  PIVO_DASHBOARD: "dashboard",
  PIVO_HISTORY: "historico",
};
