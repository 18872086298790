import { AUTHLOGIN, AUTHLOGOUT } from "app/utils/constants/auth";

export function login(token, user) {
    return {
        type: AUTHLOGIN,
        payload: { token, user }
    }
}

export function logout() {
    return {
        type: AUTHLOGOUT
    }
}
