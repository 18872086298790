import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const reducerApiSlice = createApi({
  reducerPath: "reducerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchReducers: builder.query({
        query(values) {
          const queryStrings = [];
          if (values?.page) {
            queryStrings.push(`page=${values.page}`);
          }
          if (values?.orderBy) {
            queryStrings.push(`orderBy=${values.orderBy},${values.order}`);
          }
          if (values?.s) {
            queryStrings.push(`s=${values.s}`);
          }
          if (values?.pivot_id) {
            queryStrings.push(`pivot_id=${values.pivot_id}`);
          }

          return `pivots/reducers/?${queryStrings.join("&")}`;
        },
        providesTags: ["Reducer"],
      }),
      createReducer: builder.mutation({
        query(values) {
          if (values.last_oil_verification) {
            values.last_oil_verification =
              values.last_oil_verification.format("YYYY-MM-DD");
          }
          if (values.date_last_oil_change) {
            values.date_last_oil_change =
              values.date_last_oil_change.format("YYYY-MM-DD");
          }

          return {
            url: "pivots/reducers",
            method: "POST",
            body: values,
          };
        },
        invalidatesTags: ["Reducer"],
      }),
      editReducer: builder.mutation({
        query(values) {
          if (values.last_oil_verification) {
            values.last_oil_verification =
              values.last_oil_verification.format("YYYY-MM-DD");
          }
          if (values.date_last_oil_change) {
            values.date_last_oil_change =
              values.date_last_oil_change.format("YYYY-MM-DD");
          }

          return {
            url: `pivots/reducers/${values.id}`,
            method: "PUT",
            body: values,
          };
        },
        invalidatesTags: ["Reducer"],
      }),
      deleteReducer: builder.mutation({
        query(values) {
          return {
            url: `pivots/reducers/${values}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["Reducer"],
      }),
    };
  },
});

export const {
  useFetchReducersQuery,
  useCreateReducerMutation,
  useEditReducerMutation,
  useDeleteReducerMutation,
} = reducerApiSlice;
