import { SET_ALERT } from "app/redux/modules/Alerts/constants";
import React from "react";
import { useDispatch } from "react-redux";

function useToast() {
  const dispatch = useDispatch();
  function dispatchToast({ message, variant }) {
    dispatch({
      type: SET_ALERT,
      payload: { message: message, variant: variant },
    });
  }
  return { dispatchToast };
}

export default useToast;
