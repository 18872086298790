import { FormControl, MenuItem, TextField } from "@mui/material";
import { freeTimes } from "../../untils/constants";

function InitialHourSo({ formik, handlePreviewData }) {
  return (
    <FormControl sx={{ mb: 1 }}>
      <TextField
        fullWidth
        data-testid={"input-time"}
        select
        disabled={formik.values.scheduled_at === null}
        id="time"
        name="time"
        label="Horário Início"
        value={formik.values.time}
        error={formik.touched.time && Boolean(formik.errors.time)}
        helperText={formik.touched.time && formik.errors.time}
        onChange={(e) => {
          formik.handleChange(e);
          formik.setFieldValue("time_end", "");

          handlePreviewData((previewData) => ({
            ...previewData,
            scheduled_at: [previewData.scheduled_at[0], e.target.value],
          }));
        }}
      >
        {freeTimes.map((time, index) => (
          <MenuItem
            key={index}
            data-testid={`select-option-time-${index}`}
            value={time}
          >
            {time}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}

export default InitialHourSo;
