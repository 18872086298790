import {
  GET_FILTER_PRODUCT_MODEL_SUCCESS,
  GET_FILTER_PRODUCT_MODEL_FAILURE,
  GET_FILTER_PRODUCT_MODEL_REQUEST,
  GET_USER_TO_EDIT_REQUEST,
  GET_USER_TO_EDIT_SUCCESS,
  GET_USER_TO_EDIT_FAILURE,

} from "./constants";
import api from "app/services/config";

export const getProductModelsFilter =
  () => async (dispatch, getState) => {
    dispatch({ type: GET_FILTER_PRODUCT_MODEL_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.get(`/product-models`, {
        params: {
          orderBy: `name,asc`,
          status: 1,
          page: 1,
          s: "",
          noPaginate: true,
          hasProductModelsParts: true

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_FILTER_PRODUCT_MODEL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FILTER_PRODUCT_MODEL_FAILURE,
        payload: error.message,
      });
    }
  };

export const getUserToEdit =
  (user_id) => async (dispatch, getState) => {
    console.log("HERE")

    dispatch({ type: GET_USER_TO_EDIT_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.get(`/users/${user_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      console.log(response.data)

      dispatch({
        type: GET_USER_TO_EDIT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_TO_EDIT_FAILURE,
        payload: error.message,
      });
    }
  };