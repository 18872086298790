import mock from "..";
import { tasks } from "../mock-data/schedule";

mock.onGet("tecnico/schedule").reply(request => {
    const id = request.tecId;

    return [
        200,
        {
            tasks: tasks[id - 1].tasks
        }
    ];
})