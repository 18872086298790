import React, { useCallback } from "react";
import { MENU_NAMES, PIVO_ACTIONS, REDUCERS_ACTIONS } from "./utils/constants";
import {
  useFetchCompaniesForSelectQuery,
  useFetchCompaniesQuery,
  useFetchUnitsForSelectQuery,
} from "./features/Company/company-slice";
import { reducer } from "app/shared/widgets/NewRequests/reducer";

const PivoContext = React.createContext(null);

function init(initialValue) {
  return {
    page: initialValue.page,
    order: initialValue.order,
    order_by: initialValue.order_by,
    s: initialValue.s,
    need_oil_change: initialValue.need_oil_change,
    company_id: initialValue.company_id,
    pivotEditItem: initialValue.pivotEditItem,
    openPivotEdit: initialValue.openPivotEdit,
    reducerSearch: initialValue.reducerSearch,
    reducerEditItem: initialValue.reducerEditItem,
    openReducerEdit: initialValue.openReducerEdit,
    menuPivo: initialValue.menuPivo,
  };
}

function appReducer(state, action) {
  switch (action.type) {
    case PIVO_ACTIONS.SET_PIVO_MENU:
      return {
        ...state,
        menuPivo: action.payload,
      };
    case PIVO_ACTIONS.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case PIVO_ACTIONS.SET_COMPANY_ID_FOR_UNIT_CONTROL:
      return {
        ...state,
        companyIdForUnitControl: action.payload,
      };

    case PIVO_ACTIONS.SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case PIVO_ACTIONS.SET_ORDER_BY:
      return {
        ...state,
        order_by: action.payload,
      };
    case PIVO_ACTIONS.SET_SEARCH_KEY:
      return {
        ...state,
        s: action.payload,
      };
    case PIVO_ACTIONS.SET_COMPANY_ID:
      return {
        ...state,
        company_id: action.payload,
      };
    case PIVO_ACTIONS.SET_NEED_OIL_CHANGE:
      return {
        ...state,
        need_oil_change: action.payload,
      };
    case PIVO_ACTIONS.SET_PIVOT_EDIT:
      return {
        ...state,
        pivotEditItem: action.payload,
      };
    case PIVO_ACTIONS.SET_REDUCER_EDIT:
      return {
        ...state,
        reducerEdit: action.payload,
      };
    case PIVO_ACTIONS.SET_OPEN_PIVOT_EDIT:
      return {
        ...state,
        openPivotEdit: action.payload,
      };
    case PIVO_ACTIONS.SET_OPEN_REDUCER_EDIT:
      return {
        ...state,
        openReducerEdit: action.payload,
      };

    case REDUCERS_ACTIONS.SET_REDUCER_EDIT_ITEM:
      return {
        ...state,
        reducerEditItem: action.payload,
      };
    case REDUCERS_ACTIONS.SET_REDUCER_OPEN_EDIT:
      return {
        ...state,
        openReducerEdit: action.payload,
      };
    case REDUCERS_ACTIONS.SET_REDUCER_OPEN_EDIT:
      return {
        ...state,
        openReducerEdit: action.payload,
      };
    case REDUCERS_ACTIONS.SET_REDUCER_SEARCH:
      return {
        ...state,
        reducerSearch: {
          ...state.reducerSearch,
          s: action.payload,
        },
      };
    case REDUCERS_ACTIONS.SET_REDUCER_PAGE:
      return {
        ...state,
        reducerSearch: {
          ...state.reducerSearch,
          page: action.payload,
        },
      };
    case REDUCERS_ACTIONS.SET_REDUCER_ORDER_BY:
      return {
        ...state,
        reducerSearch: {
          ...state.reducerSearch,
          order_by: action.payload,
        },
      };
    case REDUCERS_ACTIONS.SET_REDUCER_ORDER:
      return {
        ...state,
        reducerSearch: {
          ...state.reducerSearch,
          order: action.payload,
        },
      };

    default:
      return state;
  }
}

const PivoProvider = ({ children }) => {
  const [app, setApp] = React.useReducer(
    appReducer,
    {
      page: 1,
      order: "asc",
      order_by: "id",
      company_id: undefined,
      need_oil_change: undefined,
      s: "",
      pivotEditItem: undefined,
      openPivotEdit: false,
      reducerSearch: {
        page: 1,
        order: "asc",
        order_by: "id",
        s: "",
      },
      openReducerEdit: false,
      reducerEditItem: undefined,
      menuPivo: MENU_NAMES.PIVO_DASHBOARD,
    },
    init
  );

  React.useEffect(() => {
    console.log(app);
    console.log(app.reducerSearch);
  }, [app, setApp]);

  const setMenuPivo = useCallback(
    (value) => {
      setApp({ type: PIVO_ACTIONS.SET_PIVO_MENU, payload: value });
    },
    [setApp]
  );
  const setReducerSearch = useCallback(
    (value) => {
      setApp({ type: REDUCERS_ACTIONS.SET_REDUCER_SEARCH, payload: value });
    },
    [setApp]
  );

  const setReducerPage = useCallback(
    (value) => {
      setApp({ type: REDUCERS_ACTIONS.SET_REDUCER_PAGE, payload: value });
    },
    [setApp]
  );

  const setReducerOrder = useCallback(() => {
    app.reducerSearch.order === "asc"
      ? setApp({ type: REDUCERS_ACTIONS.SET_REDUCER_ORDER, payload: "desc" })
      : setApp({ type: REDUCERS_ACTIONS.SET_REDUCER_ORDER, payload: "asc" });
  }, [app, setApp]);

  const setReducerOrderBy = useCallback(
    (value) => {
      setApp({ type: REDUCERS_ACTIONS.SET_REDUCER_ORDER_BY, payload: value });
    },
    [setApp]
  );
  const setOpenReducerEdit = useCallback(() => {
    setApp({ type: REDUCERS_ACTIONS.SET_REDUCER_OPEN_EDIT, payload: true });
  }, [setApp]);
  const setReducerEditItem = useCallback(
    (value) => {
      setApp({ type: REDUCERS_ACTIONS.SET_REDUCER_EDIT_ITEM, payload: value });
    },
    [setApp]
  );
  const setPivotEdit = useCallback(
    (value) => {
      setApp({ type: PIVO_ACTIONS.SET_PIVOT_EDIT, payload: value });
    },
    [setApp]
  );

  const setPivoModalOpen = useCallback(() => {
    setApp({ type: PIVO_ACTIONS.SET_OPEN_PIVOT_EDIT, payload: true });
  }, [setApp]);

  const setReducerEdit = useCallback(
    (value) => {
      setApp({ type: PIVO_ACTIONS.SET_REDUCER_EDIT, payload: value });
    },
    [setApp]
  );
  const setPage = useCallback(
    (value) => {
      setApp({ type: PIVO_ACTIONS.SET_PAGE, payload: value });
    },
    [setApp]
  );

  const setNeedOilChange = useCallback(
    (value) => {
      setApp({ type: PIVO_ACTIONS.SET_NEED_OIL_CHANGE, payload: value });
    },
    [setApp]
  );
  const setCompanyId = useCallback(
    (value) => {
      setApp({ type: PIVO_ACTIONS.SET_COMPANY_ID, payload: value });
    },
    [setApp]
  );
  const setSearchKey = useCallback(
    (value) => {
      setApp({ type: PIVO_ACTIONS.SET_SEARCH_KEY, payload: value });
    },
    [setApp]
  );
  const setOrderBy = useCallback(
    (value) => {
      setApp({ type: PIVO_ACTIONS.SET_ORDER_BY, payload: value });
    },
    [setApp]
  );

  const setOrder = useCallback(() => {
    app.order === "asc"
      ? setApp({ type: PIVO_ACTIONS.SET_ORDER, payload: "desc" })
      : setApp({ type: PIVO_ACTIONS.SET_ORDER, payload: "asc" });
  }, [app, setApp]);

  const setCloseModal = useCallback(() => {
    setApp({ type: PIVO_ACTIONS.SET_OPEN_REDUCER_EDIT, payload: false });
    setApp({ type: PIVO_ACTIONS.SET_OPEN_PIVOT_EDIT, payload: false });
    setApp({ type: PIVO_ACTIONS.SET_REDUCER_EDIT, payload: undefined });
    setApp({ type: PIVO_ACTIONS.SET_PIVOT_EDIT, payload: undefined });
    setApp({ type: REDUCERS_ACTIONS.SET_REDUCER_OPEN_EDIT, payload: false });
    setApp({
      type: REDUCERS_ACTIONS.SET_REDUCER_EDIT_ITEM,
      payload: undefined,
    });
  }, [setApp]);

  const contextValue = React.useMemo(
    () => ({
      ...app,
      setPage,
      setOrder,
      setOrderBy,
      setReducerSearch,
      setReducerPage,
      setMenuPivo,
      setReducerOrder,
      setReducerOrderBy,
      setSearchKey,
      setCompanyId,
      setNeedOilChange,
      setCloseModal,
      setPivotEdit,
      setReducerEdit,
      setPivoModalOpen,
      setOpenReducerEdit,
      setReducerEditItem,
    }),
    [
      app,
      setPage,
      setOrder,
      setOrderBy,
      setSearchKey,
      setMenuPivo,
      setReducerSearch,
      setReducerPage,
      setReducerOrder,
      setReducerOrderBy,
      setCompanyId,
      setNeedOilChange,
      setCloseModal,
      setPivotEdit,
      setReducerEdit,
      setPivoModalOpen,
      setOpenReducerEdit,
      setReducerEditItem,
    ]
  );

  return (
    <PivoContext.Provider value={contextValue}>{children}</PivoContext.Provider>
  );
};

export { PivoContext };
export default PivoProvider;
