import { FormControl, TextField } from "@mui/material";

function TextSoField({
  formik,
  handlePreviewData,
  fieldName,
  fieldLabel,
  rows,
}) {
  return (
    <FormControl sx={{ mb: 1 }}>
      <TextField
        fullWidth
        multiline
        id={fieldName}
        name={fieldName}
        label={fieldLabel}
        rows={rows}
        value={formik.values[fieldName]}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        helperText={formik.touched[fieldName] && formik.errors[fieldName]}
        onChange={(e) => {
          formik.handleChange(e);
          handlePreviewData(fieldName, e.target.value);
        }}
      />
    </FormControl>
  );
}

export default TextSoField;
