import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import api from "app/services/config";
import vCard from "vcf";

function VcardReader({ url }) {
  const getCard = useCallback(
    async ({ url }) => {
      const { data } = await api.get(`proxy?url=${url}`);
      console.log(`resp`, data);
      const parsedCard = vCard.parse(data);

      const cardJson = parsedCard.map((entry) => ({
        version: entry.get("version"),
        prodid: entry.get("prodid"),
        fullName: entry.get("fn"),
        name: entry.get("n"),
        phone: entry.get("tel"),
      }));
      setCard(cardJson[0]);
    },
    [url]
  );

  const [card, setCard] = useState();

  useEffect(() => {
    if (card) return;
    getCard({ url });
    console.log(`card`, card);
  }, [card]);

  if (!card) {
    return <div>Loading vCard...</div>;
  }

  return (
    <div>
      <h1>Compartilhamento de contato vindo do cliente no whatsapp</h1>
      <pre className="p-3 border-solid border-gray-400 border-[1px] bg-gray-100">
        <p>Nome completo: {card.fullName._data}</p>
        {Array.isArray(card.phone) ? (
          card.phone.map((phone, index) => (
            <p key={index}>
              Telefone:{" "}
              <a href={`tel:${phone._data.replace(/[\s-]+/g, "")}`}>
                {phone._data}
              </a>
            </p>
          ))
        ) : (
          <p>
            Telefone:{" "}
            <a href={`tel:${card.phone._data.replace(/[\s-]+/g, "")}`}>
              {card.phone._data}
            </a>
          </p>
        )}
      </pre>
    </div>
  );
}

export default VcardReader;
