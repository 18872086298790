import {
  asyncResolutionCategoriesActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./style";
import { Stack } from "@mui/material";
import DynamicViewGenerator from "app/components/DynamicViewGenerator/DynamicViewGenerator";
import { DATA_TYPE_CHIP, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING, STATUS_INPUT, TEXT_INPUT } from "app/components/DynamicViewGenerator/constants";
import { CLEAN_PROBLEM_TYPES_STORE } from "app/redux/modules/Setup/ProblemTypes/constants";
import { CLEAN_RESOLUTION_CATEGORIES_STORE } from "app/redux/modules/Setup/ResolutionCategories/constants";
import * as Yup from "yup";

const ResolutionCategories = () => {
  const dispatch = useDispatch();
  const { resolutionCategories } = useSelector((state) => state);

  const startPage = async () => {
     dispatch(asyncResolutionCategoriesActions.get("asc", "id"));
  }

  useEffect(() => {
    startPage();
    return () => {
      dispatch({ type: CLEAN_RESOLUTION_CATEGORIES_STORE });
    }
  }, [dispatch]);
 



  const dataLabels = [
    {
      id: "Id",
      name: "Nome",   
      description: "Descrição",   
      created_at: "Data criação",
      status: "Status",

    },
  ];

  const dataType = [
    {
      id: DATA_TYPE_ID,
      name: DATA_TYPE_STRING,
      description: DATA_TYPE_STRING,
      status: DATA_TYPE_CHIP,
      created_at: DATA_TYPE_DATE,
      },
  ];

  const formFieldsType = [
    {
      type: TEXT_INPUT,
      value: "name",
      label: "Nome",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    }, 
        {
      type: TEXT_INPUT,
      value: "description",
      label: "Descrição",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },   
    {
      type: STATUS_INPUT,
      value: "status",
      label: "Status",
      required: true,
      action: "",
      validation: Yup.string().required("Obrigatório")
    },   
  ];


  const pageConfig = {
    title: "Categorias de Resolução",
    newText: "Nova categoria",
    searchText: "Pesquisar",
    data: resolutionCategories.data,
    loading: resolutionCategories.loading,
    actions: {
      delete: asyncResolutionCategoriesActions.delete,
      edit: asyncResolutionCategoriesActions.edit,
      get: asyncResolutionCategoriesActions.get,
      search: asyncResolutionCategoriesActions.get,
      create: asyncResolutionCategoriesActions.create,
      clean: { type: CLEAN_PROBLEM_TYPES_STORE },
    },
    createDataType: formFieldsType,
  };

  return (
    <Content>
      {
        <Stack style={{ marginTop: 10 }}>
          <DynamicViewGenerator
            pageConfig={pageConfig}
            route={"modelos"}
            type={dataType}
            labels={dataLabels}
          />
        </Stack>
      }
    </Content>
  );
};

export default ResolutionCategories;
