import {
  GET_ADMINISTRATORS_REQUEST,
  GET_ADMINISTRATORS_SUCCESS,
  GET_ADMINISTRATORS_FAILURE,

  CLEAN_ADMINISTRATORS_STORE,
  
  DELETE_ADMINISTRATORS_REQUEST,
  DELETE_ADMINISTRATORS_SUCCESS,
  DELETE_ADMINISTRATORS_FAILURE,

  POST_ADMINISTRATORS_REQUEST,
  POST_ADMINISTRATORS_FAILURE,
  POST_ADMINISTRATORS_SUCCESS,

  PUT_ADMINISTRATORS_REQUEST,
  PUT_ADMINISTRATORS_FAILURE,
  PUT_ADMINISTRATORS_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function administrators(state = initialState, action) {
  switch (action.type) {
    case GET_ADMINISTRATORS_REQUEST:
    case DELETE_ADMINISTRATORS_REQUEST:
    case POST_ADMINISTRATORS_REQUEST:
    case PUT_ADMINISTRATORS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ADMINISTRATORS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_ADMINISTRATORS_SUCCESS:
    case POST_ADMINISTRATORS_SUCCESS:
    case PUT_ADMINISTRATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_ADMINISTRATORS_FAILURE:
    case POST_ADMINISTRATORS_FAILURE:
    case DELETE_ADMINISTRATORS_FAILURE:
    case PUT_ADMINISTRATORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_ADMINISTRATORS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
