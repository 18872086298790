import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const SkeletonFamily = () => {
    return (        
        <Stack id="stack-usuario-skeleton" spacing={1} marginTop={1}>
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
            <Skeleton variant="rectangular" style={{ height: "100px", borderRadius: 10 }} />
        </Stack>
    )
};

export default SkeletonFamily;