import React from "react";
import { Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import PieChartViscosityGraph from "./PieChartViscosityGraph";

const PieChartViscosity = () => {
  return (
    <JumboCardQuick
      title={
        <Typography variant={"h6"} mb={0}>
          Viscosidade dos redutores
        </Typography>
      }
      noWrapper
      sx={{ textAlign: "center" }}
    >
      <PieChartViscosityGraph />
    </JumboCardQuick>
  );
};

export default PieChartViscosity;
