import {
  GET_MACROS_REQUEST,
  GET_MACROS_SUCCESS,
  GET_MACROS_FAILURE,

  CLEAN_MACROS_STORE,
  
  DELETE_MACROS_REQUEST,
  DELETE_MACROS_SUCCESS,
  DELETE_MACROS_FAILURE,

  POST_MACROS_REQUEST,
  POST_MACROS_FAILURE,
  POST_MACROS_SUCCESS,

  PUT_MACROS_REQUEST,
  PUT_MACROS_FAILURE,
  PUT_MACROS_SUCCESS,
} from "./constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function macros(state = initialState, action) {
  switch (action.type) {
    case GET_MACROS_REQUEST:
    case DELETE_MACROS_REQUEST:
    case POST_MACROS_REQUEST:
    case PUT_MACROS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_MACROS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_MACROS_SUCCESS:
    case POST_MACROS_SUCCESS:
    case PUT_MACROS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_MACROS_FAILURE:
    case POST_MACROS_FAILURE:
    case DELETE_MACROS_FAILURE:
    case PUT_MACROS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_MACROS_STORE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
