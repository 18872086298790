import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { useFetchTicketCategoryFieldQuery } from "app/redux/features/fields-slices";
import React from "react";

function CategoryField({ formikRef, ...props }) {
  const formik = formikRef;

  const { data, isLoading, isFetching } = useFetchTicketCategoryFieldQuery();
  return (
    <FormControl fullWidth className="bg-white">
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        onChange={(_, value) => {
          formik.setFieldValue("category_id", value !== null ? value.id : "");
        }}
        value={
          data?.data.find(
            (option) => option.id === formik.values.category_id
          ) || null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            className="w-full text-sm text-gray-500"
            size="medium"
            fullWidth
            id={"category_id"}
            name={"category_id"}
            label={"Categoria do Ticket"}
            InputLabelProps={{
              className: "text-sm pt-[3px]",
            }}
            error={
              formik.touched.category_id && Boolean(formik.errors.category_id)
            }
            helperText={formik.touched.category_id && formik.errors.category_id}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default CategoryField;
