import { asyncUploadPhotoActions } from "app/redux/reducers";
import {
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_REQUEST,
  DELETE_CUSTOMERS_FAILURE,
  DELETE_CUSTOMERS_REQUEST,
  DELETE_CUSTOMERS_SUCCESS,
  POST_CUSTOMERS_REQUEST,
  POST_CUSTOMERS_FAILURE,
  POST_CUSTOMERS_SUCCESS,
  PUT_CUSTOMERS_REQUEST,
  PUT_CUSTOMERS_FAILURE,
  PUT_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_TICKETS_REQUEST,
  GET_CUSTOMERS_TICKETS_SUCCESS,
  GET_CUSTOMERS_TICKETS_FAILURE,
} from "./constants";
import api from "app/services/config";
import { SET_ALERT } from "../../Alerts/constants";
import { instanceOf } from "prop-types";
import { normalizePhone } from "app/utils/appHelpers";

export const getCustomers =
  (order, orderBy, status, page, searchKey, noPaginate, unit_id) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_CUSTOMERS_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/users`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: status ? status : search.data.status,
          page: page ? page : search.data.page,
          s: searchKey,
          noPaginate: noPaginate,
          role_id: 2,
          unit_id: unit_id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_CUSTOMERS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMERS_FAILURE,
        payload: error.message,
      });
    }
  };

export const getCustomersTickets =
  (order, orderBy, status, page, searchKey, noPaginate, unit_id) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_CUSTOMERS_TICKETS_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/users`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          status: status ? status : search.data.status,
          page: page ? page : search.data.page,
          s: searchKey,
          noPaginate: noPaginate,
          unit_id: unit_id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_CUSTOMERS_TICKETS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMERS_TICKETS_FAILURE,
        payload: error.message,
      });
    }
  };

export const getCustomerById = (user_id) => async (dispatch, getState) => {
  dispatch({ type: GET_CUSTOMERS_REQUEST });

  try {
    const { auth, search } = getState();
    const response = await api.get(`/users/${user_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: GET_CUSTOMERS_SUCCESS,
      payload: { data: [response.data] },
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMERS_FAILURE,
      payload: error.message,
    });
  }
};

export const deleteCustomers = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_CUSTOMERS_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/users/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_CUSTOMERS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: DELETE_CUSTOMERS_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" },
    });
  } finally {
    dispatch(getCustomers("asc", "id"));
  }
};
export const createCustomers = (data) => async (dispatch, getState) => {
  dispatch({ type: POST_CUSTOMERS_REQUEST });
  data.phone = normalizePhone(data.phone);

  try {
    const { auth } = getState();
    const response = await api.post(
      `/users`,
      {
        name: data.name,
        last_name: data.last_name,
        cpf: data.cpf,
        email: data.email,
        password: data.password,
        phone: data.phone,
        role: "CLIENT",
        company_id: data.company_id,
        unit_id: data.unit_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );
    if (data.avatar instanceof File) {
      await dispatch(
        asyncUploadPhotoActions.post(data.avatar, response.data.user.id)
      );
    }
    dispatch({
      type: POST_CUSTOMERS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Criado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: POST_CUSTOMERS_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao criar", variant: "error" },
    });
  } finally {
    dispatch(getCustomers("asc", "id"));
  }
};

export const editCustomers = (data, id) => async (dispatch, getState) => {
  dispatch({ type: PUT_CUSTOMERS_REQUEST });
  data.phone = normalizePhone(data.phone);

  try {
    const { auth } = getState();
    const response = await api.put(
      `/users/${id}`,
      {
        name: data.name,
        last_name: data.last_name,
        cpf: data.cpf,
        email: data.email,
        password: data.password,
        phone: data.phone,
        role: "CLIENT",
        company_id: data.company_id,
        unit_id: data.unit_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );
    if (data.avatar instanceof File) {
      await dispatch(asyncUploadPhotoActions.post(data.avatar, id));
    }
    dispatch({
      type: PUT_CUSTOMERS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Editado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: PUT_CUSTOMERS_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao editar", variant: "error" },
    });
  } finally {
    dispatch(getCustomers("asc", "id"));
  }
};
