//READONLY Types

export const GET_READONLY_REQUEST = "GET_READONLY_REQUEST";
export const GET_READONLY_FAILURE = "GET_READONLY_FAILURE";
export const GET_READONLY_SUCCESS = "GET_READONLY_SUCCESS";

export const GET_SINGLE_READONLY_REQUEST = 'get-single-READONLY-request'
export const GET_SINGLE_READONLY_FAILURE = 'get-single-READONLY-failure';
export const GET_SINGLE_READONLY_SUCCESS = 'get-single-READONLY-success';

export const POST_READONLY_REQUEST = "POST_READONLY_REQUEST";
export const POST_READONLY_FAILURE = "post-READONLY-failure";
export const POST_READONLY_SUCCESS = "post-READONLY-success";

export const PUT_READONLY_REQUEST = "PUT_READONLY_REQUEST";
export const PUT_READONLY_FAILURE = "put-READONLY-failure";
export const PUT_READONLY_SUCCESS = "put-READONLY-success";

export const DELETE_READONLY_REQUEST = "DELETE_READONLY_REQUEST";
export const DELETE_READONLY_FAILURE = "delete-READONLY-failure";
export const DELETE_READONLY_SUCCESS = "delete-READONLY-success";

export const CLEAN_READONLY_STORE = "CLEAN_READONLY_STORE";