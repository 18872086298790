import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import {
  Skeleton,
  Typography,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import { appTheme } from "app/themes/app/theme";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api, { apiHost } from "../../services/config";
import { asyncServiceOrdersActions } from "app/redux/reducers";
import ScheduleItem from "./components/ScheduleItem";

const DetalhesChamados = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [updating] = useState(false);
  const { setActiveLayout } = useJumboApp();
  const { serviceOrders } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [order, setOrder] = useState({});
  const [chamado, setChamado] = useState({});
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);

    if (newValue == 0) {
      console.log(order);
      setChamado(order);
    } else {
      const chamadosAnteriores = order.previousSO;
      setChamado(chamadosAnteriores[newValue - 1]);
      console.log(chamadosAnteriores[newValue - 1]);
    }
  };

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  React.useEffect(() => {
    console.log("SO > > ", serviceOrders);
  }, [serviceOrders]);

  async function getOrder() {
    setLoading(true);
    try {
      const { data } = await api.get(`service-orders/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      console.log(data);
      setChamado(data);
      setOrder(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    if (id) {
      getOrder();
    }
  }, [id]);

  async function cancelServiceOrder() {
    setLoading(true);
    dispatch(asyncServiceOrdersActions.cancel(id)).then(() => {
      getOrder();
    });
  }

  async function reopenServiceOrder() {
    dispatch(asyncServiceOrdersActions.get(id));
    navigate(`/os/reabrir/${id}`);
  }

  const handleEditOrder = () => {
    navigate(`/os/editar/${id}`);
  };

  // React.useEffect(() => {
  // getOrder();
  // console.log(serviceOrders.error);
  // }, [serviceOrders.loading]);

  async function exportToPdf() {
    setLoading(true);

    try {
      const { data } = await api.get("service-orders/export-pdf", {
        params: {
          so_id: id,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      const url =
        apiHost.lastIndexOf("/") == apiHost.length - 1
          ? apiHost.substring(0, apiHost.length - 1)
          : apiHost;

      window.open(`${url}/${data.link}`);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  function handleSelectMenuItem(action) {
    switch (action.action) {
      case 1:
        cancelServiceOrder();
        break;
      case 2:
        reopenServiceOrder();
        break;
      case 3:
        exportToPdf();
        break;
      case 4:
        handleEditOrder();
        break;
      default:
        break;
    }
  }

  function handleActions() {
    const actions = [];

    if ([1, 2, 3].includes(order.status_service_order_id)) {
      actions.push({ title: "Cancelar", action: 1 });
    }

    if ([1].includes(order.status_service_order_id)) {
      actions.push({ title: "Editar", action: 4 });
    }

    if ([4, 5, 6].includes(order.status_service_order_id)) {
      actions.push({ title: "Reabrir Ordem de Serviço", action: 2 });
    }

    actions.push({ title: "Exportar para PDF", action: 3 });

    return actions;
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={updating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {loading ? (
        <Stack spacing={1} marginTop={1}>
          <Skeleton
            variant="rectangular"
            style={{ height: "800px", borderRadius: 10 }}
          />
        </Stack>
      ) : (
        <>
          <JumboCardQuick noWrapper>
            <JumboContent
              bgColor={[appTheme.palette.principal, appTheme.palette.focus]}
              action={
                <Stack direction="row" alignItems="center">
                  <JumboDdMenu
                    menuItems={handleActions()}
                    onClickCallback={handleSelectMenuItem}
                  />
                </Stack>
              }
              sx={{ color: "common.white" }}
            >
              <Stack alignItems={"center"} pt={4} mb={2}>
                <Typography
                  textAlign={{ xs: "center", md: "left" }}
                  variant={"h1"}
                  color={"common.white"}
                >
                  Detalhes da Ordem de Serviço #{order.so}
                </Typography>
              </Stack>

              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#FFF",
                  },
                }}
              >
                <Tab
                  label="Último Atendimento"
                  sx={{
                    color: "#FFF",
                    "&[aria-selected=true]": {
                      color: "#FFF",
                    },
                  }}
                />
                {order.previousSO &&
                  order.previousSO
                    .sort((a, b) => b.id - a.id)
                    .map((atividade, index) => (
                      <Tab
                        label={`Atendimento ${order.previousSO.length - index}`}
                        sx={{
                          color: "#FFF",
                          "&[aria-selected=true]": {
                            color: "#FFF",
                          },
                        }}
                      />
                    ))}
              </Tabs>
            </JumboContent>
            <JumboContent sx={{ p: 3 }}>
              <ScheduleItem item={chamado} />
            </JumboContent>
          </JumboCardQuick>
        </>
      )}
    </>
  );
};

export default DetalhesChamados;
