import { orderBy } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";
const { createContext } = require("react");

const IndexListingContext = createContext(null);

function IndexListingProvider({ children }) {
  const location = useLocation();
  const currentUrl = location.pathname + location.search;

  const [searchParams, setSearchParams] = React.useState({
    page: 1,
    order: "desc",
    orderBy: "updated_at",
    ticket_status_id: 0,
    ownerCompany: "KREBS",
  });

  // React.useEffect(() => {
  //   console.log(
  //     "currentUrl",
  //     currentUrl,
  //     currentUrl.includes(`/tickets/revendas`)
  //   );
  //   if (currentUrl.includes(`/tickets/revendas`)) {
  //     setSearchParams({
  //       ...searchParams,
  //       ownerCompany: "RESALE",
  //     });
  //   }
  //   if (currentUrl.includes(`/tickets/krebs`)) {
  //     setSearchParams({
  //       ...searchParams,
  //       ownerCompany: "KREBS",
  //     });
  //   }
  // }, [currentUrl]);

  React.useEffect(() => {
    if (currentUrl.includes(`todos`)) {
      setSearchParams({
        ...searchParams,
        ticket_status_id: 0,
      });
    }
    if (currentUrl.includes(`aberto`)) {
      setSearchParams({
        ...searchParams,
        ticket_status_id: 1,
      });
    }
    if (currentUrl.includes(`pendente`)) {
      setSearchParams({
        ...searchParams,
        ticket_status_id: 2,
      });
    }
    if (currentUrl.includes(`resolvido`)) {
      setSearchParams({
        ...searchParams,
        ticket_status_id: 3,
      });
    }
    if (currentUrl.includes(`fechado`)) {
      setSearchParams({
        ...searchParams,
        ticket_status_id: 4,
      });
    }
    if (currentUrl.includes(`em-andamento`)) {
      setSearchParams({
        ...searchParams,
        ticket_status_id: 5,
      });
    }
  }, [currentUrl]);

  const contextValue = {
    searchParams,
    setSearchParams,
  };

  return (
    <IndexListingContext.Provider value={contextValue}>
      {children}
    </IndexListingContext.Provider>
  );
}
export { IndexListingContext };

export default IndexListingProvider;
