import { Button, FormControl, FormHelperText } from "@mui/material";
import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { set } from "lodash";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function FileUploadInput({ formikRef }) {
  const formik = formikRef;
  const [files, setFiles] = React.useState([]);

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    setFiles(fileList);
    formik.setFieldValue("files", fileList);
  };

  const handleRemoveFile = (index) => {
    const newList = files.filter((_, i) => i !== index);
    setFiles(newList);
    formik.setFieldValue("files", newList);
  };

  return (
    <FormControl className="w-full" sx={{ minWidth: 250 }} size="small">
      <div className="w-full">
        {files.map((item, index) => (
          <div
            key={index}
            className="w-full flex items-center justify-between p-2 border-dashed border-2 border-gray-300 mb-2"
          >
            <p>{item.name}</p>
            <Button
              variant="text"
              color="error"
              onClick={() => {
                handleRemoveFile(index);
              }}
            >
              <RemoveCircleIcon />
            </Button>
          </div>
        ))}
      </div>
      <Button
        component="label"
        role={undefined}
        variant="outlined"
        color="primary"
        tabIndex={-1}
        startIcon={<PostAddIcon />}
      >
        Selecionar Arquivos
        <VisuallyHiddenInput
          name="files"
          type="file"
          onChange={(event) => handleFileChange(event)}
          multiple
        />
      </Button>

      <FormHelperText
        error={formik.touched.files && Boolean(formik.errors.files)}
      >
        {formik.errors.files}
      </FormHelperText>
    </FormControl>
  );
}

export default FileUploadInput;
