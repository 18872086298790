import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TICKET_CONSTANTS } from "./ticket-constants";
import { baseApiRtkSlice } from "app/redux/features/baseApiRtk-slice";

export const ticketsSlice = baseApiRtkSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchAllTicketsIndex: builder.query({
        query(values) {
          let queryString = [];
          if (values?.page) {
            queryString.push(`page=${values.page}`);
          }
          if (values?.s) {
            queryString.push(`s=${values.s}`);
          }
          if (values?.orderBy) {
            queryString.push(`orderBy=${values.orderBy},${values.order}`);
          }
          if (values?.ticket_status_id) {
            queryString.push(`ticket_status_id=${values.ticket_status_id}`);
          }
          if (values?.ownerCompany) {
            queryString.push(`ownerCompany=${values.ownerCompany}`);
          }
          if (values?.ticket_category_id) {
            queryString.push(`ticket_category_id=${values.ticket_category_id}`);
          }
          if (values?.product_family_id) {
            queryString.push(`product_family_id=${values.product_family_id}`);
          }

          return `tickets?${queryString.join("&")}`;
        },
        providesTags: [TICKET_CONSTANTS.INDEX],
      }),
      fetchTicketCategories: builder.query({
        query() {
          return "tickets/categories";
        },
        providesTags: [TICKET_CONSTANTS.CATEGORIES],
      }),
      fetchUserShow: builder.query({
        query(id) {
          return `users/${id}`;
        },
        providesTags: [TICKET_CONSTANTS.USER],
      }),
      fetchProductFamilies: builder.query({
        query() {
          return "product-families";
        },
        providesTags: [TICKET_CONSTANTS.PRODUCT_FAMILIES],
      }),
      fetchTicketShow: builder.query({
        query(id) {
          return `tickets/${id}`;
        },
        providesTags: [TICKET_CONSTANTS.SHOW],
      }),
      fetchResponses: builder.query({
        query({ id, page }) {
          return `tickets/responses?ticket_id=${id}&orderBy=id,desc&page=${page}`;
        },
        providesTags: [TICKET_CONSTANTS.RESPONSE],
      }),
      submitResponse: builder.mutation({
        query(body) {
          return {
            url: "tickets/responses",
            method: "POST",
            body,
          };
        },
        invalidatesTags: [
          TICKET_CONSTANTS.RESPONSE,
          TICKET_CONSTANTS.SHOW,
          TICKET_CONSTANTS.INDEX,
        ],
      }),
      newTicket: builder.mutation({
        query(body) {
          return {
            url: "tickets",
            method: "POST",
            body,
          };
        },
        invalidatesTags: [
          TICKET_CONSTANTS.RESPONSE,
          TICKET_CONSTANTS.SHOW,
          TICKET_CONSTANTS.INDEX,
        ],
      }),
      editTicket: builder.mutation({
        query({ id, body }) {
          return {
            url: `tickets/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: [
          TICKET_CONSTANTS.RESPONSE,
          TICKET_CONSTANTS.SHOW,
          TICKET_CONSTANTS.INDEX,
        ],
      }),
      editUser: builder.mutation({
        query({ id, body }) {
          return {
            url: `users/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: [
          TICKET_CONSTANTS.USER,
          TICKET_CONSTANTS.RESPONSE,
          TICKET_CONSTANTS.SHOW,
          TICKET_CONSTANTS.INDEX,
        ],
      }),
    };
  },
});

export const {
  useFetchProductFamiliesQuery,
  useFetchTicketCategoriesQuery,
  useFetchAllTicketsIndexQuery,
  useEditUserMutation,
  useFetchUserShowQuery,
  useNewTicketMutation,
  useEditTicketMutation,
  useSubmitResponseMutation,
  useLazyFetchResponsesQuery,
  useFetchResponsesQuery,
  useLazyFetchTicketShowQuery,
  useFetchTicketShowQuery,
} = ticketsSlice;
