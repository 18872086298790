import {
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { PivoContext } from "../../PivoProvider";
import Div from "@jumbo/shared/Div";
import { CloseOutlined } from "@mui/icons-material";
import { Form, useFormik } from "formik";
import { pivoValidationSchema } from "../../utils/formValidation";
import {
  useFetchCompaniesForSelectQuery,
  useFetchUnitsForSelectQuery,
} from "../../features/Company/company-slice";
import {
  useCreatePivoMutation,
  useEditPivoMutation,
} from "../../features/pivos/pivos-slice";
import { useDispatch, useSelector } from "react-redux";
import { SET_ALERT } from "app/redux/modules/Alerts/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import SaveIcon from "@mui/icons-material/Save";
import { column } from "stylis";
import { useFetchResellersQuery } from "app/pages/ServiceOrders/features/serviceOrderFields-slice";

function PivoModal() {
  const { openPivotEdit, setCloseModal, pivotEditItem } =
    useContext(PivoContext);
  const dispatch = useDispatch();
  const item = pivotEditItem;
  const { auth } = useSelector((state) => state);
  const [createPivot] = useCreatePivoMutation();
  const [editPivot] = useEditPivoMutation();
  const [submit, setSubmit] = useState(false);
  const debounceLoading = _.debounce((value) => {
    setSubmit(value);
  }, 1000);
  useEffect(() => {
    if (item) {
      formik.setValues({
        serial_number: item.serial_number,
        company_id: item.unit?.company.id,
        unit_id: item.unit?.id,
        fiscal_number: item.fiscal_number,
        serial_number: item.serial_number,
        pivot_spans: item.pivot_spans,
        hourmeter: item.hourmeter,
        last_oil_change: item.last_oil_change
          ? dayjs(item.last_oil_change)
          : undefined,
        resale_id:
          auth.user.role[0].name === "RESALE"
            ? auth.user.profile.unit.company_id
            : undefined,
      });
    }
  }, [item]);

  const formik = useFormik({
    initialValues: {
      serial_number: "",
      company_id: undefined,
      unit_id: undefined,
      fiscal_number: "",
      serial_number: "",
      pivot_spans: "",
      hourmeter: "",
      last_oil_change: undefined,
      resale_id:
        auth.user.role[0].name === "RESALE"
          ? auth.user.profile.unit.company_id
          : undefined,
    },
    onSubmit: (values) => {
      setSubmit(true);
      if (item) {
        values = { ...values, id: item.id };
        editPivot(values)
          .unwrap()
          .then((res) => {
            dispatch({
              type: SET_ALERT,
              payload: {
                message: "Pivo editado com sucesso",
                variant: "success",
              },
            });
            formik.resetForm();
            setCloseModal();
          })
          .catch((err) =>
            dispatch({
              type: SET_ALERT,
              payload: { message: "Erro ao editar pivo", variant: "error" },
            })
          );
        debounceLoading(false);
        return;
      }
      createPivot(values)
        .unwrap()
        .then((res) => {
          dispatch({
            type: SET_ALERT,
            payload: { message: "Pivo criado com sucesso", variant: "success" },
          });
          formik.resetForm();

          setCloseModal();
        })
        .catch((err) =>
          dispatch({
            type: SET_ALERT,
            payload: { message: "Erro ao criar pivo", variant: "error" },
          })
        );
      debounceLoading(false);
    },
    validationSchema: pivoValidationSchema,
    validateOnMount: true,
    validateOnChange: false,
  });

  const company = useFetchCompaniesForSelectQuery();
  const unit = useFetchUnitsForSelectQuery(
    formik.values.company_id ? formik.values.company_id : { skip: true }
  );
  const resale = useFetchResellersQuery();

  useEffect(() => {
    console.log("dados do item", item);
    console.log("dados dentro do formik", formik.values);
  }, [formik.values, item]);

  useEffect(() => {
    console.log("o auth", auth);
  }, [auth]);

  return (
    <Modal
      onClose={() => {
        formik.resetForm();

        setCloseModal();
      }}
      open={openPivotEdit}
    >
      <Card style={$modalBox} sx={{ p: 2, width: 400, height: 400 }}>
        <Div className="flex flex-row justify-between items-center mb-2 scroll-auto">
          <Typography id="transition-modal-title" variant="h4" component="h2">
            {item
              ? `Editar Pivot - #${item.serial_number}`
              : `Adicionar novo pivô`}
          </Typography>

          <Button
            color="error"
            onClick={() => {
              formik.resetForm();

              setCloseModal();
            }}
          >
            <CloseOutlined />
          </Button>
        </Div>
        <Divider sx={{ mb: 2 }} />

        <Stack flexDirection="row">
          <form
            data-cy="form"
            style={{ display: "flex", flex: 1 }}
            onSubmit={formik.handleSubmit}
          >
            <Div
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                boxSizing: "border-box",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      label="Número de série"
                      name="serial_number"
                      value={formik.values.serial_number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.serial_number &&
                        Boolean(formik.errors.serial_number)
                      }
                      helperText={
                        formik.touched.serial_number &&
                        formik.errors.serial_number
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="number"
                      label="Número da nota fiscal"
                      name="fiscal_number"
                      value={formik.values.fiscal_number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.fiscal_number &&
                        Boolean(formik.errors.fiscal_number)
                      }
                      helperText={
                        formik.touched.fiscal_number &&
                        formik.errors.fiscal_number
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      filterSelectedOptions={true}
                      options={company.data?.data || []}
                      loading={company.isLoading}
                      fullWidth
                      InputProps={{
                        autoComplete: "off",
                      }}
                      onChange={(_, value) =>
                        formik.setFieldValue(
                          "company_id",
                          value !== null ? value.id : ""
                        )
                      }
                      value={
                        company.isFetching
                          ? company
                          : company.data?.data?.find(
                              (option) => option.id === formik.values.company_id
                            ) || company
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderInput={(params) => (
                        <TextField
                          className="p-0"
                          {...params}
                          fullWidth
                          id={"companyIdFilter"}
                          name={"companyIdFilter"}
                          label={"Empresa"}
                          InputProps={{
                            ...params.InputProps,

                            endAdornment: (
                              <React.Fragment>
                                {company.isLoading || company.isFetching ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      filterSelectedOptions={true}
                      options={unit.data?.data || []}
                      loading={unit.isLoading}
                      fullWidth
                      InputProps={{
                        autoComplete: "off",
                      }}
                      onChange={(_, value) =>
                        formik.setFieldValue(
                          "unit_id",
                          value !== null ? value.id : ""
                        )
                      }
                      value={
                        unit.isFetching
                          ? null
                          : unit.data?.data?.find(
                              (option) => option.id === formik.values.unit_id
                            ) || null
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          className="p-0"
                          {...params}
                          fullWidth
                          id={"unit_id"}
                          name={"unit_id"}
                          label={"Unidade"}
                          value={formik.values.unit_id}
                          InputProps={{
                            ...params.InputProps,

                            endAdornment: (
                              <React.Fragment>
                                {unit.isLoading || unit.isFetching ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="number"
                      label="Horímetro"
                      name="hourmeter"
                      value={formik.values.hourmeter}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.hourmeter &&
                        Boolean(formik.errors.hourmeter)
                      }
                      helperText={
                        formik.touched.hourmeter && formik.errors.hourmeter
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      type="number"
                      fullWidth
                      label="Lances do pivô"
                      name="pivot_spans"
                      value={formik.values.pivot_spans}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.pivot_spans &&
                        Boolean(formik.errors.pivot_spans)
                      }
                      helperText={
                        formik.touched.pivot_spans && formik.errors.pivot_spans
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        data-testid={"input-date"}
                        id="last_oil_change"
                        name="last_oil_change"
                        label="Última troca de óleo"
                        format="DD/MM/YYYY"
                        onAccept={(newValue) => {
                          if (newValue) {
                            formik.setFieldValue(
                              "last_oil_change",
                              newValue,
                              true
                            );
                          }
                        }}
                        onChange={(newValue) => {
                          if (
                            newValue &&
                            newValue.format("DD/MM/YYYY") !== "Invalid Date"
                          ) {
                            formik.setFieldValue(
                              "last_oil_change",
                              newValue,
                              true
                            );
                          }
                        }}
                        value={formik.values.last_oil_change}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            onBlur: () => {
                              if (formik.values["last_oil_change"]) {
                                const today = dayjs().startOf("day");
                                const date = formik.values["last_oil_change"];
                                const diff = date.diff(today, "d");

                                if (diff < 0) {
                                  formik.setFieldValue(
                                    "last_oil_change",
                                    today
                                  );
                                }
                              }
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      filterSelectedOptions={true}
                      options={resale.data?.data || []}
                      loading={resale.isLoading}
                      fullWidth
                      InputProps={{
                        autoComplete: "off",
                      }}
                      onChange={(_, value) =>
                        formik.setFieldValue(
                          "resale_id",
                          value !== null ? value.id : ""
                        )
                      }
                      disabled={auth.user.role[0].name === "RESALE"}
                      value={
                        resale.isFetching
                          ? null
                          : resale.data?.data?.find(
                              (option) => option.id === formik.values.resale_id
                            ) || null
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderInput={(params) => (
                        <TextField
                          className="p-0"
                          {...params}
                          fullWidth
                          id={"resale_id"}
                          name={"resale_id"}
                          label={"Revenda"}
                          InputProps={{
                            ...params.InputProps,

                            endAdornment: (
                              <React.Fragment>
                                {resale.isLoading || resale.isFetching ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Divider sx={{ mb: 2, mt: 2 }} />
              <Stack flexDirection="row" justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    formik.resetForm();
                    setCloseModal();
                  }}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  loading={submit}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  variant="contained"
                  color="success"
                  type="submit"
                >
                  Salvar
                </LoadingButton>
              </Stack>
            </Div>
          </form>
        </Stack>
      </Card>
    </Modal>
  );
}

const $modalBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  height: "auto",
};
export default PivoModal;
