//PRODUCT_FAMILIES Types

export const CANCEL_SERVICE_ORDERS_FAILURE = "CANCEL_SERVICE_ORDERS_FAILURE";
export const CANCEL_SERVICE_ORDERS_REQUEST = "CANCEL_SERVICE_ORDERS_REQUEST";
export const CANCEL_SERVICE_ORDERS_SUCCESS = "CANCEL_SERVICE_ORDERS_SUCCESS";

export const REOPEN_SERVICE_ORDERS_REQUEST = "REOPEN_SERVICE_ORDERS_REQUEST";
export const REOPEN_SERVICE_ORDERS_FAILURE = "REOPEN_SERVICE_ORDERS_FAILURE";
export const REOPEN_SERVICE_ORDERS_SUCCESS = "REOPEN_SERVICE_ORDERS_SUCCESS";

export const CREATE_SERVICE_ORDERS_REQUEST = "CREATE_SERVICE_ORDERS_REQUEST";
export const CREATE_SERVICE_ORDERS_FAILURE = "CREATE_SERVICE_ORDERS_FAILURE";
export const CREATE_SERVICE_ORDERS_SUCCESS = "CREATE_SERVICE_ORDERS_SUCCESS";

export const EDIT_SERVICE_ORDERS_SUCCESS = "EDIT_SERVICE_ORDERS_SUCCESS";
export const EDIT_SERVICE_ORDERS_REQUEST = "EDIT_SERVICE_ORDERS_REQUEST";
export const EDIT_SERVICE_ORDERS_FAILURE = "EDIT_SERVICE_ORDERS_FAILURE";

export const GET_SERVICE_ORDERS_REQUEST = "get_SERVICE_ORDERS_REQUEST";
export const GET_SERVICE_ORDERS_FAILURE = "get_SERVICE_ORDERS_FAILURE";
export const GET_SERVICE_ORDERS_SUCCESS = "get_SERVICE_ORDERS_SUCCESS";

export const CLEAN_SERVICE_ORDERS_STORE = "CLEAN_SERVICE_ORDERS_STORE";
