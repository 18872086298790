const today = new Date();
const currentYear = today.getFullYear();

export const calendarData = {
    tasks: [
        {
            title: 'Tarefa 1',
            start: new Date(currentYear, 9, 20, 8, 0, 0),
            end: new Date(currentYear, 9, 20, 10, 0, 0),
        },
        {
            title: 'Tarefa 2',
            start: new Date(currentYear, 9, 20, 12, 0, 0),
            end: new Date(currentYear, 9, 20, 14, 0, 0),
        },
        {
            title: 'Tarefa 3',
            start: new Date(currentYear, 9, 19, 15, 0, 0),
            end: new Date(currentYear, 9, 20, 8, 0, 0),
        },
        {
            title: 'Tarefa 4',
            start: new Date(currentYear, 9, 20, 15, 0, 0),
            end: new Date(currentYear, 9, 20, 18, 0, 0),
        },
        /* {
            title: 'All Day Event very long title',
            allDay: true,
            start: new Date(currentYear, 3, 0),
            end: new Date(currentYear, 3, 1),
        },
        {
            title: 'Long Event',
            start: new Date(currentYear, 3, 7),
            end: new Date(currentYear, 3, 10),
        },

        {
            title: 'DTS STARTS',
            start: new Date(2016, 2, 13, 0, 0, 0),
            end: new Date(2016, 2, 20, 0, 0, 0),
        },

        {
            title: 'DTS ENDS',
            start: new Date(2016, 10, 6, 0, 0, 0),
            end: new Date(2016, 10, 13, 0, 0, 0),
        },

        {
            title: 'Some Event',
            start: new Date(currentYear, 3, 9, 0, 0, 0),
            end: new Date(currentYear, 3, 9, 0, 0, 0),
        },
        {
            title: 'Conference',
            start: new Date(currentYear, 3, 11),
            end: new Date(currentYear, 3, 13),
            desc: 'Big conference for important people',
        },
        {
            title: 'Meeting',
            start: new Date(currentYear, 3, 12, 10, 30, 0, 0),
            end: new Date(currentYear, 3, 12, 12, 30, 0, 0),
            desc: 'Pre-meeting meeting, to prepare for the meeting',
        },
        {
            title: 'Lunch',
            start: new Date(currentYear, 3, 12, 12, 0, 0, 0),
            end: new Date(currentYear, 3, 12, 13, 0, 0, 0),
            desc: 'Power lunch',
        },
        {
            title: 'Meeting',
            start: new Date(currentYear, 3, 12, 14, 0, 0, 0),
            end: new Date(currentYear, 3, 12, 15, 0, 0, 0),
        },
        {
            title: 'Happy Hour',
            start: new Date(currentYear, 3, 12, 17, 0, 0, 0),
            end: new Date(currentYear, 3, 12, 17, 30, 0, 0),
            desc: 'Most important meal of the day',
        },
        {
            title: 'Dinner',
            start: new Date(currentYear, 3, 12, 20, 0, 0, 0),
            end: new Date(currentYear, 3, 12, 21, 0, 0, 0),
        },
        {
            title: 'Birthday Party',
            start: new Date(currentYear, 3, 13, 7, 0, 0),
            end: new Date(currentYear, 3, 13, 10, 30, 0),
        },
        {
            title: 'Birthday Party 2',
            start: new Date(currentYear, 3, 13, 7, 0, 0),
            end: new Date(currentYear, 3, 13, 10, 30, 0),
        },
        {
            title: 'Birthday Party 3',
            start: new Date(currentYear, 3, 13, 7, 0, 0),
            end: new Date(currentYear, 3, 13, 10, 30, 0),
        },
        {
            title: 'Late Night Event',
            start: new Date(currentYear, 3, 17, 19, 30, 0),
            end: new Date(currentYear, 3, 18, 2, 0, 0),
        },
        {
            title: 'Multi-day Event',
            start: new Date(currentYear, 3, 20, 19, 30, 0),
            end: new Date(currentYear, 3, 22, 2, 0, 0),
        }, */
    ],
};

export const cultures = [
    {id: 1, title: 'Técnico 1'},
    {id: 2, title: 'Técnico 2'},
    {id: 3, title: 'Técnico 3'},
];
